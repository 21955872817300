<template>
    <v-list nav>
        <template v-if="$can('read', 'Account')">
            <app-admin-nav />
        </template>

        <v-list dense nav>
            <v-subheader class="text-uppercase">{{ $t('App.nav.personal') }}</v-subheader>
            <v-list-item :to="{path: '/my-documents'}" class="mb-1">
                <v-list-item-icon>
                    <v-icon>mdi-file-document</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ $t('App.nav.dashboard') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-list dense nav>
            <v-subheader class="text-uppercase">{{ $t('App.nav.tools') }}</v-subheader>
            <template v-for="item in items">
                <v-list-item
                    :key="item.title"
                    :to="{path: item.to}"
                    class="mb-1"
                    v-if="$hasFeature(item.feature)"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-if="isDa">
                <v-list-item
                    class="mb-1"
                    @click="openLink('https://www.deinadieu.ch/vertraege-briefe/')"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-download-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ $t('App.nav.moreDownloads') }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-icon small>mdi-open-in-new</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </template>
        </v-list>
    </v-list>
</template>

<script>
import AppAdminNav from './AppAdminNav';
import {mapGetters} from "vuex";

export default {
    name: 'AppUserNav',
    components: {AppAdminNav},
    data() {
        return {};
    },
    computed: {
        ...mapGetters('env', ['isDa']),
        items() {
            return [
                {
                    title: this.$t('AppUserNav.testament'),
                    icon: 'mdi-gavel',
                    to: '/testament',
                    feature: this.$Features.Testament
                },
                {
                    title: this.$t('AppUserNav.patientenverfuegung'),
                    icon: 'mdi-file-sign',
                    to: '/patientenverfuegung',
                    feature: this.$Features.PatientDecree
                },
                {
                    title: this.$t('AppUserNav.advance_care_directive'),
                    icon: 'mdi-file-account-outline',
                    to: '/vorsorgeauftrag',
                    feature: this.$Features.AdvanceCareDirective
                },
            ];
        },
    },
    methods: {
        openAdminNav() {
            this.$emit('open-admin-nav');
        },
        openLink(url) {
            window.open(url, '_blank');
        }
    },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
    background: #fff !important;
}
</style>
