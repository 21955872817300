var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-observer',{ref:"register",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-card',[_c('div',{staticClass:"text-center"},[(_vm.getLogo)?_c('img',{staticClass:"d-inline-block my-12",style:({'height': _vm.$vuetify.breakpoint.mdAndDown ? '3rem': '4rem'}),attrs:{"src":_vm.getLogo}}):_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.mdAndDown ? '8rem' : '10rem'}},[_vm._v(" $vuetify.icons.dein_adieu_logo ")])],1),_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('Register.title'))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error","value":_vm.error.length > 0,"dense":""}},[_vm._v(_vm._s(_vm.error))])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.first-name'),"rules":"required|max:255","vid":"first_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('OneWord.first-name'),"autocomplete":"given-name"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.last-name'),"rules":"required|max:255","vid":"last_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('OneWord.last-name'),"autocomplete":"family-name"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.email'),"rules":"required|email|max:255","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('OneWord.email'),"autocomplete":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.password'),"rules":"required|min:8|max:255","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"hint":_vm.$t('User.password.hint'),"label":_vm.$t('OneWord.password'),"type":_vm.show ? 'text' : 'password',"autocomplete":"off","counter":"","loading":""},on:{"click:append":function($event){_vm.show = !_vm.show}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"value":_vm.pwdStrengthProgress,"color":_vm.pwdStrengthColor,"absolute":"","height":"7"}})]},proxy:true}],null,true),model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.passwordConfirmation'),"rules":"required|min:8|max:255","vid":"password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"hint":_vm.$t('User.password.hint'),"label":_vm.$t('OneWord.passwordConfirmation'),"type":_vm.show ? 'text' : 'password',"autocomplete":"off","counter":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})]}}],null,true)})],1)],1),_c('small',{domProps:{"innerHTML":_vm._s(_vm.$t('Register.infoText'))}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":"","text":""},on:{"click":function($event){return _vm.$router.push({name:'login'})}}},[_vm._v(" "+_vm._s(_vm.$t('OneWord.cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary","type":"submit","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('Register.title'))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }