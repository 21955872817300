<template>
    <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()">
        <v-card>
            <v-card-title class="primary lighten-2 white--text">
                {{ title }}
            </v-card-title>
            <v-card-text v-show="!!message" class="pt-4">
                {{ message }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click.native="cancel()" data-cy="confirm-btn-cancel">
                    {{ $t('OneWord.cancel') }}
                </v-btn>
                <v-btn color="primary" depressed @click.native="agree()" data-cy="confirm-btn-yes">
                    {{ $t('OneWord.yes') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open(this.$t('OneWord.delete'), 'Sind Sie sicher dass Sie löschen möchten?', { color: 'red' }).then((confirm) => {});
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open;
 * }
 */
export default {
    name: 'Confirm',
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            options: {
                color: 'primary',
                width: 380,
            },
        };
    },
    methods: {
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        agree() {
            this.resolve(true);
            this.dialog = false;
        },

        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
    },
};
</script>
