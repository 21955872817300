<template>
    <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        :flat="flat"
        class="px-2"
        app
        style="z-index: 50"
    >
        <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            @click.stop="$emit('drawer')"
        ></v-app-bar-nav-icon>

        <a :href="getHomepage" :title="getName" rel="noopener" target="_blank">
            <v-toolbar-title class="cursor-pointer">
                <div class="flex items-center" v-if="getLogo">
                    <small 
                        v-if="hasProfile && !isAuthenticated && !isWhiteLabel"
                        class="inline-block pt-2 text-xs"
                        data-cy="logo-tenant-powered-by"
                    >
                        {{ $t('OneWord.poweredBy') }}:
                    </small>
                    <img
                        class="inline-block ml-2 h-8"
                        :src="getLogo"
                        data-cy="logo-tenant"
                    />
                </div>
                <div class="flex items-center" v-else>
                    <small
                        v-if="hasProfile && !isAuthenticated"
                        class="inline-block text-xs"
                        data-cy="logo-da-powered-by"
                    >
                        {{ $t('OneWord.poweredBy') }}:
                    </small>
                    <img 
                        class="inline-block ml-2 h-8"
                        :src="require('@/assets/img/deinadieu_logo.svg')" 
                        style="fill: white"
                        data-cy="logo-da"
                    />
                </div>
            </v-toolbar-title>
        </a>

        <v-spacer></v-spacer>

        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-btn class="mr-2 text-none" data-cy="language-select" outlined text v-on="on" :loading="languageLoading" :data-loaded="languageLoading ? 'false' : 'true'">
                    <v-icon left>mdi-translate</v-icon>
                    <span class="hidden-md-and-down">{{ languageLabel }}</span>
                    <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="language in languages"
                    :key="language.locale"
                    data-cy="language-select-item"
                    @click="changeLocale(language.locale)"
                >
                    <v-list-item-title>{{ language.label }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <template v-if="isAuthenticated">
            <the-notifications-menu></the-notifications-menu>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn class="text-none ml-2" color="primary" data-cy="btn-user" depressed v-on="on">
                        <v-icon left>mdi-account-outline</v-icon>
                        <span class="hidden-md-and-down">{{ displayName }}</span>
                        <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="profile">
                        <v-list-item-icon>
                            <v-icon>mdi-account-edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('App.nav.profile') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="logout" data-cy="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            {{ $t('OneWord.logout') }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-else>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="text-none"
                        color="primary"
                        depressed
                        @click="$router.push({name: 'login' })"
                        v-on="on"
                    >
                        <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-login</v-icon>
                        <span class="hidden-sm-and-down">{{ $t('OneWord.login') }}</span>
                    </v-btn>
                </template>
                <span>{{ $t('AppBar.login') }}</span>
            </v-tooltip>
        </template>
    </v-app-bar>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';
import {usei18n} from '@/plugins/i18n';
import {filter, find, get} from "lodash";
import TheNotificationsMenu from "@/components/TheNotificationsMenu.vue";

export default {
    name: 'AppBar',
    components: {
        TheNotificationsMenu,
    },
    props: ['flat'],
    computed: {
        ...mapGetters('user', [
            'user',
        ]),
        ...mapGetters('env', [
            'getLogo',
            'getLanguages',
            'isAuthenticated',
            'isWhiteLabel',
            'getHomepage',
            'getName',
        ]),
        ...mapGetters('affiliateProfile', [
            'hasProfile',
        ]),
        languages() {
            return filter([
                {lang: 'de', locale: 'de-CH', label: this.$t('OneWord.lang.german')},
                {lang: 'en', locale: 'en-GB', label: this.$t('OneWord.lang.english')},
                {lang: 'fr', locale: 'fr-CH', label: this.$t('OneWord.lang.french')},
                {lang: 'it', locale: 'it-CH', label: this.$t('OneWord.lang.italian')},
            ], obj => get(this.getLanguages, obj.lang, false));
        },
        lang() {
            return usei18n().locale?.substring(0, 2) || 'de';
        },
        displayName() {
            return get(this.user, 'display_name');
        },
    },
    data() {
        return {
            languageLabel: null,
            languageLoading: false,
            selected: [1],
        };
    },
    watch: {
        lang(lang) {
            this.setLanguageLabel(lang);
        },
    },
    methods: {
        ...mapActions('env', [
            'logout',
        ]),
        changeLocale(locale) {
            this.languageLoading = true;
            EventBus.$once('locale-changed', () => {
                this.languageLoading = false;
            });
            EventBus.$emit('change-locale', locale);
            this.setLanguageLabel(locale);
        },
        getLocale(lang) {
            switch (lang) {
            case 'en':
                return 'en-GB';
            case 'fr':
                return 'fr-CH';
            case 'it':
                return 'it-CH';
            default:
                return 'de-CH';
            }
        },
        setLanguageLabel(value) {
            let locale = value.includes('-') ? value : this.getLocale(value),
                language = find(this.languages, {locale: locale});

            if (language) {
                this.languageLabel = language.label;
            }
        },
        phone() {
            window.open('tel:+41445005237');
        },
        profile() {
            this.$router.push({path: '/user'});
        },
    },
    mounted() {
        this.setLanguageLabel(this.lang);
    }
};
</script>

<style lang="scss" scoped>
.v-list {
    .v-list-item {
        &.mark-unread {
            @apply border-l-4;
            @apply border-blue-400;
            @apply bg-blue-100;
        }
    }
}
</style>
