<template>
    <tiptap-vuetify
        v-model="editorContent"
        :card-props="{outlined: true, tile: true}"
        :extensions="extensions"
        class="mt-2"
    ></tiptap-vuetify>
</template>

<script>
import {
    Bold,
    BulletList,
    HardBreak,
    Heading,
    History,
    Italic,
    Link,
    ListItem,
    OrderedList,
    Paragraph,
    Strike,
    TiptapVuetify,
    Underline,
} from 'tiptap-vuetify';

export default {
    name: 'CustomContent',
    components: {
        TiptapVuetify
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        primaryColor: {
            type: String,
            default: '#2F1F3C',
        },
    },
    mounted() {
        this.editorContent = this.value;
    },
    watch: {
        value(value) {
            this.editorContent = value;
        },
        editorContent(value) {
            if (value === '<p></p>' || value === null) {
                value = '';
            }
            this.$emit('input', value);
        },
        primaryColor(value) {
            document.documentElement.style.setProperty('--da-wysiwyg-primary', value);
        }
    },
    data() {
        return {
            editorContent: '',
            extensions: [
                Paragraph,
                BulletList,
                HardBreak,
                [
                    Heading,
                    {
                        options: {
                            levels: [1, 2, 3],
                        },
                    },
                ],
                ListItem,
                OrderedList,
                [
                    Link,
                    {
                        options: {
                            target: '_blank',
                        }
                    }
                ],
                Bold,
                Italic,
                Strike,
                Underline,
                History,
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
::v-deep .ProseMirror {
    min-height: 10rem;
    @import '@/assets/_wysiwyg.scss';
}
</style>
