<template>
    <div data-cy="testament-tool">
        <h1 class="title mb-2">
            {{ $t('TestamentTool.title') }}
        </h1>
        <v-sheet class="pa-4" :class="{'elevation-1': $vuetify.breakpoint.lgAndUp}">
            <tool-stepper
                :step="step"
                :steps="steps"
            ></tool-stepper>

            <component :is="stepComponent"></component>
        </v-sheet>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ToolStepper from '@/components/ToolStepper';
import goTo from 'vuetify/lib/services/goto';

export default {
    name: 'TestamentTool',
    components: {
        ToolStepper,
        TestamentStep1: () => import('@/pages/testament/TestamentStep1'),
        TestamentStep2: () => import('@/pages/testament/TestamentStep2'),
        TestamentStep3: () => import('@/pages/testament/TestamentStep3'),
        TestamentStep4: () => import('@/pages/testament/TestamentStep4'),
        TestamentStep5: () => import('@/pages/testament/TestamentStep5'),
        TestamentStep6: () => import('@/pages/testament/TestamentStep6'),
        TestamentStep7: () => import('@/pages/testament/TestamentStep7'),
    },
    data() {
        return {};
    },
    watch: {
        '$route'(to) {
            if (to.name === 'testament-affiliate') {
                this.loadAffiliate(to.params.profile_slug);
            }
        },
        step(value) {
            this.changeStep(value);
        },
    },
    computed: {
        ...mapGetters('testament', {
            step: 'step',
        }),
        stepComponent() {
            return `TestamentStep${this.step}`;
        },
        steps() {
            return [
                {
                    step: 1,
                    text: this.$t('TestamentTool.stepper.step1'),
                }, {
                    step: 2,
                    text: this.$t('TestamentTool.stepper.step2'),
                }, {
                    step: 3,
                    text: this.$t('TestamentTool.stepper.step3'),
                }, {
                    step: 4,
                    text: this.$t('TestamentTool.stepper.step4'),
                }, {
                    step: 5,
                    text: this.$t('TestamentTool.stepper.step5'),
                }, {
                    step: 6,
                    text: this.$t('TestamentTool.stepper.step6'),
                }, {
                    step: 7,
                    text: this.$t('TestamentTool.stepper.step7'),
                },
            ];
        },
    },
    methods: {
        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),
        changeStep() {
            goTo(0);
        },
    },
    mounted() {
        let profileSlug = _.get(this.$route.params, 'profile_slug', false);

        if (profileSlug) {
            this.loadAffiliate(profileSlug);
        }
    },
};
</script>

<style scoped>

</style>
