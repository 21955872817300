<template>
    <div class="py-0 px-2 lg:py-6 sm:px-0">
        
        <affiliate-header v-if="!isAuthenticated" class="text-center" />

        <v-card
            class="my-4 lg:p-4 rounded-md lg:rounded-xl"
            :flat="!isAuthenticated"
        >
            <h1 class="mt-7 mb-8 text-center text-3xl text-black font-medium">
                {{ $t('Webinars.title') }}
            </h1>

            <v-card
                class="webinar p-4 border-t"
                v-for="(webinar, index) in webinars" 
                :key="webinar.id"
                :data-cy="'webinar-item-' + index"
                rounded="0"
                flat
                @click="$router.push({name: 'webinar-details', params: {profile_id: profileId, webinar_id: webinar.id}})"
            >
                <v-row class="mx-1 py-1" justify="end">
                    <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="2" class="text-center pt-4">
                        <v-card flat outlined class="w-16 mt-1">
                            <div class="bg-da-primary text-white subtitle-2 text-uppercase">{{ localizeDate(webinar.date, 'MMM').substring(0, 3) }}</div>
                            <div class="text-h5 py-1">{{ localizeDate(webinar.date, 'D') }}</div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <div class="mt-1 text-gray-500">{{ localizeDate(webinar.date, 'dddd') }}, {{ localizeDate(webinar.date) }}</div>
                        <h1 class="title mb-1">{{ webinar.title }}</h1>
                        <div class="text-gray-500">{{ localizeTime(webinar.start_time) }} - {{ localizeTime(webinar.end_time) }}</div>
                    </v-col>
                    <v-col cols="12" lg="2" style="display: flex; align-items: center;">
                        <v-btn 
                            color="primary"
                            :data-cy="'btn-webinar-register-' + index"
                            @click="$router.push({name: 'webinar-details', params: {profile_id: profileId, webinar_id: webinar.id}})"
                        >
                            Details
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>

        <v-card
            v-if="!loading && webinars.length === 0"
            data-cy="webinars-empty-alert"
            class="mx-2 sm:mx-0 mb-4 p-4 text-center rounded-md lg:rounded-xl"
            flat
        >
            {{ $t('Webinars.list.empty') }}
        </v-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';
import {localizeDate, localizeTime} from '@/composables/Helpers';
import AffiliateHeader from '@/pages/affiliate/AffiliateHeader.vue';

export default {
    name: 'WebinarList',

    components: {
        AffiliateHeader,
    },

    data() {
        return {
            webinars: [],
            loading: false,
        }
    },

    computed: {
        ...mapGetters('env', [
            'isAuthenticated',
        ]),

        profileId() {
            return this.$route.params.profile_id;
        }
    },

    mounted() {
        this.load();

        EventBus.$on('locale-changed', () => {
            this.load();
        });
    },

    methods: {
        load() {
            this.loading = true;
            axios.get(`/webapi/profiles/${this.profileId}/webinars?scope=planned`).then(response => {
                this.webinars = _.orderBy(response.data.data, 'date');
            }).finally(() => {
                this.loading = false;
            });
        },

        localizeTime,
        localizeDate,
    }
};
</script>

<style scoped>
.clamp-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.webinar + .webinar {
    border-top: 1px solid #eee;
}
</style>
