<template>
    <v-container class="p-0 mt-3" fluid>
        <v-row align="stretch">
            <v-col
                v-for="color in colors"
                :key="color.key"
                class="pb-0 mb-0 d-flex flex-column"
                cols="12"
                md="6"
                lg="3"
            >
                <v-card class="flex-grow-1" :loading="loading">
                    <v-card-text>
                        <v-row class="pa-3">
                            <v-col cols="12">
                                <h2 class="title mb-3">{{ color.label }}</h2>
                                <color-picker :color-key="color.key" v-model="color.value" /><br>
                                <v-alert v-if="color.description" color="info" dense text>{{ color.description }}</v-alert>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="mt-2">
                <v-btn
                    :loading="loading"
                    color="accent"
                    @click="saveColors()"
                >
                    <v-icon left>mdi-content-save</v-icon>
                    {{ $t('OneWord.save') }}
                </v-btn>
                <v-btn
                    @click="resetColors()"
                    class="ml-4"
                >
                    <v-icon left>mdi-restore</v-icon>
                    {{ $t('OneWord.reset') }}
                </v-btn>
            </v-col>
        </v-row>

        <h1 class="title mt-10 mb-2">
            {{ $t('AdminWhitelabel.preview.example') }}
        </h1>

        <v-card class="max-w-7xl">
            <v-row>
                <v-col cols="12" lg="8" xl="9">
                    <img 
                        :src="require('@/assets/img/whitelabel-colors-example.png')" 
                        class="mx-auto"
                    />
                </v-col>

                <v-col cols="12" lg="4" xl="3" class="py-10 px-6">
                    <v-list-item-title class="text-h5 mb-4">{{ $t('AdminWhitelabel.preview.legend') }}</v-list-item-title>
                    <v-list-item 
                        v-for="(color, index) in colors" 
                        :key="color.key"
                        class="pl-0"
                    >
                        <v-list-item-icon class="mr-2 my-3">
                            <v-icon color="#FF00D6">{{ 'mdi-numeric-' + (index + 1) + '-circle-outline' }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ color.label }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import ColorPicker from './whitelabel/ColorPicker';
import {mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';

const defaultColors = {
    primary: '#2F1F3C',
    secondary: '#DAC1BA',
    accent: '#745567',
    pp: '#CE468A',
};

export default {
    name: 'AdminWhitelabelColors',
    components: {
        ColorPicker,
    },
    data() {
        return {
            loading: false,
            colors: [
                {
                    key: 'primary',
                    value: defaultColors.primary,
                    label: this.$t('AdminWhitelabel.colors.primary.label'),
                    description: this.$t('AdminWhitelabel.colors.primary.description'),
                },
                {
                    key: 'secondary',
                    value: defaultColors.secondary,
                    label: this.$t('AdminWhitelabel.colors.secondary.label'),
                    description: this.$t('AdminWhitelabel.colors.secondary.description'),
                },
                {
                    key: 'accent',
                    value: defaultColors.accent,
                    label: this.$t('AdminWhitelabel.colors.accent.label'),
                    description: this.$t('AdminWhitelabel.colors.accent.description'),
                },
                {
                    key: 'pp',
                    value: defaultColors.pp,
                    label: this.$t('AdminWhitelabel.colors.pp.label'),
                    description: this.$t('AdminWhitelabel.colors.pp.description'),
                },
            ],
        };
    },
    mounted() {
        this.load();
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
    },
    methods: {
        load() {
            this.loading = true;
            axios.get(`/webapi/accounts/${this.user.account_id}/whitelabel`)
            .then(response => {
                _.forEach(response.data.colors, (value, color) => {
                    _.find(this.colors, {key: color}).value = value;
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        saveColors() {
            this.loading = true;
            axios.put(`/webapi/accounts/${this.user.account_id}/whitelabel`, {
                colors: this.colors
            })
            .then(() => {
                EventBus.$emit('success');
            }).catch(error => {
                EventBus.$emit('error', _.get(error, 'response.data.message'));
            }).finally(() => {
                this.loading = false;
            });
        },
        resetColors() {
            _.forEach(defaultColors, (value, key) => {
                _.find(this.colors, {key: key}).value = value;
            });
        }
    },
};
</script>

<style scoped>

</style>
