<template>
    <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(save)" ref="createProfileContact">
            <v-card :loading="saving">
                <v-card-text>
                    <profile-form
                        :is-ngo="isNgo"
                        :profile.sync="profile"
                        lang="de_CH"
                    ></profile-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="$router.back()" text>{{ $t('OneWord.back') }}</v-btn>
                    <v-btn type="submit" color="accent" :loading="saving">
                        <v-icon left>mdi-content-save</v-icon>
                        {{ $t('OneWord.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </validation-observer>
</template>

<script>
import {ValidationObserver} from 'vee-validate';
import ProfileForm from '../admin/form/ProfileForm';
import store from '../../store';

export default {
    name: 'CreateProfileContact',
    components: {ProfileForm, ValidationObserver},
    data() {
        return {
            profile: {},
            saving: false,
        };
    },
    computed: {
        isNgo() {
            return _.map(_.get(this.profile, 'partner_types', []), 'id').includes(12);
        },
    },
    methods: {
        save() {
            this.saving = true;

            axios.post('/webapi/create-profile/contact', this.profile).then(() => {
                this.$router.push({name: 'create-profile-submit'});
            }).catch(error => {
                this.$refs.createProfileContact.setErrors(error.data.errors);
            }).finally(() => {
                this.saving = false;
            });
        },
    },
    beforeRouteEnter(to, from, next) {
        axios.get('/webapi/create-profile/profile').then(response => {
            next(vm => {
                vm.profile = response.data.data;
                vm.profile.email = store.getters['user/user'].email;
            });
        });
    },
};
</script>

<style scoped>

</style>
