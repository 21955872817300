var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('AdminLeads.title'))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"depressed":"","color":"accent","href":"/leads/export"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" "+_vm._s(_vm.$t('AdminLeads.table.downloadExcel'))+" ")],1)],1),_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.meta.total,"item-key":"id","multi-sort":"","data-cy":"admin-leads"},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.load,"update:sort-by":_vm.load,"update:sort-desc":_vm.load,"update:items-per-page":_vm.load,"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('AdminTestator.personData'))+" ")])],1)]},proxy:true},{key:"header.is_favored",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('AdminLeads.dataTable.isFavoredHeaderTooltip')))])])]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.anonymized)?_c('span',[_vm._v("("+_vm._s(_vm.$t('OneWord.deleted'))+")")]):_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.anonymized)?_c('span',[_vm._v("("+_vm._s(_vm.$t('OneWord.deleted'))+")")]):_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.lead_type",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("AdminLeads.leadType.items." + value)))+" ")]}},{key:"item.lead_source",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("AdminLeads.leadSource.items." + value)))+" ")]}},{key:"item.ngo_thanks",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.lead_type === 'will' || item.lead_type === 'consulting')?[(item.ngo_thanks_external)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-information")]):(value)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle-outline")])]:_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"caption"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('OneWord.notAvailableIcon')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('OneWord.notAvailableToolTip')))])])]}},{key:"item.ngo_info",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle-outline")])]}},{key:"item.is_favored",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.lead_type === 'will' && value !== null)?[(value)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle-outline")])]:_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"caption"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('OneWord.notAvailableIcon')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('OneWord.notAvailableToolTip')))])])]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(value))+" ")]}},{key:"item.deletion_scheduled_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("date")(value))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }