<template>
    <div v-if="pageRank">
        <v-toolbar flat color="transparent" class="title">
            <v-btn icon @click="$router.push({name: 'admin-website-ranking'})">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>
                {{ $t('AdminWebsiteRankingDetail.title', {company_name: pageRank.account.company_name}) }}
            </v-toolbar-title>
        </v-toolbar>

        <v-card>
            <v-card-title>
                {{ pageRank.account.company_name }}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field
                            :value="pageRank.total"
                            :hint="$t('AdminWebsiteRankingDetail.pageRank.total.hint')"
                            :label="$t('AdminWebsiteRankingDetail.pageRank.total.label')"
                            persistent-hint
                            readonly
                            filled
                            :background-color="fieldBackgroundColor"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <div class="py-2">
                            <v-text-field
                                :value="pageRank.checked_at | date"
                                :label="$t('AdminWebsiteRankingDetail.pageRank.checkedAt.label')"
                                readonly
                            ></v-text-field>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            :label="$t('AdminWebsiteRankingDetail.pageRank.url.label')"
                            readonly
                            :value="pageRank.url"
                            append-icon="mdi-open-in-new"
                            @click:append="openUrl"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-toolbar flat color="transparent" class="subtitle-2 mt-4" dense>
            {{ $t('AdminWebsiteRankingDetail.a.header') }}
        </v-toolbar>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="2" class="text-center">
                        {{ $t('AdminWebsiteRankingDetail.maxPoints') }}
                    </v-col>
                    <v-col cols="2" class="text-center">
                        {{ $t('AdminWebsiteRankingDetail.yourPoints') }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8">
                        <div class="subtitle-1 text-decoration-underline cursor-pointer" @click="openInfo(a)">
                            {{ $t('AdminWebsiteRankingDetail.a.1title') }}
                        </div>
                        <div class="caption">
                            {{ $t('AdminWebsiteRankingDetail.a.1desc') }}
                        </div>
                    </v-col>
                    <v-col cols="2" class="align-self-center text-center">
                        <div class="text-h6">1</div>
                    </v-col>
                    <v-col cols="2" class="align-self-center text-center">
                        <v-chip color="green lighten-1" v-if="pageRank.a_total === 1">
                            <h6 class="text-h6">{{ pageRank.a_total }}</h6>
                        </v-chip>
                        <v-chip color="red lighten-2" v-else>{{ pageRank.a_total }}</v-chip>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-toolbar flat color="transparent" class="subtitle-2 mt-4" dense>
            {{ $t('AdminWebsiteRankingDetail.b.header') }}
        </v-toolbar>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="2" class="text-center">
                        {{ $t('AdminWebsiteRankingDetail.maxPoints') }}
                    </v-col>
                    <v-col cols="2" class="text-center">
                        {{ $t('AdminWebsiteRankingDetail.yourPoints') }}
                    </v-col>
                </v-row>
                <template v-for="res in bResults">
                    <v-row :key="res.field">
                        <v-col cols="8" class="align-self-center">
                            <div
                                :class="{'text-right': res.total}"
                                class="subtitle-1 text-decoration-underline cursor-pointer align-self-center"
                                @click="openInfo(res)"
                            >
                                {{ res.title }}
                            </div>
                            <div v-if="res.description" class="caption">
                                {{ res.description }}
                            </div>
                        </v-col>
                        <v-col
                            cols="2"
                            class="align-self-center text-center"
                            :class="{'bg-gray-200': res.total}"
                        >
                            <div class="text-h6">{{ res.max }}</div>
                        </v-col>
                        <v-col
                            :class="{'bg-gray-200': res.total}"
                            class="align-self-center text-center"
                            cols="2"
                        >
                            <v-chip color="green lighten-1" v-if="pageRank[res.field] === res.max"
                                    :outlined="!res.total">
                                <h6 class="text-h6">{{ pageRank[res.field] }}</h6>
                            </v-chip>
                            <v-chip color="orange" v-else-if="pageRank[res.field] !== 0" :outlined="!res.total">
                                <h6 class="text-h6">{{ pageRank[res.field] }}</h6>
                            </v-chip>
                            <v-chip color="red lighten-2" v-else :outlined="!res.total">
                                <h6 class="text-h6">{{ pageRank[res.field] }}</h6>
                            </v-chip>
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
        </v-card>

        <v-toolbar flat color="transparent" class="subtitle-2 mt-4" dense>
            {{ $t('AdminWebsiteRankingDetail.c.header') }}
        </v-toolbar>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="2" class="text-center">
                        {{ $t('AdminWebsiteRankingDetail.maxPoints') }}
                    </v-col>
                    <v-col cols="2" class="text-center">
                        {{ $t('AdminWebsiteRankingDetail.yourPoints') }}
                    </v-col>
                </v-row>
                <template v-for="res in cResults">
                    <v-row :key="res.field">
                        <v-col cols="8" class="align-self-center">
                            <div
                                :class="{'text-right': res.total, 'cursor-pointer': res.info, 'text-decoration-underline': res.info}"
                                class="subtitle-1"
                                @click="openInfo(res)"
                            >
                                {{ res.title }}
                            </div>
                            <div v-if="res.description" class="caption">
                                {{ res.description }}
                                <span v-if="res.field === 'c_5' && pageRank.c_5_date">
                                    {{ $t('AdminWebsiteRankingDetail.testDateTime', {date: c5DateTimeFormatted }) }}
                                </span>
                            </div>
                        </v-col>
                        <v-col
                            :class="{'bg-gray-200': res.total}"
                            class="align-self-center text-center"
                            cols="2"
                        >
                            <div class="text-h6">{{ res.max }}</div>
                        </v-col>
                        <v-col
                            :class="{'bg-gray-200': res.total}"
                            class="align-self-center text-center"
                            cols="2"
                        >
                            <v-chip color="green lighten-1" v-if="pageRank[res.field] === res.max"
                                    :outlined="!res.total">
                                <h6 class="text-h6">{{ pageRank[res.field] }}</h6>
                            </v-chip>
                            <v-chip color="orange" v-else-if="pageRank[res.field] !== 0" :outlined="!res.total">
                                <h6 class="text-h6">{{ pageRank[res.field] }}</h6>
                            </v-chip>
                            <v-chip color="red lighten-2" v-else :outlined="!res.total">
                                <h6 class="text-h6">{{ pageRank[res.field] }}</h6>
                            </v-chip>
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
        </v-card>

        <v-toolbar flat color="transparent" class="subtitle-2 mt-4" dense>
            {{ $t('AdminWebsiteRankingDetail.d.header') }}
        </v-toolbar>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="2" class="text-center">
                        {{ $t('AdminWebsiteRankingDetail.maxPoints') }}
                    </v-col>
                    <v-col cols="2" class="text-center">
                        {{ $t('AdminWebsiteRankingDetail.yourPoints') }}
                    </v-col>
                </v-row>
                <template v-for="res in dResults">
                    <v-row :key="res.field">
                        <v-col cols="8" class="align-self-center">
                            <div
                                :class="{'text-right': res.total}"
                                class="subtitle-1 text-decoration-underline cursor-pointer"
                                @click="openInfo(res)"
                            >
                                {{ res.title }}
                            </div>
                            <div v-if="res.description" class="caption">
                                {{ res.description }}
                            </div>
                        </v-col>
                        <v-col
                            :class="{'bg-gray-200': res.total}"
                            class="align-self-center text-center"
                            cols="2"
                        >
                            <div class="text-h6">{{ res.max }}</div>
                        </v-col>
                        <v-col
                            :class="{'bg-gray-200': res.total}"
                            class="align-self-center text-center"
                            cols="2"
                        >
                            <v-chip color="green lighten-1" v-if="pageRank[res.field] === res.max"
                                    :outlined="!res.total">
                                <h6 class="text-h6">{{ pageRank[res.field] }}</h6>
                            </v-chip>
                            <v-chip color="red lighten-2" v-else :outlined="!res.total">
                                <h6 class="text-h6">{{ pageRank[res.field] }}</h6>
                            </v-chip>
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
        </v-card>

        <v-card class="mt-4 mb-12 elevation-2 bg-da-dark" outlined dark>
            <v-card-text>
                <v-row>
                    <v-col cols="8" class="text-right subtitle-1 font-weight-bold align-self-center">
                        {{ $t('AdminWebsiteRankingDetail.sumTitle') }}
                    </v-col>
                    <v-col
                        class="align-self-center text-center"
                        cols="2"
                    >
                        <div class="text-h6">25</div>
                    </v-col>
                    <v-col
                        class="align-self-center text-center"
                        cols="2"
                    >
                        <v-chip color="green lighten-1" v-if="pageRank.total > 20">
                            <div class="text-h6">{{ pageRank.total }}</div>
                        </v-chip>
                        <v-chip color="orange" v-else-if="pageRank.total <= 20 && pageRank.total > 10">
                            <div class="text-h6">{{ pageRank.total }}</div>
                        </v-chip>
                        <v-chip color="red lighten-2" v-else>
                            <div class="text-h6">{{ pageRank.total }}</div>
                        </v-chip>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="infoDialogOpen" width="600">
            <v-card>
                <v-toolbar flat dense class="px-1">
                    <v-icon>mdi-information</v-icon>
                    <div class="ml-2">{{ infoDialogTitle }}</div>
                </v-toolbar>
                <v-card-text>{{ infoDialogText }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="accent"
                        depressed
                        @click="infoDialogOpen=false"
                    >{{ $t('OneWord.ok') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'AdminWebsiteRankingDetail',
    data: () => ({
        pageRank: null,

        infoDialogOpen: false,
        infoDialogText: null,
        infoDialogTitle: null,
    }),
    filters: {
        date(value) {
            return moment(value).format('L');
        },
    },
    computed: {
        date() {
            if (_.get(this.pageRank, 'c_5_date')) {
                return moment(this.pageRank.c_5_date).format('L');
            }

            return null;
        },
        time() {
            if (_.get(this.pageRank, 'c_5_time')) {
                return _.get(this.pageRank, 'c_5_time', '').substr(0, 5);
            }

            return null;
        },
        c5DateTimeFormatted() {
            if (this.time) {
                return this.date + ' - ' + this.$t('OneWord.timeFormat', {time: this.time});
            }

            return this.date
        },
        a() {
            return {
                title: this.$t('AdminWebsiteRankingDetail.a.1title2'),
                info: this.$t('AdminWebsiteRankingDetail.a.1info'),
            };
        },
        bResults() {
            return [
                {
                    title: this.$t('AdminWebsiteRankingDetail.b.1title'),
                    description: this.$t('AdminWebsiteRankingDetail.b.1description'),
                    info: this.$t('AdminWebsiteRankingDetail.b.1info'),
                    max: 3,
                    field: 'b_1',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.b.2title'),
                    description: this.$t('AdminWebsiteRankingDetail.b.2description'),
                    info: this.$t('AdminWebsiteRankingDetail.b.2info'),
                    max: 1,
                    field: 'b_2',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.b.3title'),
                    description: this.$t('AdminWebsiteRankingDetail.b.3description'),
                    info: this.$t('AdminWebsiteRankingDetail.b.3info'),
                    max: 1,
                    field: 'b_3',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.b.4title'),
                    description: this.$t('AdminWebsiteRankingDetail.b.4description'),
                    info: this.$t('AdminWebsiteRankingDetail.b.4info'),
                    max: 3,
                    field: 'b_4',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.b.5title'),
                    description: null,
                    info: this.$t('AdminWebsiteRankingDetail.b.5info'),
                    max: 16,
                    field: 'b_total',
                    total: true,
                },
            ];
        },
        cResults() {
            return [
                {
                    title: this.$t('AdminWebsiteRankingDetail.c.1title'),
                    description: this.$t('AdminWebsiteRankingDetail.c.1description'),
                    info: this.$t('AdminWebsiteRankingDetail.c.1info'),
                    max: 1,
                    field: 'c_1',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.c.2title'),
                    description: this.$t('AdminWebsiteRankingDetail.c.2description'),
                    info: this.$t('AdminWebsiteRankingDetail.c.2info'),
                    max: 1,
                    field: 'c_2',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.c.3title'),
                    description: this.$t('AdminWebsiteRankingDetail.c.3description'),
                    info: this.$t('AdminWebsiteRankingDetail.c.3info'),
                    max: 1,
                    field: 'c_3',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.c.4title'),
                    description: this.$t('AdminWebsiteRankingDetail.c.4description'),
                    info: this.$t('AdminWebsiteRankingDetail.c.4info'),
                    max: 1,
                    field: 'c_4',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.c.5title'),
                    description: this.$t('AdminWebsiteRankingDetail.c.5description'),
                    info: this.$t('AdminWebsiteRankingDetail.c.5info'),
                    max: 1,
                    field: 'c_5',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.c.6title'),
                    description: this.$t('AdminWebsiteRankingDetail.c.6description'),
                    info: this.$t('AdminWebsiteRankingDetail.c.6info'),
                    max: 3,
                    field: 'c_6',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.c.7title'),
                    description: null,
                    info: null,
                    max: 8,
                    field: 'c_total',
                    total: true,
                },
            ];
        },
        dResults() {
            return [
                {
                    title: this.$t('AdminWebsiteRankingDetail.d.1title'),
                    description: this.$t('AdminWebsiteRankingDetail.d.1description'),
                    info: this.$t('AdminWebsiteRankingDetail.d.1info'),
                    max: 0,
                    field: 'd_1',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.d.2title'),
                    description: this.$t('AdminWebsiteRankingDetail.d.2description'),
                    info: this.$t('AdminWebsiteRankingDetail.d.2info'),
                    max: 0,
                    field: 'd_2',
                }, {
                    title: this.$t('AdminWebsiteRankingDetail.d.3title'),
                    description: null,
                    info: this.$t('AdminWebsiteRankingDetail.d.3info'),
                    max: 0,
                    field: 'd_total',
                    total: true,
                },
            ];
        },
        fieldBackgroundColor() {
            if (this.pageRank.total > 20) {
                return 'green lighten-1';
            }
            else if (this.pageRank.total <= 20 && this.pageRank.total > 10) {
                return 'orange';
            }

            return 'red lighten-2';
        },
    },
    methods: {
        load() {
            let pageRank = _.get(this.$route, 'params.page_rank');
            axios.get(`/webapi/page-rank/${pageRank}`).then(response => {
                this.pageRank = _.get(response, 'data.data', {});
            });
        },
        openUrl() {
            window.open(this.pageRank.url, '_blank');
        },
        openInfo(item) {
            if (item.info) {
                this.infoDialogText = item.info;
                this.infoDialogTitle = item.title;
                this.infoDialogOpen = true;
            }
        },
    },
    mounted() {
        this.load();
    },
    beforeRouteEnter(to, from, next) {
        let pageRank = _.get(to, 'params.page_rank');
        axios.get(`/webapi/page-rank/${pageRank}`).then(response => {
            next(vm => {
                vm.pageRank = _.get(response, 'data.data');
            });
        }).catch(err => {
            if (_.get(err, 'response.status') === 403) {
                next({name: 'error-403'});
            }
        });
    },
    beforeRouteUpdate(to, from, next) {
        let pageRank = _.get(to, 'params.page_rank');
        axios.get(`/webapi/page-rank/${pageRank}`).then(response => {
            this.pageRank = _.get(response, 'data.data');
            next();
        }).catch(err => {
            if (_.get(err, 'response.status') === 403) {
                next({name: 'error-403'});
            }
        });
    },
};
</script>

<style scoped>

</style>