<template>
    <v-container fluid class="fill-height">
        <v-row no-gutters justify="center" align="center">
            <v-col cols="12" lg="6">
                <validation-observer v-slot="{handleSubmit}" ref="register">
                    <v-form @submit.prevent="handleSubmit(save)">
                        <v-card>
                            <div class="text-center">
                                <img
                                    v-if="getLogo"
                                    :src="getLogo"
                                    :style="{'height': $vuetify.breakpoint.mdAndDown ? '3rem': '4rem'}"
                                    class="d-inline-block my-12"
                                />
                                <v-icon v-else :size="$vuetify.breakpoint.mdAndDown ? '8rem' : '10rem'">
                                    $vuetify.icons.dein_adieu_logo
                                </v-icon>
                            </div>
                            <v-card-title class="headline">
                                {{ $t('Register.title') }}
                            </v-card-title>
                            <v-card-text>

                                <v-row>
                                    <v-col cols="12">
                                        <v-alert type="error" :value="error.length > 0" dense>{{ error }}</v-alert>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            :name="$t('OneWord.first-name')"
                                            rules="required|max:255"
                                            vid="first_name"
                                        >
                                            <v-text-field
                                                :error-messages="errors"
                                                :label="$t('OneWord.first-name')"
                                                v-model="user.first_name"
                                                autocomplete="given-name"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            :name="$t('OneWord.last-name')"
                                            rules="required|max:255"
                                            vid="last_name"
                                        >
                                            <v-text-field
                                                :error-messages="errors"
                                                :label="$t('OneWord.last-name')"
                                                v-model="user.last_name"
                                                autocomplete="family-name"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            :name="$t('OneWord.email')"
                                            rules="required|email|max:255"
                                            vid="email"
                                        >
                                            <v-text-field
                                                :error-messages="errors"
                                                :label="$t('OneWord.email')"
                                                autocomplete="email"
                                                v-model="user.email"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            :name="$t('OneWord.password')"
                                            rules="required|min:8|max:255"
                                            vid="password"
                                        >
                                            <v-text-field
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                :error-messages="errors"
                                                :hint="$t('User.password.hint')"
                                                :label="$t('OneWord.password')"
                                                :type="show ? 'text' : 'password'"
                                                @click:append="show = !show"
                                                autocomplete="off"
                                                counter
                                                loading
                                                v-model="user.password"
                                            >
                                                <template v-slot:progress>
                                                    <v-progress-linear
                                                        :value="pwdStrengthProgress"
                                                        :color="pwdStrengthColor"
                                                        absolute
                                                        height="7"
                                                    ></v-progress-linear>
                                                </template>
                                            </v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            :name="$t('OneWord.passwordConfirmation')"
                                            rules="required|min:8|max:255"
                                            vid="password_confirmation"
                                        >
                                            <v-text-field
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                :error-messages="errors"
                                                :hint="$t('User.password.hint')"
                                                :label="$t('OneWord.passwordConfirmation')"
                                                :type="show ? 'text' : 'password'"
                                                @click:append="show = !show"
                                                autocomplete="off"
                                                counter
                                                v-model="user.password_confirmation"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <small v-html="$t('Register.infoText')"></small>

                            </v-card-text>

                            <v-card-actions>
                                <v-btn depressed text @click="$router.push({name:'login'})">
                                    {{ $t('OneWord.cancel') }}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn depressed color="primary" type="submit" :loading="loading">
                                    {{ $t('Register.title') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </validation-observer>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import zxcvbn from 'zxcvbn';
import {mapActions, mapGetters} from 'vuex';
import loadEnv from "@/plugins/env";

export default {
    name: 'Register',
    components: {ValidationObserver, ValidationProvider},
    data() {
        return {
            show: false,
            user: {},
            confirm: null,
            loading: false,
            error: '',
        };
    },
    computed: {
        ...mapGetters('env', [
            'getLogo',
        ]),
        pwdStrengthProgress() {
            if (this.user.password) {
                let res = zxcvbn(this.user.password);
                return (res.score + 1) * 20;
            }

            return 0;
        },
        pwdStrengthColor() {
            if (this.pwdStrengthProgress > 80) {
                return 'success';
            }
            if (this.pwdStrengthProgress > 40 && this.pwdStrengthProgress <= 80) {
                return 'warning';
            }
            if (this.pwdStrengthProgress < 40) {
                return 'error';
            }

            return 'transparent';
        },
    },
    methods: {
        ...mapActions('user', {
            setUser: 'set',
        }),
        save() {
            this.loading = true;

            axios.get('/sanctum/csrf-cookie').then(() => {
                axios.post('/webapi/register', this.user).then(response => {
                    this.setUser(response.data.data);
                    this.$ability.update(_.get(response, 'data.abilities'));

                    loadEnv().then(({abilities}) => {
                        this.$ability.update(abilities);
                        this.$router.push({name: 'my-documents'});
                    });
                }).catch(error => {
                    if (_.get(error, 'response.data.errors')) {
                        this.$refs.register.setErrors(_.get(error, 'response.data.errors'));
                    }
                    this.error = _.get(error, 'response.data.message');
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
    },
};
</script>

<style scoped>

</style>
