<template>
    <v-card>
        <v-card-text>

            <div class="text-center">
                <v-icon color="success" size="12rem">mdi-check-circle-outline</v-icon>
            </div>

            <div class="title text-center my-12">
                {{ $t('ProfileCreated.successMessage1') }}
            </div>
            <div class="subtitle-1 text-center my-6">
                {{ $t('ProfileCreated.successMessage2') }}
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'ProfileCreated',
    };
</script>

<style scoped>

</style>
