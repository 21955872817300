import store from '@/store';
import router from '@/router';

const getVisitedSteps = (tool) => {
    return store.getters[tool + '/visitedSteps'] || [0];
}

const getHighestVisitedStep = (tool) => {
    return Math.max(...getVisitedSteps(tool));
}

const getAllowedStep = (tool, step) => {
    return getVisitedSteps(tool).includes(step) ? step : getHighestVisitedStep(tool);
}

/**
 * Redirect to highest visited step (if tool not completed)
 */
const checkCompletion = (tool) => {
    if (store.getters[tool + '/completed'] === true) {
        return;
    }

    let step = getHighestVisitedStep(tool);

    router.replace({
        name: router.currentRoute.name.replace('-completed', '-tool'),
        params: {
            step: step,
        }
    });

    store.dispatch(tool + '/setStep', step);
}

/**
 * Handles the watcher for the url param {tool}/step/:step
 */
const handleStepUrlParam = (tool, urlStep, currentStep) => {

    // disable route handling in component tests
    if ( ! router.currentRoute.name) {
        return;
    }

    // redirect to completed, if url was manually set to lower step
    if (store.getters[tool + '/completed'] && urlStep !== 'completed') {
        router.replace({
            name: router.currentRoute.name.replace('-tool', '-completed'),
        });
        return;
    }

    urlStep = parseInt(urlStep);

    if (urlStep === 0) {

        let highestStep = getHighestVisitedStep(tool);

        if (highestStep > urlStep) {
            router.replace({
                name: router.currentRoute.name,
                params: {
                    step: highestStep,
                }
            });
        }

        store.dispatch(tool + '/setStep', highestStep);
    }

    else if (urlStep < currentStep) {

        router.push({
            name: router.currentRoute.name,
            params: {
                step: urlStep,
            }
        });

        store.dispatch(tool + '/setStep', urlStep);

    } else if (urlStep > currentStep || isNaN(urlStep)) {

        let allowedStep = getAllowedStep(tool, urlStep);

        if (allowedStep !== urlStep) {
            router.replace({
                name: router.currentRoute.name,
                params: {
                    step: allowedStep,
                }
            });
        }

        store.dispatch(tool + '/setStep', allowedStep);
    }
    
}

export {
    getHighestVisitedStep,
    handleStepUrlParam,
    checkCompletion,
}