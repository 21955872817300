<template>
    <div>
        <v-toolbar class="mt-4" color="transparent" dense flat>
            <v-toolbar-title>
                {{ $t("AccountPersons.title") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed small @click="createDialog" data-cy="btn-add-person">
                <v-icon left>mdi-plus</v-icon>
                {{ $t("AccountPersons.btnAddPerson") }}
            </v-btn>
        </v-toolbar>

        <v-alert type="info">
            <span v-html="$t('AccountPersons.listInfoText')"></span>
        </v-alert>

        <v-alert v-if="hasNoMainContact" data-cy="alert-no-main-contact" type="error">
            <span v-html="$t('AccountPersons.noMainContact')"></span>
        </v-alert>

        <div v-if="loading && !updating" class="text-center">
            <v-progress-circular
                indeterminate
                color="primary"
                class="my-10"
            ></v-progress-circular>
        </div>

        <div class="lg:grid lg:grid-cols-2 lg:gap-x-5">
            <v-card
                v-for="contact in contacts"
                :key="contact.id"
                :data-cy="loading === false ? 'contacts-loaded' : null"
                elevation="2"
                class="mb-4"
                :loading="loading && updating && updating.id === contact.id"
                :disabled="loading || !loaded"
            >
                <div class="block bg-gray-200" :class="contact.is_primary ? 'is-primary' : null">
                    <v-card-title>
                        {{ contact.full_name }}
                        <v-chip v-if="contact.is_primary" color="primary" class="ml-4" label outlined small>
                            {{ $t("OneWord.primaryPerson") }}
                        </v-chip>
                    </v-card-title>

                    <v-card-subtitle>{{ contact.email }}</v-card-subtitle>

                    <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="absolute right-3 top-3" icon v-bind="attrs" v-on="on" data-cy="v-menu-person-edit">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="openDialog(contact)">
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ $t("OneWord.edit") }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="setPrimary(contact)">
                                <v-list-item-icon>
                                    <v-icon>mdi-account-key</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ $t("AccountPersons.table.useAsPrimaryAccount") }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="removePerson(contact)" data-cy="v-list-item-remove-person">
                                <v-list-item-icon>
                                    <v-icon>mdi-trash-can</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ $t("OneWord.remove") }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>

                <v-divider></v-divider>

                <v-card-text class="lg:flex">
                    <v-card-subtitle class="text-black font-regular p-0 lg:p-4 lg:w-1/3">{{ $t('AccountPersons.subtitle.rights') }}</v-card-subtitle>
                    <div class="pt-0 pr-3 lg:w-2/3">
                        <v-switch
                            color="primary"
                            class="pt-0 pr-3"
                            hide-details
                            data-cy="switch-has-account-login"
                            v-model="contact.has_account_login"
                            @click="updateItem(contact, 'has_account_login')"
                            :label="$t('AccountPersons.hasAccountLogin')"
                            size="small"
                        />

                        <v-switch
                            v-if="account.is_ngo"
                            color="primary"
                            class="pt-0 pr-3 mt-2"
                            hide-details
                            data-cy="switch-has-email-billing"
                            v-model="contact.has_email_billing"
                            @click="updateItem(contact, 'has_email_billing')"
                            :label="$t('AccountPersons.hasEmailBilling')"
                            size="small"
                        />
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text class="lg:flex" :class="!contact.has_account_login ? 'opacity-20' : null">
                    <v-card-subtitle class="text-black font-regular p-0 lg:p-4 lg:w-1/3">{{ $t('AccountPersons.subtitle.emailNotifications') }}</v-card-subtitle>
                    <div class="pt-0 pr-3 lg:w-2/3">
                        <v-switch
                            color="primary"
                            class="pt-0 pr-3"
                            hide-details
                            data-cy="switch-has-campaigns-statistic"
                            v-model="contact.has_campaigns_statistic"
                            @click="updateItem(contact, 'has_campaigns_statistic')"
                            :disabled="!contact.has_account_login"
                            :label="$t('AccountPersons.hasCampaignStatistic')"
                            size="small"
                        />

                        <v-switch
                            v-if="account.is_ngo"
                            color="primary"
                            class="pt-0 pr-3 mt-2"
                            hide-details
                            data-cy="switch-has-email-testator"
                            v-model="contact.has_email_testator"
                            @click="updateItem(contact, 'has_email_testator')"
                            :disabled="!contact.has_account_login"
                            :label="$t('AccountPersons.hasEmailTestator')"
                            size="small"
                        />

                        <v-switch
                            v-if="account.is_ngo"
                            color="primary"
                            class="pt-0 pr-3 mt-2"
                            hide-details
                            data-cy="switch-has-warnings"
                            v-model="contact.has_warnings"
                            @click="updateItem(contact, 'has_warnings')"
                            :disabled="!contact.has_account_login"
                            :label="$t('AccountPersons.hasWarnings')"
                            size="small"
                        />
                    </div>
                </v-card-text>
            </v-card>
        </div>

        <v-card v-if="loaded && contacts.length" class="pa-4 px-6 text-left mb-4 border-2 bg-yellow-100 border-0" flat>
            <h4 class="text-lg mb-2">{{ $t('AccountPersons.newsInfo.headline') }}</h4>
            <span v-html="$t('AccountPersons.newsInfo.text')" />
        </v-card>

        <v-alert v-if="loaded && contacts.length === 0" class="mt-4 text-left" text type="warning">
            <span v-html="$t('OneWord.errorLoadingData')"></span>
        </v-alert>

        <account-person-edit
            :account="account"
            :item="dialogItem"
            :open.sync="isDialogOpen"
            @saved="load"
        ></account-person-edit>
    </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus";
import AccountPersonEdit from "@/pages/admin/Account/AccountPersonEdit.vue";

export default {
    name: "AccountPersons",
    components: { AccountPersonEdit },
    data() {
        return {
            contacts: [],
            loading: false,
            loaded: false,
            saving: false,
            isDialogOpen: false,
            dialogItem: {},
            updating: null,
        };
    },
    props: {
        account: Object,
    },
    computed: {
        salutation() {
            return [
                { text: this.$t("OneWord.salutation.m"), value: "Herr" },
                { text: this.$t("OneWord.salutation.f"), value: "Frau" },
                { text: this.$t("OneWord.salutation.d"), value: "" },
            ];
        },
        hasNoMainContact() {
            if (this.contacts.length) {
                // if no contact is primary, the account has no main contact
                return _.some(this.contacts, {is_primary: true}) ? false : true;
            }
            return false;
        },
    },
    methods: {
        load() {
            this.loaded = false;
            this.loading = true;
            axios
                .get(`/webapi/accounts/${this.account.id}/contacts`)
                .then((response) => {
                    this.contacts = response.data.data;
                })
                .finally(() => {
                    this.loading = false;
                    this.loaded = true;
                });
        },
        setPrimary(item) {
            axios
                .post(`/webapi/accounts/${this.account.id}/contacts/${item.id}/make-primary`)
                .then(() => {
                    this.load();
                    EventBus.$emit("success", this.$t("OneWord.saveSuccess"));
                })
                .catch((error) => {
                    EventBus.$emit("error", _.get(error, "response.data.message"));
                });
        },
        openDialog(item) {
            this.dialogItem = _.cloneDeep(item);
            this.isDialogOpen = true;
        },
        createDialog() {
            this.dialogItem = {};
            this.isDialogOpen = true;
        },
        updateItem(item, type) {
            this.loading = true;
            this.updating = item; // to show loading indicator on contact
            let payload = { action: type };
            payload[type] = item[type]; // get toggle value
            this.$axios
                .post(`/webapi/accounts/${this.account.id}/contacts/${item.id}/services`, payload)
                .then(() => {
                    EventBus.$emit("success", this.$t("OneWord.saveSuccess"));
                    this.load();
                })
                .catch((error) => {
                    // reset value to previous value
                    item[type] = !item[type];
                    
                    if (_.has(error, 'response.data.errors')) {
                        let errors = _.keys(_.get(error, 'response.data.errors'));
                        if (errors.includes('billing_contact_required') || errors.includes('has_email_billing')) {
                            EventBus.$emit('error', this.$t('AccountPersons.errors.oneBillingContactRequired'));
                        } else if (errors.includes('account_login_required')) {
                            EventBus.$emit('error', this.$t('AccountPersons.errors.oneAccountLoginContactRequired'));
                        } else {
                            EventBus.$emit('error', _.get(error, `response.data.errors.${errors[0]}.[0]`));
                        }
                    } else {
                        EventBus.$emit('error', _.get(error, 'response.data.message'));
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        removePerson(person) {
            this.$root
                .$confirm(
                    this.$t("OneWord.confirmUnsavedChangesTitle"),
                    this.$t("ConfirmDelete", { parameter: `${person.full_name}` })
                )
                .then((confirm) => {
                    if (confirm) {
                        this.$axios
                            .delete(
                                `/webapi/accounts/${this.account.id}/contacts/${person.id}`
                            )
                            .then(() => {
                                this.load();
                                EventBus.$emit("success", this.$t("OneWord.saveSuccess"));
                            })
                            .catch(error => {
                                if (_.has(error, 'response.data.errors.account_login_required')) {
                                    EventBus.$emit("error", this.$t('AccountPersons.errors.oneAccountLoginContactRequired'));
                                } else if (_.has(error, 'response.data.errors.billing_contact_required')) {
                                    EventBus.$emit("error", this.$t('AccountPersons.errors.oneBillingContactRequired'));
                                } else if (_.has(error, 'response.data.errors.primary_contact_required')) {
                                    EventBus.$emit("error", this.$t('AccountPersons.errors.primaryContactMayNotBeDeleted'));
                                } else {
                                    EventBus.$emit("error", _.get(error, "response.data.message"));
                                }
                            });
                    }
                });
        },
    },
    mounted() {
        this.load();
    },
};
</script>

<style lang="scss" scoped>
.v-card__subtitle {
    overflow-wrap: normal;
}

.v-input--switch ::v-deep label {
    color: #000;
    padding-left: 10px;
}

.is-primary {
    background: #e0d3da !important;
}
</style>