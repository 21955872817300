import store from '@/store';

export const Features = {
    Testament: 'Testament',
    PatientDecree: 'PatientDecree',
    AdvanceCareDirective: 'AdvanceCareDirective',
    Consulting: 'Consulting',
};

export default {
    install(app) {
        app.prototype.$Features = Features;

        app.prototype.$hasFeature = feature => {
            return _.get(store.getters['env/getFeatures'], feature, false);
        };

        app.prototype.$isCountry = country => {
            return store.getters['env/getCountry'].toLowerCase() === country.toLowerCase();
        };
    },
}