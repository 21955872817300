<template>
    <v-card data-cy="my-documents-item">
        <v-menu left>
            <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn color="grey" dark v-on="{ ...tooltip, ...menu }" icon class="ma-2 right-0" absolute>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('OneWord.more') }}</span>
                </v-tooltip>
            </template>
            <v-list dense>
                <template v-if="hasStatus">
                    <v-subheader>STATUS</v-subheader>
                    <v-list-item :disabled="!document.download_url" @click="document.download_url ? $emit('download') : null">
                        <v-list-item-icon>
                            <v-icon :color="getStatusColor('downloaded_at')">{{ getIcon('downloaded_at') }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('OneWord.download') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="markAsSigned">
                        <v-list-item-icon>
                            <v-icon :color="getStatusColor('signed_at')">{{ getIcon('signed_at') }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('MyDocumentItem.markAsSigned') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="markAsArchived">
                        <v-list-item-icon>
                            <v-icon :color="getStatusColor('archived_at')">{{ getIcon('archived_at') }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('MyDocumentItem.markAsArchived') }}</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>
                <v-list-item @click="$emit('delete')" data-cy="menu-btn-clear">
                    <v-list-item-icon>
                        <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('OneWord.delete') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <div class="text-center px-6 pt-6 pb-1">
            <div class="d-inline-block pa-6 primary lighten-1 rounded-full" style="width: 7rem">
                <v-icon size="4rem" color="white">{{ docIcon }}</v-icon>
            </div>
        </div>
        <div class="flex flex-row px-4 justify-between align-middle">
            <div>
                <v-chip
                    v-if="isDraft"
                    class="ma-0"
                    label
                    x-small
                >{{ $t('OneWord.draft') }}</v-chip>
            </div>
            <div>
                <v-icon
                    v-if="hasStatus"
                    :color="documentStatusColor()"
                    class="ma-0"
                    right
                    x-small
                >mdi-circle</v-icon>
                <v-icon v-else color="white" x-small>mdi-circle</v-icon>
            </div>
        </div>
        <div class="border-t border-solid border-gray-400 mb-2 mt-2"></div>
        <v-card-text class="py-0">
            <div class="title text-nowrap text-truncate">{{ docTitle }}</div>
            <div class="text-nowrap text-truncate">
                <span v-html="personName"></span>
            </div>
        </v-card-text>
        <v-card-actions class="px-4">
            <div class="inline-block caption grey--text">{{ document.created_at | date }}</div>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        @click="$router.push({name: 'patientenverfuegung-edit', params: {patient_decree_id: document.id}})"
                        color="grey lighten-1"
                        dark
                        depressed
                        fab
                        data-cy="btn-edit"
                        v-if="document.type === 'patient_decree'"
                        v-on="on"
                        x-small
                        class="mr-2"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        @click="$router.push({path: `/vorsorgeauftrag/${document.id}`})"
                        color="grey lighten-1"
                        dark
                        depressed
                        fab
                        data-cy="btn-edit"
                        v-if="document.type === 'advance_care_directive'"
                        v-on="on"
                        x-small
                        class="mr-2"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('OneWord.edit') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-if="document.download_url" fab dark depressed x-small color="grey lighten-1"
                           @click="$emit('download')" v-on="on">
                        <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('OneWord.downloadPdf') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-if="document.website_url" fab dark depressed x-small color="grey lighten-1"
                           @click="openUrl(document.website_url)" v-on="on">
                        <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('OneWord.openUrl') }}</span>
            </v-tooltip>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    name: 'MyDocumentItem',
    props: {
        document: {
            type: Object,
            default() {
                return {
                    type: null,
                    person_name: null,
                    created_at: null,
                };
            },
        },
    },
    filters: {
        date(value) {
            return moment(value).format('L');
        },
    },
    computed: {
        personName() {
            return _.get(this.document, 'person_name', '&nbsp;');
        },
        hasStatus() {
            switch (this.document.type) {
                case 'testament':
                case 'patient_decree':
                case 'advance_care_directive':
                    return true;
                default:
                    return false;
            }
        },
        docTitle() {
            switch (this.document.type) {
                case 'testament':
                    return this.$t('OneWord.testament');
                case 'patient_decree':
                    return this.$t('OneWord.patientDecree');
                case 'advance_care_directive':
                    return this.$t('AppUserNav.advance_care_directive');
                default:
                    return '';
            }
        },
        docIcon() {
            switch (this.document.type) {
                case 'testament':
                    return 'mdi-gavel';
                case 'patient_decree':
                    return 'mdi-file-sign';
                case 'advance_care_directive':
                    return 'mdi-file-account-outline';
                default:
                    return '';
            }
        },
        isDraft() {
            return _.get(this.document, 'is_draft', false);
        },
    },
    methods: {
        markAsSigned() {
            if (this.document.type === 'patient_decree') {
                axios.post(`/webapi/patient-decree/${this.document.id}/mark`, {
                    mark: 'signed',
                }).then(() => {
                    this.$emit('mark');
                });
            }

            if (this.document.type === 'testament') {
                axios.post(`/webapi/testaments/${this.document.id}/mark`, {
                    mark: 'signed',
                }).then(() => {
                    this.$emit('mark');
                });
            }

            if (this.document.type === 'advance_care_directive') {
                axios.post(`/webapi/advance-care-directive/${this.document.id}/mark`, {
                    mark: 'signed',
                }).then(() => {
                    this.$emit('mark');
                });
            }
        },
        markAsArchived() {
            if (this.document.type === 'patient_decree') {
                axios.post(`/webapi/patient-decree/${this.document.id}/mark`, {
                    mark: 'archived',
                }).then(() => {
                    this.$emit('mark');
                });
            }

            if (this.document.type === 'testament') {
                axios.post(`/webapi/testaments/${this.document.id}/mark`, {
                    mark: 'archived',
                }).then(() => {
                    this.$emit('mark');
                });
            }

            if (this.document.type === 'advance_care_directive') {
                axios.post(`/webapi/advance-care-directive/${this.document.id}/mark`, {
                    mark: 'archived',
                }).then(() => {
                    this.$emit('mark');
                });
            }
        },
        getStatusColor(field) {
            let val = _.get(this.document, field, false);
            if (val) {
                return 'green';
            }
            return 'red';
        },
        getIcon(field) {
            let val = _.get(this.document, field, false);
            if (val) {
                return 'mdi-check-circle';
            }
            return 'mdi-checkbox-blank-circle';
        },
        documentStatusColor() {
            if (
                _.get(this.document, 'downloaded_at', false)
                && _.get(this.document, 'signed_at', false)
                && _.get(this.document, 'archived_at', false)
            ) {
                return 'green';
            }

            if (_.get(this.document, 'downloaded_at', false)) {
                return 'orange';
            }

            return 'red';
        },
        openUrl(url) {
            window.open(url, '_blank');
        },
    },
};
</script>

<style scoped>

</style>
