<template>
    <div>
        <v-card class="mt-4" v-if="webinars.length">
            <v-card-title>
                {{ $t('AdminWebinars.list.title') }}
            </v-card-title>

            <v-card-text class="pb-0">
                <v-btn-toggle v-model="langForLinks" mandatory dense>
                    <template v-for="lang in languages">
                        <v-btn :key="`lang-btn-${lang.locale}`" :value="lang.locale" :data-cy="'btn-webinar-lang-' + lang.locale.substring(0, 2)">
                            <template v-if="$vuetify.breakpoint.smAndDown">
                                <span class="uppercase">{{ lang.locale.substring(0, 2) }}</span>
                            </template>
                            <template v-else>
                                {{ lang.label }}
                            </template>
                        </v-btn>
                    </template>
                </v-btn-toggle>

                <v-alert type="info" class="mt-6">
                    {{ $t('AdminWebinars.list.info') }}
                </v-alert>
            </v-card-text>

            <v-card
                outlined
                :class="['mx-4', 'mt-4', 'p-4', hasSubscription(webinar) ? 'subscribed' : 'not-subscribed']"
                v-for="(webinar, index) in webinars"
                :key="webinar.id"
                :data-cy="'webinar-item-' + index"
            >
                <v-row class="mx-1 py-3">
                    <v-col cols="12" lg="1" class="text-center">
                        <v-card flat outlined class="w-16 mt-1">
                            <div class="bg-da-primary text-white subtitle-2 text-uppercase calendar-background">{{ localizeDate(webinar.date, 'MMM', langForLinks).substring(0, 3) }}</div>
                            <div class="text-h5 py-1">{{ localizeDate(webinar.date, 'D', langForLinks) }}</div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="5">
                        <div class="my-1 subtitle-2 text-gray-500">{{ localizeDate(webinar.date, 'dddd', langForLinks) }}, {{ localizeDate(webinar.date) }}</div>
                        <h1 class="title mb-1">{{ webinar.title }}</h1>
                        <div class="text-gray-500">{{ localizeTime(webinar.start_time) }} - {{ localizeTime(webinar.end_time) }}</div>
                    </v-col>
                    <v-col cols="12" lg="6" class="mt-7">
                        <div class="mt-2 mb-4 clamp-3 cursor-pointer" @click="dialog = webinar">{{ webinar.description }}</div>
                        <v-chip
                            :color="isPast(webinar) || ! hasSubscription(webinar) ? 'gray' : 'success'"
                            class="text-uppercase absolute top-6 right-6"
                            depressed
                            small
                        >
                            <span v-if="isPast(webinar)" :data-cy="'webinar-' + index + '-status-past'">{{ $t('AdminWebinars.status.past') }}</span>
                            <span v-else-if="hasSubscription(webinar)" :data-cy="'webinar-' + index + '-status-active'">{{ $t('AdminWebinars.status.active') }}</span>
                            <span v-else :data-cy="'webinar-' + index + '-status-inactive'">{{ $t('AdminWebinars.status.inactive') }}</span>
                        </v-chip>
                    </v-col>
                </v-row>
                <v-row class="border-t pt-3">
                    <v-col class="flex py-0">
                        <v-switch
                            color="primary"
                            class="ml-6 pt-0 pr-3"
                            :id="'webinar-switch-' + webinar.id"
                            :input-value="hasSubscription(webinar)"
                            :disabled="isPast(webinar)"
                            @click="toggleSubscription(webinar)"
                            :data-cy="'webinar-toggle-' + index"
                        />

                        <template v-if="hasSubscription(webinar)">
                            <v-text-field
                                cols="11"
                                :key="`field-${profile.id}`"
                                :value="addWebinarToUrl(profile.backlinks.webinars[localeCode], webinar.id)"
                                data-cy="webinars-backlink"
                                background-color="grey lighten-3"
                                class="mr-2 mt-1"
                                flat
                                hide-details
                                prepend-inner-icon="mdi-link"
                                readonly
                                single
                                solo
                            >
                                <template #append-outer>
                                    <v-btn
                                        v-if="!isPast(webinar)"
                                        icon
                                        class="-mt-1"
                                        color="gray"
                                        @click="copyToClipboard(addWebinarToUrl(profile.backlinks.webinars[localeCode], webinar.id).toString())"
                                    >
                                        <v-icon size="x-large">mdi-content-copy</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="!isPast(webinar)"
                                        icon
                                        class="-mt-1"
                                        @click="showQRCode(profile, webinar.id, $t('AdminTools.backlinks.webinar'))"
                                    >
                                        <v-icon size="x-large">mdi-qrcode</v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        class="-mt-1"
                                        @click="showRegistrations(webinar)"
                                        data-cy="btn-show-registrations"
                                    >
                                        <v-icon size="x-large">mdi-badge-account</v-icon>
                                        <span data-cy="webinar-registrations-count">{{ webinar.affiliate_registrations_count }}</span>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </template>

                        <label v-else :for="'webinar-switch-' + webinar.id" class="py-4">{{ $t('AdminWebinars.list.toggle') }}</label>
                    </v-col>
                </v-row>
            </v-card>

            <v-card-title class="border-t mt-9 pt-6 pb-0">
                <span class="mx-4">{{ $t('AdminWebinars.title') }}</span>
            </v-card-title>

            <v-card-text v-if="profile" class="mt-3 px-8 pb-0">
                <div>{{ $t('AdminWebinars.backlink.info') }}</div>

                <v-text-field
                    :key="`field-${profile.id}`"
                    :value="profile.backlinks.webinars[localeCode]"
                    data-cy="webinars-backlink"
                    background-color="grey lighten-3"
                    class="mr2"
                    flat
                    prepend-inner-icon="mdi-link"
                    readonly
                    single
                    solo
                >
                    <template #append-outer>
                        <v-btn
                            icon
                            class="-mt-1"
                            color="gray"
                            @click="copyToClipboard(profile.backlinks.webinars[localeCode])"
                        >
                            <v-icon size="x-large">mdi-content-copy</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            class="-mt-1"
                            @click="showQRCode(profile, null, $t('AdminTools.backlinks.webinar'))"
                        >
                            <v-icon size="x-large">mdi-qrcode</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </v-card-text>
        </v-card>

        <!-- DIALOGS BELOW -->

        <v-dialog
            v-if="dialog"
            v-model="dialog"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">{{ dialog.title }}</v-card-title>
                <v-card-text class="mt-5">{{ dialog.description }}</v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">{{ $t('OneWord.ok') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="registrations.show"
            v-model="registrations.show"
            width="600"
        >
            <v-card>
                <v-card-title class="text-h5 grey lighten-2" data-cy="webinar-registrations-dialog-headline">{{ $t('OneWord.registrations') }} ({{ registrations.data.length }})</v-card-title>
                <v-card-text class="mt-5">
                    <v-simple-table
                        data-cy="webinar-registrations-dialog-table"
                        fixed-header
                        height="300px"
                        dense
                    >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">{{ $t('OneWord.firstName') }}</th>
                                    <th class="text-left">{{ $t('OneWord.lastName') }}</th>
                                    <th class="text-left">{{ $t('OneWord.email') }}</th>
                                    <th class="text-left">{{ $t('OneWord.registeredAt') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in registrations.data"
                                    :key="item.id"
                                >
                                    <td>{{ item.first_name }}</td>
                                    <td>{{ item.last_name }}</td>
                                    <td>{{ item.email }}</td>
                                    <td class="whitespace-nowrap">{{ localizeDate(item.created_at) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        v-if="registrations.data.length > 0"
                        @click="downloadRegistrations(registrations.webinar)"
                        data-cy="btn-webinar-registrations-dialog-download"
                        color="primary"
                        text
                    >
                        <v-icon left>mdi-download</v-icon>
                        {{ $t('OneWord.download') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="registrations.show = false"
                        data-cy="btn-close-registrations"
                        color="primary"
                        text
                    >{{ $t('OneWord.ok') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="qr.show" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">{{ qr.headline }}</v-card-title>
                <a :href="qr.backlink" target="_blank"><img :src="qr.image"
                                                            :alt="qr.filename"
                                                            width="800"
                                                            height="800"
                                                            id="qrcode"></a>
                <v-divider></v-divider>
                <div class="shortlink flex" ref="dialog">
                    <v-text-field
                        :value="qr.shortlink"
                        solo
                        flat
                        readonly
                        single
                        prepend-inner-icon="mdi-link"
                        background-color="grey lighten-3"
                        class="mr2"
                    ></v-text-field>
                    <v-btn
                        icon
                        class="inline-block ma-2 mr-0"
                        @click="copyToClipboard(qr.shortlink, $refs.dialog)"
                    >
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                </div>
                <v-divider></v-divider>
                <v-card-actions class="justify-space-between">
                    <v-btn
                        small
                        color="primary"
                        @click="downloadQRCode()"
                    >
                        <v-icon left>mdi-download</v-icon>
                        {{ $t('OneWord.download') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="qr.show = false"
                    >
                        {{ $t('OneWord.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';
import {localizeDate, localizeTime} from '@/composables/Helpers';

export default {
    name: 'AdminWebinars',
    props: {
        profile: Object,
        languages: Array,
        locale: String,
    },
    data() {
        return {
            loading: false,
            webinars: [],
            subscriptions: [],
            profiles: [],
            dialog: null,
            langForLinks: 'de-CH', // default locale
            qr: {
                show: false,
                headline: null,
                image: null,
                backlink: null,
                shortlink: null,
            },
            registrations: {
                show: false,
                webinar: null,
                data: [],
            },
        };
    },
    watch: {
        langForLinks() {
            this.load();
        }
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),

        localeCode() {
            return (this.langForLinks || '').substring(0, 2) ?? 'de';
        },
    },
    methods: {
        load() {
            this.loading = true;
            Promise.all([
                axios.get(`/webapi/profiles/${this.profile.id}/webinars`, {
                    headers: {
                        'DA-Accept-Language': this.langForLinks.replace('-', '_'),
                    }
                }),
                axios.get(`/webapi/profiles/${this.profile.id}/webinars?scope=subscribed`)
            ]).then(([webinars, subscriptions]) => {
                this.webinars = _.sortBy(webinars.data.data, 'date');
                this.subscriptions = subscriptions.data.data;
            }).finally(() => {
                this.loading = false;
            });
        },

        isPast(webinar) {
            return webinar.date < moment().format('YYYY-MM-DD');
        },

        hasSubscription(webinar) {
            return _.find(this.subscriptions, {id: webinar.id}) ? true : false;
        },

        toggleSubscription(webinar) {
            axios.put(`/webapi/profiles/${this.profile.id}/webinars/${webinar.id}`).then(response => {
                this.subscriptions = response.data.data;
            });
        },

        addWebinarToUrl(url, webinarId) {
            url = new URL(url);

            if (webinarId) {
                url.searchParams.set('wid', webinarId);
            }

            return url;
        },

        copyToClipboard(value, container) {
            this.$copyText(value, container).then(() => {
                EventBus.$emit('success', this.$t('Clipboard.success'));
            }, () => {
                EventBus.$emit('error', this.$t('Clipboard.failure'));
            });
        },

        /**
         * Get QR code and show in modal
         *
         * Will get the base64 encoded QR code for display
         * as well as the filename (slug + headline + lang).
         */
        showQRCode(profile, webinarId, headline) {
            let backlink = this.addWebinarToUrl(profile.backlinks.webinars[this.localeCode], webinarId).toString();
            let shortlink = this.addWebinarToUrl(profile.shortlinks.webinars[this.localeCode], webinarId).toString();
            axios.get('/webapi/qr-code', {
                params: {
                    url: backlink,
                    filename: [
                        headline,
                        webinarId,
                        this.langForLinks.substring(0, 2),
                    ].join('-'),
                }
            }).then(response => {
                // set the params used in the dialog
                this.qr.backlink = backlink;
                this.qr.shortlink = shortlink;
                this.qr.headline = headline;
                this.qr.image = response.data.image;
                this.qr.filename = response.data.filename;
                this.qr.show = true;
            });
        },

        showRegistrations(webinar) {
            axios.get(`/webapi/profiles/${this.profile.id}/webinars/${webinar.id}/registrations`).then(response => {
                this.registrations.webinar = webinar;
                this.registrations.data = response.data.data;
                this.registrations.show = true;
            });
        },

        downloadRegistrations(webinar) {
            window.open(`/webapi/profiles/${this.profile.id}/webinars/${webinar.id}/registrations?format=xlsx`, '_blank');
        },

        /**
         * Download the QR code currently displayed in the dialog.
         */
        downloadQRCode() {
            const params = new URLSearchParams({
                url: this.qr.backlink,
                filename: this.qr.filename,
            });

            window.location.href = '/webapi/qr-code/download?' + params.toString();
        },

        localizeTime,
        localizeDate,
    },
    mounted() {
        this.langForLinks = this.locale;
        this.load();
    },
};
</script>

<style scoped lang="scss">
.not-subscribed {
    .row:first-child {
        opacity: 0.8;
    }

    .calendar-background {
        background-color: #888 !important;
    }
}

.v-card__title {
    word-break: normal;
}

.clamp-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.v-btn-toggle .v-item--active {
    background: #faebf4;
    color: #523746 !important;
}
</style>
