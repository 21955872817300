import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: [
        'advanceCareDirective',
        'consulting',
        'patientDecree',
        'testament',
    ],
});

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

/* eslint-disable no-new */
const store = new Vuex.Store({
    modules: modules,
    strict: debug,
    plugins: debug ? [vuexLocal.plugin] : [vuexLocal.plugin],
});

export default store;

export const getStore = () => {
    return new Vuex.Store({
        modules: modules,
    });
}