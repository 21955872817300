<template>
    <div></div>
</template>

<script>
export default {
    name: 'TestamentInvalidAffiliateLink',
    data() {
        return {
            profile: null,
        };
    },
    methods: {
        load() {
            let profileSlug = _.get(this.$route.params, 'profile_slug');
            this.$axios.get(`/api/profiles/npo/${profileSlug}`).then(response => {
                this.profile = _.get(response, 'data.data');
            }).finally(() => {
                this.redirect();
            });
        },
        redirect() {
            if (_.isString(_.get(this.profile, 'backlink_testament'))) {
                window.location.href = _.get(this.profile, 'backlink_testament') + '?legacy=true';
            } else {
                this.$router.push({name: 'testament-tool'});
            }
        },
    },
    mounted() {
        this.load();
    }
}
</script>

<style scoped>

</style>