import casl from '@/plugins/casl';
import store from '@/store';
import Vue from 'vue';
import {abilitiesPlugin} from '@casl/vue';
import {Ability} from '@casl/ability';
import {EventBus} from '@/utils/event-bus';

export default async function loadEnv() {
    const thenable = {
        then(resolve) {
            axios.get('/webapi/env').then(response => {
                let env = _.get(response, 'data.data', {});
                store.dispatch('env/setEnv', env);

                let user = _.get(response, 'data.data.user', {});
                store.dispatch('user/set', user);

                let abilities = [];
                if (_.has(response, 'data.data.abilities')) {
                    // abilitiesPlugin.update(_.get(response, 'data.data.abilities'));
                    abilities = _.get(response, 'data.data.abilities');
                    Vue.use(abilitiesPlugin, new Ability(abilities));
                } else {
                    Vue.use(abilitiesPlugin, casl);
                }

                // todo: check if this can be refactored, some components still depend on this event
                if (store.getters['env/isAuthenticated']) {
                    EventBus.$emit('logged-in');
                }

                resolve({
                    env,
                    user,
                    abilities
                });
            });
        },
    };

    return thenable;
}