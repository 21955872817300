var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('ProfileForm.profileTitle'),"mode":"eager","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"label":_vm.$t('ProfileForm.profileTitle')},model:{value:(_vm.profile.profile_title),callback:function ($$v) {_vm.$set(_vm.profile, "profile_title", $$v)},expression:"profile.profile_title"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('ProfileForm.profileDescription'),"mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v(_vm._s(_vm.$t('ProfileForm.profileDescription')))]),_c('tiptap-vuetify',{staticClass:"mt-2",attrs:{"card-props":{outlined: true, tile: true, color: errors.length > 0 ? 'error' : null},"extensions":_vm.extensions},model:{value:(_vm.editorContent),callback:function ($$v) {_vm.editorContent=$$v},expression:"editorContent"}}),_c('div',{staticClass:"red--text mt-1"},[_vm._v(_vm._s(errors[0])+" ")])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('ProfileForm.profileKeyFigures'),"mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('ProfileForm.profileKeyFigures')))]),_c('tiptap-vuetify',{staticClass:"mt-2",attrs:{"card-props":{outlined: true, tile: true, color: errors.length > 0 ? 'error' : null},"extensions":_vm.extensions},model:{value:(_vm.keyFiguresContent),callback:function ($$v) {_vm.keyFiguresContent=$$v},expression:"keyFiguresContent"}}),_c('div',{staticClass:"red--text mt-1"},[_vm._v(_vm._s(errors[0])+" ")])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('ProfileForm.profileCategories'),"rules":_vm.profileCategories.length > 0 ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.profileCategories.length === 0,"error-messages":errors,"hint":_vm.$t('ProfileForm.profileCategories'),"items":_vm.profileCategories,"label":_vm.$t('ProfileForm.profileCategories'),"loading":_vm.profileCategoriesLoading,"chips":"","data-cy":"service-categories","deletable-chips":"","item-text":"name","item-value":"id","multiple":"","persistent-hint":"","return-object":""},on:{"input":function($event){return _vm.limitSelection($event, 3)}},model:{value:(_vm.profile.service_categories),callback:function ($$v) {_vm.$set(_vm.profile, "service_categories", $$v)},expression:"profile.service_categories"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('ProfileForm.profileServices'),"rules":_vm.profileServices.length > 0 ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-4",attrs:{"disabled":_vm.profileServices.length === 0,"error-messages":errors,"hint":_vm.$t('ProfileForm.profileServicesHint'),"items":_vm.profileServices,"label":_vm.$t('ProfileForm.profileServices'),"loading":_vm.profileServicesLoading,"chips":"","data-cy":"services","deletable-chips":"","item-text":"name","item-value":"id","multiple":"","persistent-hint":"","return-object":""},model:{value:(_vm.profile.services),callback:function ($$v) {_vm.$set(_vm.profile, "services", $$v)},expression:"profile.services"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }