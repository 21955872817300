<template>
    <div>
        <v-toolbar dense flat color="transparent">
            <v-toolbar-title class="title">
                {{ $t('AdminLeads.title') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn depressed color="accent" class="text-none" href="/leads/export">
                <v-icon left>mdi-microsoft-excel</v-icon>
                {{ $t('AdminLeads.table.downloadExcel') }}
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-card-text class="pa-0">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :options.sync="options"
                    :server-items-length="meta.total"
                    @update:page="load"
                    @update:sort-by="load"
                    @update:sort-desc="load"
                    @update:items-per-page="load"
                    @click:row="onRowClick"
                    item-key="id"
                    multi-sort
                    data-cy="admin-leads"
                >
                    <template v-slot:top>
                        <v-toolbar dense flat>
                            <v-toolbar-title>
                                {{ $t('AdminTestator.personData') }}
                            </v-toolbar-title>
                        </v-toolbar>
                    </template>
                    <template v-slot:header.is_favored="{header}">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">{{ header.text }}</span>
                            </template>
                            <span>{{ $t('AdminLeads.dataTable.isFavoredHeaderTooltip') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.first_name="{item, value}">
                        <span v-if="item.anonymized">({{ $t('OneWord.deleted') }})</span>
                        <span v-else>{{ value }}</span>
                    </template>
                    <template v-slot:item.last_name="{item, value}">
                        <span v-if="item.anonymized">({{ $t('OneWord.deleted') }})</span>
                        <span v-else>{{ value }}</span>
                    </template>
                    <template v-slot:item.lead_type="{value}">
                        {{ $t(`AdminLeads.leadType.items.${value}`) }}
                    </template>
                    <template v-slot:item.lead_source="{value}">
                        {{ $t(`AdminLeads.leadSource.items.${value}`) }}
                    </template>
                    <template v-slot:item.ngo_thanks="{value, item}">
                        <template v-if="item.lead_type === 'will' || item.lead_type === 'consulting'">
                            <v-icon color="blue" v-if="item.ngo_thanks_external">mdi-information</v-icon>
                            <v-icon color="green" v-else-if="value">mdi-check-circle</v-icon>
                            <v-icon color="red" v-else>mdi-minus-circle-outline</v-icon>
                        </template>
                        <v-tooltip v-else bottom>
                            <template v-slot:activator="{ on, attrs }">
                            <span
                                    v-bind="attrs"
                                    v-on="on"
                                    class="caption"
                            >{{ $t('OneWord.notAvailableIcon') }}</span>
                            </template>
                            <span>{{ $t('OneWord.notAvailableToolTip') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.ngo_info="{value}">
                        <v-icon color="green" v-if="value">mdi-check-circle</v-icon>
                        <v-icon color="red" v-else>mdi-minus-circle-outline</v-icon>
                    </template>
                    <template v-slot:item.is_favored="{value, item}">
                        <template v-if="item.lead_type === 'will' && value !== null">
                            <v-icon color="green" v-if="value">mdi-check-circle</v-icon>
                            <v-icon color="red" v-else>mdi-minus-circle-outline</v-icon>
                        </template>
                        <v-tooltip v-else bottom>
                            <template v-slot:activator="{ on, attrs }">
                            <span
                                    v-bind="attrs"
                                    v-on="on"
                                    class="caption"
                            >{{ $t('OneWord.notAvailableIcon') }}</span>
                            </template>
                            <span>{{ $t('OneWord.notAvailableToolTip') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.created_at="{value}">
                        {{ value | dateTime }}
                    </template>
                    <template v-slot:item.deletion_scheduled_at="{value}">
                        {{ value | date }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {EventBus} from "@/utils/event-bus";
import {mapGetters} from "vuex";

export default {
    name: "AdminLeads",
    data() {
        return {
            items: [],
            loading: false,
            meta: {},
            options: {
                sortBy: ['created_at'],
                sortDesc: [true],
            },
            itemsFilter: null,
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        headers() {
            return [
                {text: this.$t('AdminLeads.leadType.title'), value: 'lead_type', sortable: true},
                {text: this.$t('OneWord.firstName'), value: 'first_name', sortable: true},
                {text: this.$t('OneWord.lastName'), value: 'last_name', sortable: true},
                {text: this.$t('AdminLeads.source'), value: 'lead_source', sortable: true},
                {text: this.$t('AdminTestator.ngoThanks'), value: 'ngo_thanks', sortable: true},
                {text: this.$t('AdminTestator.ngoInfo'), value: 'ngo_info', sortable: true},
                {text: this.$t('AdminLeadEdit.section.will.isFavouredInfo'), value: 'is_favored', sortable: true},
                {text: this.$t('OneWord.date'), value: 'created_at', sortable: true},
                {text: this.$t('AdminLeads.table.deletionScheduledAt'), value: 'deletion_scheduled_at', sortable: false},
            ];
        },
    },
    filters: {
        date(value) {
            if (value) {
                return moment(value).format('L');
            }

            return null;
        },
        dateTime(value) {
            if (value) {
                return moment(value).format('LLL');
            }

            return null;
        },
    },
    methods: {
        load() {
            this.loading = true;

            let params = {
                page: this.options.page,
                sort_by: this.options.sortBy,
                sort_desc: this.options.sortDesc,
                per_page: this.options.itemsPerPage,
            };

            axios.get(`/webapi/leads`, {params: params}).then(response => {
                this.items = response.data.data;
                this.meta = response.data.meta;
            }).catch(error => {
                EventBus.$emit('error', error.data.message);
            }).finally(() => {
                this.loading = false;
            });
        },
        onRowClick(item) {

            this.$router.push({
                name: 'admin-lead-edit',
                params: {lead_id: item.id},
            });
        }
    },
    mounted() {
        this.load();
    }
}
</script>

<style scoped>

</style>