<template>
    <div>
        <v-toolbar flat color="transparent" class="title">
            {{ $t('AppAdminNav.websiteRanking') }}
            <v-spacer></v-spacer>
            <v-btn
                v-if="hasOwnItem"
                color="primary"
                depressed
                @click="openDetails(ownItem)"
                class="hidden-xs-only"
            >{{ $t('AdminWebsiteRanking.openOwnRanking') }}
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-data-table
                :headers="headers"
                :item-class="itemClass"
                :items="itemsFiltered"
                :loading="loading"
                :options.sync="options"
                :search="search"
                disable-pagination
                hide-default-footer
                @click:row="openDetails"
            >
                <template v-slot:top>
                    <v-row no-gutters>
                        <v-col cols="12" md="6">
                            <v-select
                                :items="serviceCategories"
                                :label="$t('AdminWebsiteRanking.filterCategories')"
                                chips
                                clearable
                                dense
                                hide-details
                                item-text="name"
                                item-value="id"
                                multiple
                                prepend-icon="mdi-filter-variant"
                                class="pa-4"
                                solo
                                v-model="filter"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="search"
                                :label="$t('OneWord.search')"
                                class="pa-4"
                                clearable
                                dense
                                prepend-icon="mdi-magnify"
                                solo
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.url="{value}">
                    <v-btn
                        v-if="value"
                        color="primary"
                        depressed
                        small
                        @click.stop="openUrl(value)"
                    >
                        <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.total="{value}">
                    <v-chip color="green lighten-1" v-if="value > 20">{{ value }}</v-chip>
                    <v-chip color="orange" v-else-if="value <= 20 && value > 10">{{ value }}</v-chip>
                    <v-chip color="red lighten-2" v-else>{{ value }}</v-chip>
                </template>
                <template v-slot:item.checked_at="{value}">
                    {{ localizeDate(value, 'L') }}
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {localizeDate} from '@/composables/Helpers';
import {EventBus} from "@/utils/event-bus";

export default {
    name: 'AdminWebsiteRanking',
    data: () => {
        return {
            items: [],
            serviceCategories: [],
            loading: false,
            filter: null,
            search: null,
            options: {
                sortBy: [
                    'total',
                ],
                sortDesc: [
                    true,
                ],
            },
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        headers() {
            return [
                {text: this.$t('AdminWebsiteRanking.tableHeader.companyName'), value: 'account.company_name'},
                {text: this.$t('AdminWebsiteRanking.tableHeader.link'), value: 'url', sortable: false},
                {text: this.$t('AdminWebsiteRanking.tableHeader.sum'), value: 'total'},
                {text: this.$t('AdminWebsiteRanking.tableHeader.sumA'), value: 'a_total'},
                {text: this.$t('AdminWebsiteRanking.tableHeader.sumB'), value: 'b_total'},
                {text: this.$t('AdminWebsiteRanking.tableHeader.sumC'), value: 'c_total'},
                {text: this.$t('AdminWebsiteRanking.tableHeader.checkedAt'), value: 'checked_at'},
            ];
        },
        ownItem() {
            return _.find(this.items, {
                account: {
                    id: this.user.account_id,
                },
            });
        },
        hasOwnItem() {
            return !_.isUndefined(this.ownItem);
        },
        itemsFiltered() {
            if (_.isEmpty(this.filter)) {
                return this.items;
            }

            return _.filter(this.items, item => {
                return _.difference(_.map(item.service_categories, 'id'), this.filter).length === 0;
            });
        },
    },
    methods: {
        localizeDate,
        load() {
            this.loading = true;

            window.axios.get('/webapi/page-rank').then(response => {
                this.items = response.data.data;
            }).finally(() => {
                this.loading = false;
            }).catch(err => {
                if (_.get(err, 'response.status') === 403) {
                    this.$router.push({name: 'error-403'});
                }
            });
        },
        openUrl(url) {
            window.open(url, '_blank');
        },
        itemClass(item) {
            if (item.account_id === this.user.account_id) {
                return 'own';
            }
            else if (item.is_hidden) {
                return 'is-hidden';
            }
        },
        openDetails(item) {
            if (item.account_id === this.user.account_id) {
                this.$router.push({name: 'admin-website-ranking-detail', params: {page_rank: item.id}});
            }
        },
        loadCategories() {
            window.axios.get('/api/service-categories/npo').then(response => {
                this.serviceCategories = _.sortBy(response.data.data, 'name');
            });
        },
    },
    mounted() {
        this.load();
        this.loadCategories();

        EventBus.$on('locale-changed', this.loadCategories);
    },
    beforeDestroy() {
        EventBus.$off('locale-changed', this.loadCategories);
    }
};
</script>

<style scoped lang="scss">
.v-data-table {
    ::v-deep {
        .own {
            @apply border-4;
            @apply border-da-pp;
        }

        .is-hidden {
            @apply bg-gray-200;
        }
    }
}
</style>