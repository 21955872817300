<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { EventBus } from "@/utils/event-bus";

export default {
    name: "AccountPersonEdit",
    components: { ValidationObserver, ValidationProvider },
    props: ["item", "open", "account"],
    data() {
        return {
            person: {},
            isDialogOpen: false,
            saving: false,
        };
    },
    watch: {
        item(value) {
            this.person = value;
        },
        open(value) {
            if (_.has(this.$refs, "personForm")) {
                this.$refs.personForm.reset();
            }

            this.isDialogOpen = value;
        },
        isDialogOpen(value) {
            this.$emit("update:open", value);

            if (value === false) {
                this.saving = false;
                this.person = {};
            }
        },
    },
    computed: {
        salutation() {
            return [
                { text: this.$t("OneWord.salutation.m"), value: "Herr" },
                { text: this.$t("OneWord.salutation.f"), value: "Frau" },
                { text: this.$t("OneWord.salutation.d"), value: "" },
            ];
        },
    },
    methods: {
        save() {
            this.saving = true;

            let isUpdate = _.get(this.person, 'id');

            axios({
                url: isUpdate ? `/webapi/accounts/${this.account.id}/contacts/${this.person.id}` : `/webapi/accounts/${this.account.id}/contacts`,
                method: isUpdate ? 'put' : 'post',
                data: this.person,
            })
            .then(() => {
                EventBus.$emit("success", this.$t("OneWord.saveSuccess"));
                this.isDialogOpen = false;
                this.$emit('saved');
            })
            .catch(error => {
                if (_.has(error, 'response.data.errors.email')) {
                    EventBus.$emit("error", _.get(error, 'response.data.errors.email.0'));
                } else {
                    EventBus.$emit("error", _.get(error, "response.data.message"));
                }
            })
            .finally(() => {
                this.saving = false;
            });
        },
    },
};
</script>

<template>
    <v-dialog v-model="isDialogOpen" width="600">
        <validation-observer ref="personForm" v-slot="{ handleSubmit }">
            <v-form data-cy="v-dialog-person" @submit.prevent="handleSubmit(save)">
                <v-card>
                    <v-toolbar class="white--text" color="accent" dense flat>
                        {{ $t("AccountPersons.dialogAddPersonTitle") }}
                    </v-toolbar>
                    <v-card-text class="pt-4">
                        <v-row>
                            <v-col>
                                <validation-provider
                                    v-slot="{ errors }"
                                    :name="$t('OneWord.salutation.label')"
                                >
                                    <v-select
                                        v-model="person.salutation"
                                        :error-messages="errors"
                                        :items="salutation"
                                        :label="$t('OneWord.salutation.label')"
                                        data-cy="v-select-salutation"
                                    ></v-select>
                                </validation-provider>
                            </v-col>
                            <v-col>
                                <validation-provider
                                    v-slot="{ errors }"
                                    :name="$t('OneWord.lastName')"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="person.last_name"
                                        :error-messages="errors"
                                        :label="$t('OneWord.lastName')"
                                        data-cy="v-textfield-lastname"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col>
                                <validation-provider
                                    v-slot="{ errors }"
                                    :name="$t('OneWord.lastName')"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="person.first_name"
                                        :error-messages="errors"
                                        :label="$t('OneWord.firstName')"
                                        data-cy="v-textfield-firstname"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <validation-provider
                                    v-slot="{ errors }"
                                    :name="$t('OneWord.email')"
                                    rules="required|email"
                                >
                                    <v-text-field
                                        v-model="person.email"
                                        :error-messages="errors"
                                        :label="$t('OneWord.email')"
                                        data-cy="v-textfield-email"
                                        type="email"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed @click="isDialogOpen = false">
                            {{ $t("OneWord.cancel") }}
                        </v-btn>
                        <v-btn :loading="saving" color="primary" depressed type="submit">
                            {{ $t("OneWord.save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </validation-observer>
    </v-dialog>
</template>

<style scoped></style>