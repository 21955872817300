import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {EventBus} from '@/utils/event-bus';
import {app as chApp} from '@/locales/deinadieu-ch/app';
import {app as deApp} from '@/locales/deinadieu-de/app';
import {app as atApp} from '@/locales/deinadieu-at/app';
import store from '@/store/index';

Vue.use(VueI18n);

const loadMessages = () => {
    // TODO async imports possible to reduce app size?
    switch (store.getters['env/getCountry']) {
    case 'DE':
        return deApp;
    case 'AT':
        return atApp;
    default:
        return chApp;
    }
};

let envLocales = null;

const getLocales = () => {
    return new VueI18n({
        locale: store.getters['env/getLang'] || 'de',
        fallbackLocale: 'de',
        messages: loadMessages(),
    });
}

EventBus.$on('change-locale', (locale) => {
    usei18n().locale = localeToLang(locale);

    moment.locale(getCurrentLocale());

    axios.post('/webapi/locale', {locale: locale}).then(() => {
        EventBus.$emit('locale-changed', locale);
    });
});

/**
 * Set the language stored in the user session from server, loaded in the ENV request.
 */
store.watch(
    state => {
        return _.get(state, 'env.lang');
    },
    lang => {
        usei18n().locale = localeToLang(lang);
    }
);

function localeToLang(lang) {
    switch (lang) {
        case 'de-CH':
            return 'de';
        case 'en-GB':
            return 'en';
        case 'fr-CH':
            return 'fr';
        case 'it-CH':
            return 'it';
        default:
            return lang;
    }
}

export function getCurrentLocale() {
    switch (usei18n().locale) {
        case 'en':
            return 'en-GB';
        case 'fr':
            return 'fr-CH';
        case 'it':
            return 'it-CH';
        default:
            return 'de-CH';
    }
}

export function usei18n() {
    // if locale is not loaded yet, return default
    if ( ! store.getters['env/isLoaded']) {
        return getLocales()
    }

    // if locales have been loaded from env, return env locales
    if (envLocales) return envLocales

    // if env is set, but locales have not been stored, store and return them
    return envLocales = getLocales()
}

export default getLocales()
