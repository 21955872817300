<template>
    <div>
        <h1 class="title mb-2">
            {{ $t('AdminProfile.title') }}
        </h1>

        <v-data-iterator
            :items="profiles"
            :items-per-page.sync="itemsPerPage"
            :loading="loading"
            hide-default-footer
        >
            <template v-slot:default="props">
                <v-row>
                    <v-col
                        v-for="item in props.items"
                        :key="item.name"
                        cols="12"
                        lg="6"
                    >
                        <v-card>
                            <div class="pa-4">
                                <v-img v-if="logo(item)" :src="logo(item).url" max-height="12rem" contain></v-img>
                                <dein-adieu-logo v-else style="max-height: 12rem"></dein-adieu-logo>
                            </div>
                            <div class="px-4">
                                <v-chip
                                    v-for="pt in item.partner_types"
                                    :key="pt.id"
                                    class="mr-1"
                                    color="accent"
                                    label
                                    outlined
                                    small
                                >{{ pt.name }}
                                </v-chip>
                            </div>
                            <v-card-title>
                                <router-link
                                    v-if="isEditable(item)"
                                    :to="{name: 'admin-edit-profile-description', params: {profile_id: item.id, lang: lang }}"
                                >
                                    {{ item.company_name || $t('AdminProfileList.newProfileTitle') }}
                                </router-link>
                                <span v-else>
                                    {{ item.company_name || $t('AdminProfileList.newProfileTitle') }}
                                </span>
                            </v-card-title>
                            <v-card-subtitle>
                                {{ item.street || $t('OneWord.address') }}, {{ item.zip || $t('OneWord.zip') }}
                                {{ item.place || $t('OneWord.place') }}
                            </v-card-subtitle>
                            <v-card-actions>
                                <v-btn
                                    :disabled="!isEditable(item)"
                                    :hidden="$vuetify.breakpoint.smAndDown && !isEditable(item)"
                                    color="primary"
                                    depressed
                                    @click="edit(item)"
                                >
                                    <v-icon left>mdi-pencil</v-icon>
                                    {{ $t('OneWord.edit') }}
                                </v-btn>
                                <v-spacer></v-spacer>

                                <v-btn
                                    icon
                                    v-if="item.is_draft"
                                    @click="deleteProfile(item)"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>

                                <v-chip
                                    v-if="item.is_in_translation"
                                    class="ma-2 text-uppercase"
                                    color="warning"
                                    label
                                    outlined
                                >
                                    {{ $t('AdminProfileList.inTranslation') }}
                                </v-chip>

                                <v-chip
                                    v-if="item.is_approved"
                                    class="ma-2 text-uppercase"
                                    color="success"
                                    label
                                    outlined
                                >
                                    {{ $t('OneWord.public') }}
                                </v-chip>

                                <v-chip
                                    v-if="item.is_approved !== true && item.is_draft === false"
                                    class="ma-2 text-uppercase"
                                    color="default"
                                    label
                                    outlined
                                >
                                    {{ $t('OneWord.inReview') }}
                                </v-chip>

                                <v-chip
                                    v-if="item.is_draft"
                                    class="ma-2 text-uppercase"
                                    color="warning"
                                    label
                                    outlined
                                >
                                    {{ $t('OneWord.draft') }}
                                </v-chip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-2 text-uppercase"
                                            color="primary"
                                            label
                                            outlined
                                        >
                                            {{ item.locale.substring(0, 2) }}
                                        </v-chip>
                                    </template>
                                    <span>{{ getLang(item.locale) }}</span>
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:loading>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-skeleton-loader type="card, list-item-avatar"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-skeleton-loader type="card, list-item-avatar"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-alert type="info" outlined class="mt-3">
                    {{ $t('AdminProfileList.errors.noData') }}
                </v-alert>
            </template>
            <template v-slot:header>
                <v-card :loading="loading" class="mb-4">
                    <v-card-title>
                        {{ $t('AdminProfileList.myProfiles') }}
                        <v-spacer></v-spacer>
                        <v-btn
                            :block="$vuetify.breakpoint.smAndDown"
                            :class="{'mt-2': $vuetify.breakpoint.smAndDown}"
                            :disabled="$can('ngo', 'Statistic') && profiles.length >= 1"
                            :small="$vuetify.breakpoint.smAndDown"
                            color="accent"
                            depressed
                            outlined
                            @click="add"
                        >
                            <v-icon left>mdi-plus</v-icon>
                            {{ $t('AdminProfileList.addProfile') }}
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-menu offset-y left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :block="$vuetify.breakpoint.smAndDown"
                                    class="text-capitalize"
                                    color="primary"
                                    depressed
                                    small
                                >
                                    {{ $t('AdminProfileList.profileLanguage') }}: {{ selectedLang }}
                                    <v-icon right>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="item in languages"
                                    :key="item.locale"
                                    @click="lang = item.locale"
                                >
                                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <span class="ml-4 hidden-md-and-down">
                            {{ $t('AdminProfileList.selectProfileLangIntoText') }}
                        </span>
                    </v-card-text>
                </v-card>
            </template>
        </v-data-iterator>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {usei18n} from '@/plugins/i18n';
import store from '../../store';
import {EventBus} from '@/utils/event-bus';
import DeinAdieuLogo from '../../components/Icons/DeinAdieuLogo';

export default {
    name: 'AdminProfileList',
    components: {DeinAdieuLogo},
    data() {
        return {
            loading: false,
            itemsPerPage: 1000,
            profiles: [],
            lang: 'de_CH',
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        languages() {
            return [
                {locale: 'de_CH', label: this.$t('OneWord.lang.german')},
                {locale: 'en_GB', label: this.$t('OneWord.lang.english')},
                {locale: 'fr_CH', label: this.$t('OneWord.lang.french')},
                {locale: 'it_CH', label: this.$t('OneWord.lang.italian')},
            ];
        },
        locale() {
            return usei18n().locale.substr(0, 2);
        },
        selectedLang() {
            return _.get(_.find(this.languages, {locale: this.lang}), 'label');
        },
    },
    filters: {
        date(value) {
            return moment(value).format('LL');
        },
    },
    watch: {
        lang(value, old) {
            if (old !== value) {
                this.load();
            }
        },
    },
    methods: {
        ...mapActions('user', {
            loadUser: 'load',
        }),
        load() {
            this.loading = true;

            axios.get(`/webapi/accounts/${this.user.account_id}/profiles`, {
                headers: {
                    'DA-Accept-Language': this.lang,
                },
            }).then((response) => {
                this.profiles = response.data.data;
            }).finally(() => {
                this.loading = false;
            });
        },
        edit(item) {
            this.$router.push({name: 'admin-edit-profile-description', params: {profile_id: item.id, lang: this.lang}});
        },
        isEditable(item) {
            return item.is_in_translation === false;
        },
        add() {
            this.$router.push({name: 'admin-profiles-create', params: {lang: this.lang}});
        },
        logo(item) {
            let logo = _.find(_.get(item, 'profile_images', []), {type: 'logo', lang: this.lang});

            if (_.isUndefined(logo)) {
                logo = _.find(_.get(item, 'profile_images', []), {type: 'logo', lang: 'de_CH'});
            }

            return logo;
        },
        getLang(locale) {
            return _.get(_.find(this.languages, {locale: locale}), 'label', '');
        },
        deleteProfile(profile) {
            this.$root.$confirm(
                this.$t('OneWord.delete'),
                this.$t('ConfirmDelete',
                    {parameter: profile.company_name || this.$t('AdminProfileList.newProfileTitle')}),
            ).then(confirm => {
                if (confirm) {
                    axios.delete(`/webapi/accounts/${this.user.account_id}/profiles/${profile.id}`).then(() => {
                        EventBus.$emit(
                            'success',
                            this.$t('OneWord.itemDeleteSuccess',
                                {item: profile.company_name || this.$t('AdminProfileList.newProfileTitle')}),
                        );
                        this.load();
                    });
                }
            }).catch(error => {
                EventBus.$emit('error', error.data.message);
            });
        },
    },
    mounted() {
        this.load();
    },
    activated() {
        this.load();
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('user/load').then(() => {
            next();
        });
    },
};
</script>

<style scoped lang="scss">
.v-card__title {
    @apply pt-2;
}
</style>
