<template>
    <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(save)">
            <v-card :loading="loading" :disabled="loading" class="mt-4">
                <v-card flat>
                    <v-card-text>
                        <profile-description-form :profile.sync="profile"></profile-description-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="$router.back()" text>{{ $t('OneWord.back') }}</v-btn>
                        <v-btn type="submit" color="accent" :loading="loading">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('OneWord.save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-form>
    </validation-observer>
</template>

<script>
import {ValidationObserver} from 'vee-validate';
import ProfileDescriptionForm from '../admin/form/ProfileDescriptionForm';

export default {
    name: 'CreateProfileDescription',
    components: {ProfileDescriptionForm, ValidationObserver},
    data() {
        return {
            loading: false,
            profile: {},
        };
    },
    computed: {
        profileId() {
            return _.parseInt(_(this.$route.params).get('profile_id'));
        },
    },
    methods: {
        save() {
            this.loading = true;
            return axios.post(
                `/webapi/create-profile/profile-description`,
                this.profile,
            ).then(() => {
                this.$router.push({name: 'create-profile-images-form'});
            }).finally(() => {
                this.loading = false;
            });
        },
    },
    beforeRouteEnter(to, from, next) {
        axios.get(`/webapi/create-profile/profile`).then(response => {
            next(vm => {
                vm.profile = response.data.data;
            });
        });
    },
};
</script>

<style scoped>

</style>
