<template>
    <component
        v-if="isLoaded"
        :is="component"
        :tool="tool"
        @start-tool="startTool"
    />
</template>

<script>
import AffiliateAgreement from './AffiliateAgreement';
import ConsultingTool from '@/pages/consulting/ConsultingTool';
import TestamentTool from '@/pages/testament/TestamentTool';
import PatientDecreeTool from '@/pages/patient-decree/PatientDecreeTool';
import AdvanceCareDirectiveTool from '@/pages/advance-care-directive/AdvanceCareDirectiveTool';
import {mapActions, mapGetters} from 'vuex';
import { handleStepUrlParam, getHighestVisitedStep } from '@/composables/ToolStepper';

export default {
    name: 'AffiliateSwitch',

    components: {
        AffiliateAgreement,
        testament: TestamentTool,
        patientDecree: PatientDecreeTool,
        advanceCareDirective: AdvanceCareDirectiveTool,
        consulting: ConsultingTool,
    },

    props: {
        defaultTool: {
            type: String,
            default: ''
        }
    },

    data: () => {
        return {
            component: 'AffiliateAgreement'
        }
    },

    mounted() {
        this.load();
    },

    watch: {
        /**
         * Handle direct entry to tool step via url.
         */
        '$route.params.step': {
            immediate: true,
            handler(value) {
                if (parseInt(value) === 0) {
                    this.reload();
                }

                handleStepUrlParam(this.tool, value, this.currentStep);
            },
        },

        currentStep(nextStep, prevStep) {
            if (nextStep !== prevStep) {
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        step: nextStep
                    }
                });
            }
        },

        tool() {
            this.reload(); // reload affiliate when tool has changed
        },

        isAuthenticated: {
            immediate: true,
            handler() {
                this.reload(); // reload affiliate when authentication changed
            },
        },

        isLoaded() {
            this.startToolIfLoaded();
        },
    },

    computed: {
        ...mapGetters('env', {
            isAuthenticated: 'isAuthenticated',
            isWhiteLabel: 'isWhiteLabel',
        }),
        ...mapGetters('affiliateProfile', {
            hasProfile: 'hasProfile',
            isExternalLink: 'externalLink',
            isLoaded: 'isLoaded',
        }),
        ...mapGetters('consulting', {
            consultingStep: 'step',
        }),
        ...mapGetters('testament', {
            testamentToolStep: 'step',
        }),
        ...mapGetters('patientDecree', {
            patientDecreeStep: 'step',
        }),
        ...mapGetters('advanceCareDirective', {
            advanceCareDirectiveStep: 'step',
        }),

        agreementAccepted() {
            return this.$store.getters[this.tool + '/agreementAccepted'];
        },

        isDaDirect() {
            return !this.hasProfile && !this.isExternalLink;
        },

        tool() {
            return _.get({
                'consulting-tool': 'consulting',
                'testament-tool': 'testament',
                'patient-decree-tool': 'patientDecree',
                'advance-care-directive-tool': 'advanceCareDirective',
            }, this.$route.name, this.defaultTool);
        },

        currentStep() {
            switch (this.tool) {
            case 'consulting':
                return this.consultingStep;
            case 'testament':
                return this.testamentToolStep;
            case 'patientDecree':
                return this.patientDecreeStep;
            case 'advanceCareDirective':
                return this.advanceCareDirectiveStep;
            default:
                return 1;
            }
        },

        fillsOutTheForm() {
            return getHighestVisitedStep(this.tool) >= 1 && this.agreementAccepted;
        },
    },

    methods: {
        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),

        load() {
            /**
             * If the affiliate is loaded, start the tool immediately,
             * otherwise load the affiliate first. Realized via watcher.
             */
            this.loadAffiliate();
            this.startToolIfLoaded();
        },

        reload() {
            // reset component and params
            this.component = 'AffiliateAgreement';
            this.load();
        },

        setAgreementAccepted() {
            return this.$store.dispatch(this.tool + '/setAgreementAccepted', true);
        },

        startToolIfLoaded() {
            if (this.isLoaded && ! this.$route.query.preview) {
                // if user is authenticated, never show
                // the affiliate/agreement screen
                if (this.isAuthenticated) {
                    this.setAgreementAccepted();
                    this.startTool();
                }

                // if user is in the middle of filling out the tool,
                // don't show the affiliate/agreement screen
                if (this.fillsOutTheForm) {
                    this.setAgreementAccepted();
                    this.startTool();
                }

                if (this.agreementAccepted) {
                    this.startTool();
                }
            }
        },

        startTool() {
            if (this.agreementAccepted && ! this.fillsOutTheForm) {
                this.$store.dispatch(this.tool + '/setStep', 1);
            }

            this.component = this.tool;
        },
    },
};
</script>