var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',[_c('v-card-text',[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.email'),"rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"error-messages":errors,"hint":_vm.$t('User.email.hint'),"label":_vm.$t('OneWord.email'),"persistent-hint":"","type":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.locale'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.languages,"label":_vm.$t('OneWord.locale'),"item-text":"label","item-value":"id"},model:{value:(_vm.user.locale),callback:function ($$v) {_vm.$set(_vm.user, "locale", $$v)},expression:"user.locale"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.numberFormat'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.numberFormats,"label":_vm.$t('OneWord.numberFormat'),"item-text":"label","item-value":"id"},model:{value:(_vm.user.number_format),callback:function ($$v) {_vm.$set(_vm.user, "number_format", $$v)},expression:"user.number_format"}})]}}],null,true)}),_c('v-alert',{staticClass:"mb-2",attrs:{"type":"info"}},[_vm._v(_vm._s(_vm.$t('User.changePasswordInfo')))]),_c('v-alert',{staticClass:"mb-4",attrs:{"type":"error","dense":"","value":_vm.passwordsDoNoMatch,"data-cy":"passwords-not-match","dismissible":""}},[_vm._v(" "+_vm._s(_vm.$t('User.passwordsNotMatch'))+" ")]),_c('v-alert',{staticClass:"mb-4",attrs:{"type":"error","dense":"","value":_vm.currentPasswordIncorrect,"data-cy":"current-pw-incorrect","dismissible":""}},[_vm._v(" "+_vm._s(_vm.$t('OneWord.passwordCurrentHint'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.passwordNew'),"rules":"min:8|max:255","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"hint":_vm.$t('User.password.hint'),"label":_vm.$t('OneWord.passwordNew'),"type":_vm.show ? 'text' : 'password',"autocomplete":"off","data-cy":"password-new","counter":"","loading":""},on:{"click:append":function($event){_vm.show = !_vm.show}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"value":_vm.pwdStrengthProgress,"color":_vm.pwdStrengthColor,"absolute":"","height":"7"}})]},proxy:true}],null,true),model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.passwordNewConfirmation'),"rules":"min:8|max:255","vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"label":_vm.$t('OneWord.passwordNewConfirmation'),"hint":_vm.$t('User.password.hint'),"data-cy":"password-confirm","counter":"","error-messages":errors,"autocomplete":"off"},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}})]}}],null,true)}),(_vm.user.is_registered && _vm.user.password)?_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.passwordCurrent'),"rules":"required_if:password","vid":"current_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"label":_vm.$t('OneWord.passwordCurrent'),"hint":_vm.$t('OneWord.passwordCurrentHint'),"data-cy":"password-current","counter":"","error-messages":errors,"autocomplete":"off"},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.user.current_password),callback:function ($$v) {_vm.$set(_vm.user, "current_password", $$v)},expression:"user.current_password"}})]}}],null,true)}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","data-cy":"btn-save","depressed":"","loading":_vm.loading},on:{"click":function($event){return passes(_vm.save)}}},[_vm._v(" "+_vm._s(_vm.$t('OneWord.save'))+" ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }