<template>
    <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(save)">
            <v-card :loading="saving">
                <v-card-text>
                    <h2 class="title mb-2">
                        {{ $t('PreviewProfileForm.title') }}
                    </h2>

                    <div class="text-center" v-if="false">
                        <v-btn @click="openPreview" x-large color="primary">
                            {{ $t('PreviewProfileForm.btn.openProfilePreview') }}
                        </v-btn>
                    </div>

                    <div class="mt-3" v-html="$t('PreviewProfileForm.infoText')"></div>

                    <validation-provider rules="required" v-slot="{errors}" :name="$t('OneWord.termsOfUse')">
                        <v-checkbox
                            v-model="tac"
                            :error-messages="errors"
                            class="mt-3"
                        >
                            <template v-slot:label>
                                <span v-html="$t('PreviewProfileForm.tac')"></span>
                            </template>
                        </v-checkbox>
                    </validation-provider>

                    <v-alert type="error" dismissible text :value="error.message !== null">
                        <p>{{ error.message }}</p>
                        <ul>
                            <template v-for="(item, index) in error.errors">
                                <li :key="`error-${index}`">{{ item }}</li>
                            </template>
                        </ul>
                        <p class="mt-3">
                            {{ $t('PreviewProfileForm.errors.submitError') }}
                        </p>
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="$router.back()" text>{{ $t('OneWord.back') }}</v-btn>
                    <v-btn color="accent" type="submit" :loading="saving">
                        {{ $t('PreviewProfileForm.btn.create') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {EventBus} from '../../utils/event-bus';

export default {
    name: 'PreviewProfileForm',
    components: {ValidationObserver, ValidationProvider},
    props: {
        error: {
            type: Object,
            default() {
                return {
                    message: null,
                    errors: [],
                };
            },
        },
    },
    data() {
        return {
            tac: null,
            saving: false,
        };
    },
    methods: {
        save() {
            this.saving = true;

            axios.post('/webapi/create-profile/submit', {
                tac: this.tac,
            }).then(() => {
                this.$router.push({name: 'create-profile-created'});
            }).catch(error => {
                EventBus.$emit('error', error.data.message);
            }).finally(() => {
                this.saving = false;
            });
        },
    },
};
</script>

<style scoped>

</style>
