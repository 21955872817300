var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"headline mb-4"},[_vm._v(_vm._s(_vm.$t('OneWord.company')))]),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.company'),"mode":"eager","rules":"required|max:255","vid":"company_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"label":_vm.$t('OneWord.company')},model:{value:(_vm.profile.company_name),callback:function ($$v) {_vm.$set(_vm.profile, "company_name", $$v)},expression:"profile.company_name"}})]}}])}),(!_vm.isTranslateable)?_c('v-alert',{staticClass:"mt-8",attrs:{"dense":"","text":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('ProfileForm.translationInfo'))+" ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.address'),"mode":"eager","rules":"required|max:255","vid":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"disabled":!_vm.isTranslateable,"error-messages":errors,"label":_vm.$t('OneWord.address'),"autocomplete":"street-address"},model:{value:(_vm.profile.street),callback:function ($$v) {_vm.$set(_vm.profile, "street", $$v)},expression:"profile.street"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.poBox'),"mode":"eager","rules":"max:255","vid":"post_office_box"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"disabled":!_vm.isTranslateable,"error-messages":errors,"label":_vm.$t('OneWord.poBox'),"autocomplete":"street-address"},model:{value:(_vm.profile.post_office_box),callback:function ($$v) {_vm.$set(_vm.profile, "post_office_box", $$v)},expression:"profile.post_office_box"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.zip'),"mode":"eager","rules":"required|max:5","vid":"zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!_vm.isTranslateable,"error-messages":errors,"label":_vm.$t('OneWord.zip'),"autocomplete":"postal-code"},model:{value:(_vm.profile.zip),callback:function ($$v) {_vm.$set(_vm.profile, "zip", $$v)},expression:"profile.zip"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.place'),"mode":"eager","rules":"required|max:255","vid":"place"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"disabled":!_vm.isTranslateable,"error-messages":errors,"label":_vm.$t('OneWord.place'),"autocomplete":"address-level2"},model:{value:(_vm.profile.place),callback:function ($$v) {_vm.$set(_vm.profile, "place", $$v)},expression:"profile.place"}})]}}])})],1)],1),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.website'),"mode":"eager","rules":"max:255|url","vid":"website"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"hint":_vm.$t('OneWord.urlHint'),"label":_vm.$t('OneWord.website'),"persistent-hint":"","prepend-inner-icon":"mdi-web"},model:{value:(_vm.profile.website),callback:function ($$v) {_vm.$set(_vm.profile, "website", $$v)},expression:"profile.website"}})]}}])}),_c('h5',{staticClass:"headline mt-6 mb-4"},[_vm._v(_vm._s(_vm.$t('AccountForm.subheaderContact')))]),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.salutation.label'),"mode":"eager","rules":"required","vid":"salutation_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.salutations,"label":_vm.$t('OneWord.salutation.label'),"item-text":"title","item-value":"id"},model:{value:(_vm.profile.salutation_id),callback:function ($$v) {_vm.$set(_vm.profile, "salutation_id", $$v)},expression:"profile.salutation_id"}})]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.first-name'),"mode":"eager","rules":"required|max:255","vid":"first_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"label":_vm.$t('OneWord.first-name'),"autocomplete":"given-name"},model:{value:(_vm.profile.first_name),callback:function ($$v) {_vm.$set(_vm.profile, "first_name", $$v)},expression:"profile.first_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.last-name'),"mode":"eager","rules":"required|max:255","vid":"last_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"label":_vm.$t('OneWord.last-name'),"autocomplete":"family-name"},model:{value:(_vm.profile.last_name),callback:function ($$v) {_vm.$set(_vm.profile, "last_name", $$v)},expression:"profile.last_name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.email'),"mode":"eager","rules":"required|email|max:255","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"hint":_vm.$t('AccountForm.EmailHint'),"label":_vm.$t('OneWord.email'),"autocomplete":"email","persistent-hint":"","prepend-inner-icon":"mdi-at"},model:{value:(_vm.profile.email),callback:function ($$v) {_vm.$set(_vm.profile, "email", $$v)},expression:"profile.email"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.phone'),"mode":"eager","rules":"max:255|phone","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hint":_vm.$t('OneWord.phoneHint'),"label":_vm.$t('OneWord.phone'),"placeholder":_vm.phonePlaceholder,"type":"tel","persistent-hint":"","prepend-inner-icon":"mdi-phone"},model:{value:(_vm.profile.phone),callback:function ($$v) {_vm.$set(_vm.profile, "phone", $$v)},expression:"profile.phone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.mobile'),"mode":"eager","rules":"max:255|phone","vid":"mobile_phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hint":_vm.$t('OneWord.phoneHint'),"label":_vm.$t('OneWord.mobile'),"placeholder":_vm.phonePlaceholder,"type":"tel","persistent-hint":"","prepend-inner-icon":"mdi-cellphone"},model:{value:(_vm.profile.mobile_phone),callback:function ($$v) {_vm.$set(_vm.profile, "mobile_phone", $$v)},expression:"profile.mobile_phone"}})]}}])})],1)],1),_c('v-checkbox',{attrs:{"label":_vm.$t('ProfileComtactForm.hasPermanentOperation')},model:{value:(_vm.profile.has_permanent_operation),callback:function ($$v) {_vm.$set(_vm.profile, "has_permanent_operation", $$v)},expression:"profile.has_permanent_operation"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }