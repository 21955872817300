<template>
    <v-container fluid class="fill-height">
        <v-row no-gutters justify="center" align="center">
            <v-col cols="12" lg="6">
                <validation-observer v-slot="{handleSubmit}" ref="register">
                    <v-form @submit.prevent="handleSubmit(save)">
                        <v-card>
                            <div class="text-center">
                                <img
                                    v-if="getLogo"
                                    :src="getLogo"
                                    :style="{'height': $vuetify.breakpoint.mdAndDown ? '3rem': '4rem'}"
                                    class="d-inline-block my-12"
                                />
                                <v-icon v-else :size="$vuetify.breakpoint.mdAndDown ? '8rem' : '10rem'">
                                    $vuetify.icons.dein_adieu_logo
                                </v-icon>
                            </div>
                            <v-card-title class="headline">
                                {{ $t('SendPassword.title') }}
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <v-alert data-cy="errors" type="error" :value="error.length > 0" dense>{{ error }}</v-alert>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            :name="$t('OneWord.email')"
                                            rules="required|email|max:255"
                                            vid="email"
                                        >
                                            <v-text-field
                                                :error-messages="errors"
                                                :label="$t('OneWord.email')"
                                                autocomplete="email"
                                                data-cy="input-email"
                                                v-model="user.email"
                                                readonly
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            :name="$t('OneWord.password')"
                                            rules="required|min:8|max:255"
                                            vid="password"
                                        >
                                            <v-text-field
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                :error-messages="errors"
                                                :hint="$t('User.password.hint')"
                                                :label="$t('OneWord.password')"
                                                :type="show ? 'text' : 'password'"
                                                @click:append="show = !show"
                                                data-cy="input-password"
                                                autocomplete="off"
                                                counter
                                                loading
                                                v-model="user.password"
                                            >
                                                <template v-slot:progress>
                                                    <v-progress-linear
                                                        :value="pwdStrengthProgress"
                                                        :color="pwdStrengthColor"
                                                        absolute
                                                        height="7"
                                                    ></v-progress-linear>
                                                </template>
                                            </v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            :name="$t('OneWord.passwordConfirmation')"
                                            rules="required|min:8|max:255"
                                            vid="password_confirmation"
                                        >
                                            <v-text-field
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                :error-messages="errors"
                                                :hint="$t('User.password.hint')"
                                                :label="$t('OneWord.passwordConfirmation')"
                                                :type="show ? 'text' : 'password'"
                                                @click:append="show = !show"
                                                data-cy="input-confirmation"
                                                autocomplete="off"
                                                counter
                                                v-model="user.password_confirmation"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn depressed text @click="$router.push({name:'login'})">
                                    {{ $t('OneWord.cancel') }}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn depressed color="primary" type="submit" :loading="loading">
                                    {{ $t('OneWord.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </validation-observer>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {EventBus} from '@/utils/event-bus';
import zxcvbn from 'zxcvbn';
import {mapGetters} from "vuex";

export default {
    name: 'SetPassword',
    components: {ValidationObserver, ValidationProvider},
    data() {
        return {
            user: {},
            loading: false,
            error: '',
            show: false,
        };
    },
    computed: {
        ...mapGetters('env', [
            'getLogo',
        ]),
        pwdStrengthProgress() {
            if (this.user.password) {
                let res = zxcvbn(this.user.password);
                return (res.score + 1) * 20;
            }

            return 0;
        },
        pwdStrengthColor() {
            if (this.pwdStrengthProgress > 80) {
                return 'success';
            }
            if (this.pwdStrengthProgress > 40 && this.pwdStrengthProgress <= 80) {
                return 'warning';
            }
            if (this.pwdStrengthProgress < 40) {
                return 'error';
            }

            return 'transparent';
        },
    },
    methods: {
        save() {
            this.loading = true;

            axios.post('/webapi/password/reset', this.user).then(() => {
                EventBus.$emit('success', this.$t('SetPassword.success'));

                this.$router.push({name: 'login'});
            }).catch(error => {
                this.$refs.register.setErrors(_.get(error, 'response.data.errors', []));
                this.error = _.get(error, 'response.data.message');
            }).finally(() => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.user.email = this.$route.query.email;
        this.user.token = this.$route.query.token;
    },
};
</script>

<style scoped>

</style>
