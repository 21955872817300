<template>
    <v-alert
        v-if="isAuthenticated && hasProminentNotification"
        border="left"
        dense
        text
        type="info"
        data-cy="prominent-notification"
    >
        <v-row align="center">
            <v-col class="grow">
                {{ ld.get(getProminentNotification, 'data.subject') }}
            </v-col>
            <v-col class="shrink">
                <v-btn
                    color="primary"
                    depressed
                    small
                    @click="openNotification(getProminentNotification)"
                >{{ $t('OneWord.show') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "TheProminentNotification",
    data()  {
        return {
            ld: _,
        };
    },
    computed: {
        ...mapGetters('env', [
            'isAuthenticated'
        ]),
        ...mapGetters('notifications', [
            'getUnreadCount',
            'getProminentNotification',
        ]),
        hasProminentNotification() {
            return this.getUnreadCount > 0 && !_.isUndefined(this.getProminentNotification);
        },
    },
    methods: {
        ...mapActions('notifications', {
            loadNotifications: 'load',
            reloadNotifications: 'reload',
        }),
        openNotification(item) {
            axios.put(`/webapi/user/notifications/mark-read`, {id: item.id}).then(() => {
                this.$router.push({name: 'admin-news', query: {item: _.get(item, 'data.news_id')}});
                this.reloadNotifications();
            });
        },
    },
    mounted() {
        if (this.isAuthenticated) {
            this.loadNotifications();
        }

        this.$on('logged-in', this.reloadNotifications);
    }
}
</script>

<style scoped>

</style>