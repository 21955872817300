var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.company'),"rules":"required|max:255","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.company'),"counter":255,"error-messages":errors},model:{value:(_vm.company.company_name),callback:function ($$v) {_vm.$set(_vm.company, "company_name", $$v)},expression:"company.company_name"}})]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.address'),"rules":"required|max:255","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.address'),"error-messages":errors,"counter":255,"autocomplete":"street-address"},model:{value:(_vm.company.street),callback:function ($$v) {_vm.$set(_vm.company, "street", $$v)},expression:"company.street"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.poBox'),"rules":"max:255","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.poBox'),"error-messages":errors,"counter":255,"autocomplete":"street-address"},model:{value:(_vm.company.post_office_box),callback:function ($$v) {_vm.$set(_vm.company, "post_office_box", $$v)},expression:"company.post_office_box"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.zip'),"rules":"required|max:5","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.zip'),"error-messages":errors,"autocomplete":"postal-code"},model:{value:(_vm.company.zip),callback:function ($$v) {_vm.$set(_vm.company, "zip", $$v)},expression:"company.zip"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.place'),"rules":"required|max:255","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.place'),"error-messages":errors,"autocomplete":"address-level2","counter":255},model:{value:(_vm.company.place),callback:function ($$v) {_vm.$set(_vm.company, "place", $$v)},expression:"company.place"}})]}}])})],1)],1),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.website'),"rules":"required|url","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.website'),"error-messages":errors,"counter":255,"prepend-inner-icon":"mdi-web","hint":_vm.$t('OneWord.urlHint')},model:{value:(_vm.company.website),callback:function ($$v) {_vm.$set(_vm.company, "website", $$v)},expression:"company.website"}})]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.callCenter'),"rules":"required|max:255|phone","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.callCenter'),"error-messages":errors,"prepend-inner-icon":"mdi-phone","type":"tel"},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.directPhone'),"rules":"max:255|phone","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.directPhone'),"type":"tel","error-messages":errors,"prepend-inner-icon":"mdi-cellphone"},model:{value:(_vm.company.mobile_phone),callback:function ($$v) {_vm.$set(_vm.company, "mobile_phone", $$v)},expression:"company.mobile_phone"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }