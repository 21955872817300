<template>
    <svg class="noun-grand-parents" width="85" height="85" enable-background="new 0 0 100 100" fill="#000000" version="1.1" viewBox="0 0 85 85" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-7.589 -12.688)">
    <path d="m33.788 56.135v0.262c0 0.662 0.536 1.198 1.198 1.198s1.198-0.536 1.198-1.198v-0.262c0-1.506 1.226-2.732 2.732-2.732 1.507 0 2.732 1.226 2.732 2.732v29.305c0 0.662 0.536 1.198 1.198 1.198s1.198-0.536 1.198-1.198v-29.305c0-2.827-2.3-5.127-5.128-5.127-2.828-1e-3 -5.128 2.3-5.128 5.127z"/>
            <path d="m12.904 83.638c0 1.654 1.34 2.994 2.994 2.994s2.994-1.34 2.994-2.994v-10.979h2.395v10.979c0 1.654 1.34 2.994 2.994 2.994s2.994-1.34 2.994-2.994v-10.979h5.315v-22.831c0-6.904-5.597-12.501-12.501-12.501s-12.5 5.597-12.5 12.501v22.831h5.315z"/>
            <path d="m79.91 37.318c-6.904 0-12.501 5.597-12.501 12.501 0 4.227 2.104 7.956 5.315 10.219v23.591c0 1.654 1.34 2.994 2.994 2.994s2.994-1.34 2.994-2.994v-21.37c0.394 0.038 0.793 0.06 1.198 0.06s0.803-0.023 1.198-0.06v21.369c0 1.654 1.34 2.994 2.994 2.994s2.994-1.34 2.994-2.994v-23.59c3.211-2.263 5.315-5.992 5.315-10.219 0-6.904-5.597-12.501-12.501-12.501z"/>
            <path d="m57.154 86.629c0.662 0 1.198-0.536 1.198-1.198v-29.306c0-1.507 1.226-2.732 2.732-2.732s2.732 1.226 2.732 2.732v0.262c0 0.662 0.536 1.198 1.198 1.198s1.198-0.536 1.198-1.198v-0.262c0-2.828-2.3-5.128-5.127-5.128-2.828 0-5.128 2.3-5.128 5.128v29.306c0 0.662 0.536 1.198 1.197 1.198z"/>
            <path d="m58.364 39.386c0.038 0.185 0.078 0.448 0.206 0.519 0.206 0.115 0.469 0.105 0.654-0.158 0.11-0.156 0.271-0.283 0.43-0.397 0.086 0.025 0.179 0.038 0.286 0.024 0.6-0.079 1.004-0.442 1.322-0.916 0.122-0.183 0.214-0.386 0.312-0.586 0.098 0.199 0.19 0.403 0.312 0.586 0.317 0.474 0.721 0.837 1.322 0.916 0.107 0.014 0.201 1e-3 0.286-0.024 0.159 0.114 0.32 0.241 0.43 0.397 0.184 0.263 0.448 0.273 0.654 0.158 0.128-0.072 0.168-0.334 0.206-0.519 0.03-0.144-5e-3 -0.301-0.011-0.452h7e-3c-0.019-0.482 0.015-0.974-0.068-1.444-0.19-1.074-1.288-1.886-2.264-1.742-0.156 0.023-0.642 0.211-0.873 0.311-0.231-0.1-0.717-0.287-0.873-0.311-0.975-0.144-2.074 0.668-2.264 1.742-0.083 0.471-0.049 0.962-0.068 1.444h7e-3c-8e-3 0.151-0.043 0.308-0.013 0.452z"/>
            <path d="m59.269 30.807 14.892 3.127c0.254 0.053 0.956-0.339 1.09-0.979 0.135-0.642-0.431-1.301-0.687-1.355l-1.864-0.391c0.092-0.21 0.166-0.433 0.215-0.669l0.228-1.088c0.336-1.601-0.508-3.135-1.886-3.425l-6.151-1.292c-1.378-0.289-2.768 0.776-3.104 2.377l-0.228 1.088c-0.049 0.236-0.072 0.47-0.072 0.699l-1.863-0.391c-0.257-0.054-1.039 0.322-1.174 0.964-0.135 0.64 0.349 1.282 0.604 1.335z"/>
            <path d="m65.359 39.504c-0.064 0.318-0.143 0.717-0.495 0.914-0.169 0.095-0.357 0.145-0.542 0.145-0.347 0-0.667-0.174-0.88-0.479-0.027-0.037-0.066-0.077-0.111-0.117-0.012 1e-3 -0.024 1e-3 -0.036 1e-3l-0.162-0.01c-0.616-0.082-1.128-0.387-1.559-0.93-0.153 0.193-0.32 0.345-0.494 0.478 1.267 1.5 3.158 2.454 5.275 2.454 3.813 0 6.905-3.091 6.905-6.905 0-0.242-0.013-0.481-0.037-0.717l-12.863-2.701c-0.576 1.008-0.91 2.173-0.91 3.418 0 0.109 0.011 0.216 0.016 0.325 0.416-0.19 0.87-0.28 1.32-0.213 0.192 0.028 0.551 0.162 0.788 0.257 0.237-0.094 0.596-0.228 0.788-0.257 1.316-0.197 2.69 0.877 2.927 2.221 0.067 0.373 0.068 0.739 0.07 1.092 1e-3 0.144 1e-3 0.288 7e-3 0.432l5e-3 0.135c8e-3 0.094 0.013 0.177 0.01 0.258l3e-3 0.084-9e-3 2e-3c-2e-3 0.036-8e-3 0.074-0.016 0.113z"/>
            <circle cx="33.788" cy="34.932" r="7.036"/>
            <path d="m26.486 32.706c0.791-2.588 2.923-4.59 5.582-5.206-0.588-1.573-2.091-2.698-3.869-2.698-2.288 0-4.142 1.854-4.142 4.142 0 1.675 0.999 3.109 2.429 3.762z"/>
  </g>
</svg>
</template>

<script>
    export default {
        name: 'GrandParents',
    };
</script>

<style scoped lang="scss">
    .noun-grand-parents {
        fill: currentColor;
        width: auto;
    }
</style>
