<template>
    <div>
        <v-alert v-if="isTranslation" dense type="warning">
            {{ $t('ProfileImagesForm.translationInfo') }}
        </v-alert>

        <v-card :loading="loading">
            <v-card-text>
                <v-row class="pa-3">
                    <v-col cols="12" md="6">
                        <h2 class="title mb-2">
                            {{ $t('ImagesForm.titleLogo') }}
                        </h2>

                        <v-alert color="info" dense text>
                            {{ $t('ProfileImagesForm.logoImageInfoText') }}
                        </v-alert>

                        <v-alert class="mt-3" dense text type="warning">
                            {{ $t('ProfileImagesForm.useInListsInfoText') }}
                        </v-alert>
                    </v-col>
                    
                    <v-col cols="12" md="6">
                        <v-card
                            v-if="logoImage.url"
                            :color="logoImage.use_in_directory ? 'orange' : undefined"
                            flat
                            outlined
                        >
                            <v-card-text class="white">
                                <v-img :src="logoImage.url" contain height="150" data-cy="profile-image-logo"></v-img>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    data-cy="profile-image-logo-delete"
                                    depressed
                                    small
                                    @click="fileRemovedEvent(logoImage)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <span
                                    v-if="logoImage.use_in_directory"
                                    class="ml-4"
                                    v-html="$t('ProfileImagesForm.useInLists')"
                                ></span>
                                <v-tooltip v-else bottom>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn
                                            :color="logoImage.use_in_directory ? 'white' : 'primary'"
                                            depressed
                                            small
                                            v-bind="attrs"
                                            @click="setListImage(logoImage)"
                                            v-on="on"
                                        >
                                            {{ $t('ProfileImagesForm.useInListsBtn') }}
                                        </v-btn>
                                    </template>
                                    {{ $t('ProfileImagesForm.useInListsBtnToolTip') }}
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>

                        <vue-dropzone
                            v-if="dropzoneLogoOptions && !logoImage.url"
                            id="application-form-vue-dropzone-logo"
                            ref="dropzoneLogo"
                            :destroyDropzone="false"
                            :options="dropzoneLogoOptions"
                            data-cy="application-form-vue-dropzone-logo"
                            use-custom-slot
                            @vdropzone-file-added="e => fileAddedEvent(e, 'logo')"
                            @vdropzone-removed-file="fileRemovedEvent"
                        >
                            <div class="dropzone-custom-content">
                                <h3 class="subtitle-1">{{ $t('Dropzone.title') }}</h3>
                                <div class="caption">{{ $t('Dropzone.caption') }}</div>
                            </div>
                        </vue-dropzone>

                        <v-alert :value="errors.logo !== null" class="mt-3" dense light text type="error">
                            {{ errors.logo }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mt-4" :loading="loading">
            <v-card-text>
                <v-row class="pa-3">
                    <v-col cols="12" md="6">
                        <h2 class="title mb-2">
                            {{ $t('ImagesForm.titlePerson') }}
                        </h2>

                        <v-alert color="info" dense text>
                            {{ $t('ProfileImagesForm.personImageInfoText') }}
                        </v-alert>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card
                            v-if="personImage.url"
                            :color="personImage.use_in_directory ? 'warning' : undefined"
                            flat
                            outlined
                        >
                            <v-card-text class="white">
                                <v-img :src="personImage.url" contain height="150" data-cy="profile-image-person"></v-img>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    color="white"
                                    data-cy="profile-image-person-delete"
                                    depressed
                                    small
                                    @click="fileRemovedEvent(personImage)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <span
                                    v-if="personImage.use_in_directory"
                                    class="ml-4"
                                    v-html="$t('ProfileImagesForm.useInLists')"
                                ></span>
                                <v-tooltip v-else bottom>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn
                                            :color="personImage.use_in_directory ? 'white' : 'primary'"
                                            depressed
                                            small
                                            v-bind="attrs"
                                            @click="setListImage(personImage)"
                                            v-on="on"
                                        >
                                            {{ $t('ProfileImagesForm.useInListsBtn') }}
                                        </v-btn>
                                    </template>
                                    {{ $t('ProfileImagesForm.useInListsBtnToolTip') }}
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>

                        <vue-dropzone
                            v-if="dropzonePersonOptions && !personImage.url"
                            id="application-form-vue-dropzone-person"
                            ref="dropzonePerson"
                            :destroyDropzone="false"
                            :options="dropzonePersonOptions"
                            data-cy="application-form-vue-dropzone-person"
                            use-custom-slot
                            @vdropzone-file-added="e => fileAddedEvent(e, 'person')"
                            @vdropzone-removed-file="fileRemovedEvent"
                        >
                            <div class="dropzone-custom-content">
                                <h3 class="subtitle-1">{{ $t('Dropzone.title') }}</h3>
                                <div class="caption">{{ $t('Dropzone.caption') }}</div>
                            </div>
                        </vue-dropzone>

                        <v-alert :value="errors.person !== null" class="mt-3" dense light text type="error">
                            {{ errors.person }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mt-4" :loading="loading">
            <v-card-text>
                <v-row class="pa-3">
                    <v-col cols="12" md="6">
                        <h2 class="title mb-2">
                            {{ $t('ImagesForm.titleImages') }}
                        </h2>

                        <v-alert color="info" dense text>
                            {{ $t('ProfileImagesForm.profileImageInfoText') }}
                        </v-alert>
                    </v-col>

                    <v-col cols="12" md="6">

                        <vue-dropzone
                            v-if="dropzoneOptions"
                            id="application-form-vue-dropzone"  
                            data-cy="application-form-vue-dropzone"
                            ref="dropzone"
                            :destroyDropzone="false"
                            :options="dropzoneOptions"
                            use-custom-slot
                            @vdropzone-file-added="e => fileAddedEvent(e, 'profile')"
                            @vdropzone-mounted="loadImages"
                            @vdropzone-removed-file="fileRemovedEvent"
                        >
                            <div class="dropzone-custom-content">
                                <h3 class="subtitle-1">{{ $t('Dropzone.title') }}</h3>
                                <div class="caption">{{ $t('Dropzone.caption') }}</div>
                            </div>
                        </vue-dropzone>

                        <v-alert :value="errors.profile !== null" class="mt-3" dense light text type="error">
                            {{ errors.profile }}
                        </v-alert>
                    </v-col>
                </v-row>

                <v-row v-if="profileImages.length > 0" class="border-t mt-6 -mx-4 -mb-4 pa-3 bg-gray-100">
                    <template v-for="image in profileImages">
                        <v-col :key="`image-${image.id}`" cols="12" lg="3">
                            <v-card v-if="image.url" flat outlined>
                                <v-img :src="image.url" cover height="150" data-cy="profile-image-profile"></v-img>
                                <v-card-actions>
                                    <v-btn icon x-small @click="fileRemovedEvent(image)">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-card-text>

            <v-card-actions v-if="showActions">
                <v-btn text @click="$router.back()">{{ $t('OneWord.back') }}</v-btn>
                <v-btn :loading="loading" color="accent" @click="$emit('save')">
                    <v-icon left>mdi-content-save</v-icon>
                    {{ $t('OneWord.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <!-- only show background settings in partner area, not in creation wizard -->
        <v-card v-if="isNgo && !showActions" class="mt-4" :loading="saving">
            <v-card-text>
                <v-row class="pa-3">
                    <v-col cols="12" md="6">
                        <h2 class="title mb-2">
                            {{ $t('ProfileImagesForm.background.headline') }}
                        </h2>

                        <p>
                            {{ $t('ProfileImagesForm.background.infoText') }}
                        </p>

                        <v-alert v-if="background.type === 'image'" color="info" dense text>
                            {{ $t('ProfileImagesForm.background.image.infoText') }}
                        </v-alert>
                    </v-col>
                    
                    <v-col cols="12" md="6">
                        <v-select
                            :items="[
                                {text: $t('ProfileImagesForm.background.options.da'), value: 'da'},
                                {text: $t('ProfileImagesForm.background.options.gradient'), value: 'gradient'},
                                {text: $t('ProfileImagesForm.background.options.image'), value: 'image'},
                            ]"
                            :label="$t('ProfileImagesForm.background.label.background')"
                            v-model="background.type"
                            hide-details
                            class="mb-5"
                        ></v-select>

                        <v-row v-if="background.type === 'gradient'" class="mb-2">
                            <v-col>
                                <v-text-field v-model="background.gradient.start" hide-details class="ma-0 pa-0" solo>
                                    <template v-slot:append>
                                        <v-menu v-model="showColorPicker.start" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                            <template v-slot:activator="{ on }">
                                                <div :style="colorPickerStart" v-on="on" />
                                            </template>
                                            <v-card>
                                                <v-card-text class="pa-0">
                                                    <v-color-picker v-model="background.gradient.start" :modes="['rgb']" flat />
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="background.gradient.end" hide-details class="ma-0 pa-0" solo>
                                    <template v-slot:append>
                                        <v-menu v-model="showColorPicker.end" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                            <template v-slot:activator="{ on }">
                                                <div :style="colorPickerEnd" v-on="on" />
                                            </template>
                                            <v-card>
                                                <v-card-text class="pa-0">
                                                    <v-color-picker v-model="background.gradient.end" :modes="['rgb']" flat />
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <template v-if="background.type === 'image'">
                            <v-card
                                v-if="backgroundImage.url"
                                class="mb-5"
                                flat
                                outlined
                            >
                                <v-card-text class="white">
                                    <v-img :src="backgroundImage.url" cover height="150"></v-img>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        data-cy="profile-image-background-delete"
                                        depressed
                                        small
                                        @click="fileRemovedEvent(backgroundImage)"
                                    >
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>

                            <vue-dropzone
                                v-if="dropzoneBackgroundOptions && !backgroundImage.url"
                                id="application-form-vue-dropzone-background"
                                ref="dropzoneBackground"
                                :destroyDropzone="false"
                                :options="dropzoneBackgroundOptions"
                                data-cy="application-form-vue-dropzone-background"
                                use-custom-slot
                                @vdropzone-file-added="e => fileAddedEvent(e, 'background')"
                                @vdropzone-removed-file="fileRemovedEvent"
                                class="mb-5"
                            >
                                <div class="dropzone-custom-content">
                                    <h3 class="subtitle-1">{{ $t('Dropzone.title') }}</h3>
                                    <div class="caption">{{ $t('Dropzone.caption') }}</div>
                                </div>
                            </vue-dropzone>

                            <v-alert :value="errors.background !== null" class="mt-3" dense light text type="error">
                                {{ errors.background }}
                            </v-alert>
                        </template>

                        <v-btn 
                            type="submit" 
                            color="accent" 
                            :loading="saving" 
                            @click="setBackground()"
                        >
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('OneWord.save') }}
                        </v-btn>

                        <v-btn 
                            v-if="showPaletteReset"
                            type="submit" 
                            class="ml-3"
                            @click="resetPalette()"
                        >
                            <v-icon left>mdi-reload</v-icon>
                            {{ $t('ProfileImagesForm.background.gradient.reset') }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="border-t mt-6 -mx-4 -mb-4 pa-3 bg-gray-100 block">
                    <div class="max-w-xl mx-auto">
                        <small class="text-uppercase">{{ $t('ProfileImagesForm.background.preview.label') }}:</small>
                        <v-card 
                            outlined
                            class="affiliate-preview text-xxs flex-row border cursor-pointer"
                            align="center"
                            @click="openPreview()"
                        >
                            <div class="pa-2 text-left">
                                <div class="max-w-1/2 mx-auto">
                                    <img data-v-04b37fa0="" :src="require('@/assets/img/deinadieu_logo.svg')" class="inline-block h-4" style="fill: white;">
                                    <div class="bg-da-primary text-xxxs text-white inline-block rounded pa-1 px-2 float-right">{{ $t('ProfileImagesForm.background.preview.login') }}</div>
                                </div>
                            </div>
                            <div class="flex-row pt-3" :style="backgroundPreview">
                                <div class="bg-white rounded-lg mt-2 w-1/2">
                                    <div class="inline-block text-uppercase bg-gray-100 rounded ma-2 px-2 py-1.5">{{ $t('ProfileImagesForm.background.preview.logo') }}</div>
                                    <div class="inline-block text-uppercase bg-gray-100 rounded ma-2 px-2 py-1.5">{{ $t('ProfileImagesForm.background.preview.contact') }}</div>
                                </div>
                                <div class="inline-block bg-white rounded-lg w-1/2 mt-2 mb-5 pa-8">
                                    <h3 class="text-lg mb-4">{{ $t('ProfileImagesForm.background.preview.headline') }}</h3>
                                    <div class="bg-da-primary text-xxxs text-white inline-block rounded pa-1 px-2">{{ $t('ProfileImagesForm.background.preview.button') }}</div>
                                </div>
                            </div>
                            <div class="pa-2 text-xxxs text-gray-600">{{ $t('ProfileImagesForm.background.preview.footer') }}</div>
                        </v-card>
                    </div>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="showPreviewDialog" max-width="500px">
            <v-card>
                <v-card-title>{{ $t('ProfileImagesForm.background.preview.label') }}</v-card-title>
                <v-card-text>{{ $t('ProfileImagesForm.background.preview.info') }}</v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="showPreviewDialog = false">{{ $t('OneWord.close') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import VueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {ImagesToBase64} from '@/utils/images';
import {EventBus} from '@/utils/event-bus';
import {usei18n} from "@/plugins/i18n";

export default {
    name: 'ProfileImagesForm',
    components: {VueDropzone},
    props: {
        accountId: Number,
        profile: Object,
        profileId: Number,
        lang: String,
        showActions: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    watch: {
        images(value) {
            if (!_.isEmpty(value)) {
                this.loadImages();
            }
        },
    },
    data() {
        return {
            loading: false,
            saving: false,
            dropzoneBackgroundOptions: null,
            dropzoneLogoOptions: null,
            dropzoneOptions: null,
            dropzonePerson: null,
            dropzonePersonOptions: null,
            errors: {
                logo: null,
                person: null,
                profile: null,
                background: null,
            },
            personImage: {},
            logoImage: {},
            backgroundImage: {},
            profileImages: [],
            background: {
                type: 'da',
                gradient: {
                    start: null,
                    end: null,
                },
            },
            images: [],
            showColorPicker: {
                start: false,
                end: false,
            },
            showPreviewDialog: false,
        };
    },
    computed: {
        isTranslation() {
            return this.lang !== 'de_CH';
        },
        isNgo() {
            return _.map(_.get(this.profile, 'partner_types', []), 'id').includes(12);
        },
        showPaletteReset() {
            return this.background.type === 'gradient' && _.get(this.profile, 'logo_palette') && (
                _.get(this.background, 'gradient.start') !== _.get(this.profile, 'logo_palette.gradient.0') || 
                _.get(this.background, 'gradient.end') !== _.get(this.profile, 'logo_palette.gradient.1')
            );
        },
        colorPickerStart() {
            return {
                backgroundColor: this.background.gradient.start,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: this.showColorPicker.start ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        },
        colorPickerEnd() {
            return {
                backgroundColor: this.background.gradient.end,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: this.showColorPicker.end ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        },
        backgroundPreview() {
            // background gradient
            if (this.background.type === 'gradient') {
                return {
                    background: `linear-gradient(to bottom, ${this.background.gradient.start}, ${this.background.gradient.end})`,
                };
            }

            // custom background image
            if (this.background.type === 'image' && this.backgroundImage.url) {
                return {
                    backgroundImage: 'url(' + this.backgroundImage.url + ')',
                    backgroundSize: 'cover',
                    backgroundColor: '#eee',
                };
            }

            // default: DA background image
            return {
                backgroundImage: 'url(' + require('@/assets/img/app/bg/da-colors.png') + ')',
                backgroundSize: 'cover',
            };
        },
    },
    methods: {
        setListImage(image) {
            this.loading = true;
            let url = `/webapi/accounts/${this.accountId}/profiles/${this.profileId}/images/${image.id}`;
            axios.put(url, {use_in_directory: true}).then(() => {
                EventBus.$emit('success');
                this.load();
            }).finally(() => {
                this.loading = false;
            });
        },

        setBackground() {
            this.saving = true;
            let url = `/webapi/accounts/${this.accountId}/profiles/${this.profileId}/background`;
            axios.put(url, this.background, {
                headers: {
                    'DA-Accept-Language': this.lang,
                },
            }).then(() => {
                EventBus.$emit('success');
            }).catch(errors => {
                if (errors.response.data.errors.background) {
                    this.errors.background = this.$t('ProfileImagesForm.errors.backgroundImageRequired');
                }
            }).finally(() => {
                this.$emit('reload-profile');
                this.saving = false;
            });
        },

        openPreview() {
            if (this.profile.background.type !== this.background.type) {
                this.showPreviewDialog = true;
                return;
            }

            window.open(this.profile.backlinks.testament[usei18n().locale] + '?preview=true', '_blank');
        },

        resetPalette() {
            this.background.gradient.start = _.get(this.profile, 'logo_palette.gradient.0', '#2f1f3c');
            this.background.gradient.end = _.get(this.profile, 'logo_palette.gradient.1', '#867197');
        },

        fileAddedEvent(file, type) {
            if (_.has(file, 'manuallyAdded')) {
                return;
            }

            this.loading = true;

            ImagesToBase64.fromDropzone([file]).then(res => {
                _.forEach(res, image => {
                    image['type'] = type;
                    axios.post(`/webapi/accounts/${this.accountId}/profiles/${this.profileId}/images`, image, {
                        headers: {
                            'DA-Accept-Language': this.lang,
                        },
                    }).then(response => {
                        this.errors.person = null;
                        this.errors.logo = null;
                        this.errors.profile = null;
                        this.errors.background = null;

                        if (type === 'logo') {
                            this.$emit('reload-profile');

                            if (_.get(this.profile, 'background.type') !== 'gradient') {
                                this.background.gradient.start = _.get(response.data, 'data.palette.gradient.0');
                                this.background.gradient.end = _.get(response.data, 'data.palette.gradient.1');
                            }
                        }

                        this.setImage(response.data.data, file);
                    }).catch(error => {
                        if (type === 'logo') {
                            this.errors.logo = _.get(error, 'response.data.errors.data[0]', _.get(error, 'statusText'));
                            this.$refs.dropzoneLogo.removeFile(file);
                        }
                        if (type === 'background') {
                            this.errors.background = _.get(error, 'response.data.errors.data[0]', _.get(error, 'statusText'));
                            this.$refs.dropzoneBackground.removeFile(file);
                        }
                        if (type === 'person') {
                            if (_.has(error, 'response.data.errors.data[0]')) {
                                this.errors.person = _.get(error, 'response.data.errors.data[0]');
                            } else {
                                this.errors.person = _.get(error, 'statusText');
                            }
                            this.$refs.dropzonePerson.removeFile(file);
                        }
                        if (type === 'profile') {
                            if (_.has(error.data, 'exception')) {
                                this.errors.profile = this.$t('ProfileImagesForm.fileExistsException');
                            }
                            else {
                                this.errors.profile = _.get(
                                    error, 'response.data.errors.data[0]',
                                    _.get(error, 'statusText'),
                                );
                            }
                            this.$refs.dropzone.removeFile(file);
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                });
            });
        },

        fileRemovedEvent(file) {
            if (!_.has(file, 'id')) {
                return;
            }

            this.$root.$confirm(this.$t('OneWord.delete'), this.$t('ConfirmDelete', {parameter: file.name})).
                then((confirm) => {
                    if (confirm) {
                        axios.delete(`/webapi/accounts/${this.accountId}/profiles/${this.profileId}/images/${file.id}`).
                            finally(() => {
                                this.loading = false;

                                if (file.type === 'logo') {
                                    this.logoImage = {};
                                }
                                if (file.type === 'person') {
                                    this.personImage = {};
                                }
                                if (file.type === 'background') {
                                    this.backgroundImage = {};
                                }
                                if (file.type === 'profile') {
                                    this.profileImages.splice(_.findIndex(this.profileImages, {id: file.id}), 1);
                                }

                                this.load();
                            });
                    }
                });
        },

        setImage(image, source) {
            image['name'] = image.url.substring(image.url.lastIndexOf('/') + 1);

            if (image.type === 'logo') {
                this.logoImage = image;
            }
            if (image.type === 'person') {
                this.personImage = image;
            }
            if (image.type === 'background') {
                this.backgroundImage = image;
            }
            if (image.type === 'profile') {
                this.profileImages.push(image);
                this.$refs.dropzone.removeFile(source);
            }
        },
        loadImages() {
            if (_.has(this.$refs, 'dropzone')) {
                this.profileImages = [];
                _.forEach(this.images, item => {
                    let imageMock = {
                        url: item.url,
                        name: item.url.substring(item.url.lastIndexOf('/') + 1),
                        size: item.size,
                        id: item.id,
                        type: item.type,
                        use_in_directory: item.use_in_directory,
                    };

                    if (item.type === 'logo') {
                        this.logoImage = imageMock;
                    }
                    else if (item.type === 'person') {
                        this.personImage = imageMock;
                    }
                    else if (item.type === 'background') {
                        this.backgroundImage = imageMock;
                    }
                    else {
                        this.profileImages.push(imageMock);
                    }
                });
            }
        },

        load() {
            this.loading = true;
            let url = `/webapi/accounts/${this.accountId}/profiles/${this.profileId}/images`;
            axios.get(url, {
                headers: {
                    'DA-Accept-Language': this.lang,
                },
            }).then(response => {
                this.images = response.data.data;
            }).finally(() => {
                this.loading = false;
            });
        },
    },

    mounted() {
        this.dropzoneLogoOptions = {
            url: window.location.href,
            autoProcessQueue: false,
            addRemoveLinks: true,
            maxFiles: 1,
            maxFilesize: 3,
        };
        this.dropzonePersonOptions = {
            url: window.location.href,
            autoProcessQueue: false,
            addRemoveLinks: true,
            maxFiles: 1,
        };
        this.dropzoneBackgroundOptions = {
            url: window.location.href,
            autoProcessQueue: false,
            addRemoveLinks: true,
            maxFiles: 1,
        };
        this.dropzoneOptions = {
            url: window.location.href,
            autoProcessQueue: false,
            addRemoveLinks: true,
        };

        // set default values for affiliate background
        this.background.type = _.get(this.profile, 'background.type') || 'da';
        this.backgroundImage = _.get(this.profile, 'background_image') || {};

        if (_.get(this.profile, 'background.type') === 'gradient') {
            this.background.gradient.start = _.get(this.profile, 'background.gradient.start');
            this.background.gradient.end = _.get(this.profile, 'background.gradient.end');
        } else {
            this.resetPalette();
        }

        this.load();
    },
};
</script>

<style lang="scss" scoped>
.vue-dropzone {
    ::v-deep .dz-progress {
        display: none;
    }
}

.vue-dropzone {
    ::v-deep {
        .dz-preview .dz-error-message {
            top: 210px !important;
        }
    }
}
</style>
