<template>
    <v-sheet v-if="content" data-cy="whitelabel-content" color="white" class="whitelabel-content black--text py-10">
        <v-row>
            <v-col cols="12" md="6" lg="4" xl="3" class="mx-auto px-10">
                <div v-html="content"></div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import {mapGetters} from 'vuex';
import {getCurrentLocale} from '@/plugins/i18n';

export default {
    name: 'WhitelabelContent',
    computed: {
        ...mapGetters('env', [
            'getContent',
        ]),
        locale() {
            // make sure the locale matches the backend format (xy_XY)
            return getCurrentLocale().replace('-', '_');
        },
        content() {
            return this.getContent[this.locale];
        },
    },
};
</script>

<style scoped lang="scss">
.whitelabel-content ::v-deep {
    @import '@/assets/_wysiwyg.scss';
}
</style>
