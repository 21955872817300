import Vue from "vue";

Vue.filter('formatDate', value => {
    if (value) {
        let dateVal = new Date(value);
        if (isNaN(dateVal.getTime())) {
            return '';
        }
        return dateVal.toLocaleDateString('de-CH', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    }

    return '';
});

Vue.filter('formatDateTime', value => {
    if (value) {
        let dateVal = new Date(value);
        if (isNaN(dateVal.getTime())) {
            return '';
        }
        return dateVal.toLocaleDateString('de-CH', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    return '';
});

Vue.filter('nl2br', str => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }

    let breakTag = '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
});