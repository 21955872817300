<template>
    <div v-if="webinar" class="py-0 px-2 lg:py-6 sm:px-0">
        <affiliate-header v-if="!isAuthenticated" class="text-center" />

        <v-card class="p-10 rounded-md lg:rounded-xl" :flat="!isAuthenticated">
            <v-row>
                <v-col>
                    <small class="font-weight-medium block mb-1 text-gray-500">{{ $t('OneWord.webinar') }}</small>

                    <h1 class="text-2xl font-medium my-2">
                        {{ webinar.title }}
                    </h1>

                    <v-row class="border-y my-6">
                        <v-col cols="12" lg="7">
                            <small class="font-weight-medium block mb-1 text-gray-500">{{ $t('OneWord.date') }}</small>
                            <pre class="text-h6">{{ localizeDate(webinar.date, 'dddd') }}, {{ localizeDate(webinar.date) }}</pre>
                        </v-col>
                        <v-col cols="12" lg="5">
                            <small class="font-weight-medium block mb-1 text-gray-500">{{ $t('Webinars.details.times') }} ({{ shortTimezone() }})</small>
                            <pre class="text-h6">{{ localizeTime(webinar.start_time) }} - {{ localizeTime(webinar.end_time) }}</pre>
                        </v-col>
                    </v-row>

                    <div class="mt-8 mb-10" v-html="$options.filters.nl2br(webinar.description)" />

                    <small 
                        v-if="webinar.status !== 'done'"
                        class="block mt-6"
                        v-html="$t('Webinars.details.supportText', {email: webinar.presenter || getMailFromAddress})"
                    ></small>

                    <v-btn class="-ml-4 -mb-2 mt-8" text @click="goBack()">← {{ $t('OneWord.back') }}</v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="p-10 mt-6 rounded-md lg:rounded-xl" :flat="!isAuthenticated">
            <v-row>
                <v-col>
                    <h1 class="title mb-2">
                        {{ $t('Webinars.details.registration.headline') }}
                    </h1>

                    <v-alert data-cy="registration-error" v-if="registrationError" type="error" class="mt-8">
                        {{ $t('Webinars.details.registration.errorText') }}
                    </v-alert>

                    <div data-cy="registration-success" v-else-if="registered">
                        <v-alert type="success">
                            {{ $t('Webinars.details.registration.successText') }}
                        </v-alert>

                        <p>{{ $t('Webinars.details.registration.successInfo') }}</p>
                    </div>

                    <div data-cy="registration-completed" v-if="webinar.status === 'done'">
                        <v-alert type="info">
                            {{ $t('Webinars.details.completed') }}
                        </v-alert>
                    </div>
                    
                    <validation-observer v-else ref="webinarRegistration" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(register)" v-if="webinar.status !== 'done'">
                            <v-row class="mt-5">
                                <v-col>
                                    <validation-provider :name="$t('OneWord.firstName')" v-slot="{errors}" rules="required">
                                        <v-text-field
                                            v-model="form.first_name"
                                            :label="$t('OneWord.firstName')"
                                            :error-messages="errors"
                                            maxlength="255"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <validation-provider :name="$t('OneWord.lastName')" v-slot="{errors}" rules="required">
                                        <v-text-field
                                            v-model="form.last_name"
                                            :label="$t('OneWord.lastName')"
                                            :error-messages="errors"
                                            maxlength="255"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <validation-provider :name="$t('OneWord.email')" v-slot="{errors}" rules="required|email">
                                        <v-text-field
                                            v-model="form.email"
                                            :label="$t('OneWord.email')"
                                            :error-messages="errors"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <v-row v-if="1 === 2">
                                <v-col>
                                    <v-textarea
                                        v-model="form.question"
                                        :label="$t('Webinar.details.registration.questionLabel')"
                                        rows="3"
                                        maxlength="255"
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row class="p-3 pt-0">
                                <v-switch
                                    v-if="!isAuthenticated"
                                    v-model="agreementAccepted"
                                    :false-value="false"
                                    :true-value="true"
                                    data-cy="switch-affiliate-agreement"
                                    dense
                                    class="pt-0 mt-0"
                                    hide-details
                                >
                                    <template v-slot:label>
                                        <small data-cy="accept-label" v-html="$t('Affiliate.simple.acceptLabel')"></small>
                                    </template>
                                </v-switch>
                            </v-row>

                            <v-btn 
                                :loading="loading"
                                :disabled="!agreementAccepted && !isAuthenticated"
                                data-cy="btn-register"
                                color="primary"
                                type="submit"
                                class="mt-5"
                            >
                                <v-icon>mdi-save</v-icon>
                                {{ $t('Webinars.details.registration.buttonRegister') }}
                            </v-btn>

                            <small class="block mt-6">{{ $t('Webinars.details.registration.legalInfo') }}</small>
                        </v-form>
                    </validation-observer>
                </v-col>
            </v-row>

            <v-btn class="-ml-4 -mb-2 mt-8" text @click="goBack()">← {{ $t('OneWord.back') }}</v-btn>
        </v-card>
    </div>
</template>

<script>
import {EventBus} from '@/utils/event-bus';
import AffiliateHeader from '@/pages/affiliate/AffiliateHeader.vue';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {shortTimezone, localizeDate, localizeTime} from '@/composables/Helpers';
import {mapGetters} from 'vuex';

export default {
    name: 'WebinarDetails',

    components: {
        AffiliateHeader,
        ValidationProvider,
        ValidationObserver,
    },

    data() {
        return {
            webinar: {},
            form: {
                first_name: null,
                last_name: null,
                email: null,
                question: null,
            },
            loading: false,
            registered: false,
            registrationError: false,
            agreementAccepted: false,
        }
    },

    computed: {
        ...mapGetters('env', [
            'getLogo',
            'isAuthenticated',
            'getMailFromAddress'
        ]),
        ...mapGetters('user', {
            user: 'user',
        }),
    },

    mounted() {
        this.load();

        EventBus.$on('locale-changed', () => {
            this.load();
        });

        if (this.user && ! this.registered) {
            this.form.first_name = this.user.first_name;
            this.form.last_name = this.user.last_name;
            this.form.email = this.user.email;
        }
    },

    methods: {
        load() {
            this.loading = true;
            axios.get(`/webapi/profiles/${this.$route.params.profile_id}/webinars/${this.$route.params.webinar_id}`).then(response => {
                this.webinar = response.data.data;
            }).catch(() => {
                this.goBack();
            }).finally(() => {
                this.loading = false;
            });
        },

        register() {
            this.loading = true;
            axios.post(`/webapi/profiles/${this.$route.params.profile_id}/webinars/${this.$route.params.webinar_id}`, this.form)
                .then(() => {
                    this.registered = true;
                })
                .catch(() => {
                    this.registrationError = true;
                })
                .finally(() => this.loading = false)
        },

        goBack() {
            this.$router.push({path: `/profiles/${this.$route.params.profile_id}/webinars`});
        },

        localizeTime,
        localizeDate,
        shortTimezone,
    }
};
</script>
