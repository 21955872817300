<template>
    <v-text-field readonly :value="colorValue" @click="showPicker = true" hide-details class="ma-0 pa-0 w-32">
        <template v-slot:prepend>
            <v-menu v-model="showPicker" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <div :style="styles(colorKey)" v-on="on" />
                </template>
                <v-card>
                    <v-card-text class="pa-0">
                        <v-color-picker v-model="colorValue" :modes="['rgb']" flat />
                    </v-card-text>
                </v-card>
            </v-menu>
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: 'ColorPicker',
    props: {
        colorKey: {
            type: String,
        },
        value: {
            type: String,
        },
    },
    watch: {
        value: {
            handler(value) {
                this.colorValue = value;
            },
            immediate: true,
        },
        colorValue(value) {
            this.$emit('input', value);
        },
    },
    data() {
        return {
            showPicker: false,
            colorValue: '#FFFFFF', // internal
        };
    },
    methods: {
        styles() {
            return {
                backgroundColor: this.colorValue,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: this.showPicker ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out',
                borderColor: '#ddd',
                borderWidth: '1px',
                marginBottom: '5px',
            }
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
