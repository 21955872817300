<template>
    <validation-observer ref="profileForm" v-slot="{ passes }">
        <v-card>
            <v-card-text>
                <validation-provider
                    v-slot="{ errors }"
                    :name="$t('OneWord.first-name')"
                    rules="required|max:255"
                >
                    <v-text-field
                        v-model="user.first_name"
                        :error-messages="errors"
                        :label="$t('OneWord.first-name')"
                    ></v-text-field>
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    :name="$t('OneWord.last-name')"
                    rules="required|max:255"
                >
                    <v-text-field
                        v-model="user.last_name"
                        :error-messages="errors"
                        :label="$t('OneWord.last-name')"
                    ></v-text-field>
                </validation-provider>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" color="primary" depressed @click="passes(save)">
                    {{ $t('OneWord.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-toolbar class="mt-4" color="transparent" dense flat>
            <v-toolbar-title class="subtitle-2">
                {{ $t('User.privateTitle') }}
            </v-toolbar-title>
        </v-toolbar>

        <v-card>
            <v-card-text>
                <validation-provider
                    v-slot="{ errors }"
                    :name="$t('OneWord.address')"
                    mode="eager"
                    rules="max:255"
                    vid="address"
                >
                    <v-text-field
                        v-model="user.address"
                        :counter="255"
                        :error-messages="errors"
                        :label="$t('OneWord.address')"
                        autocomplete="street-address"
                    ></v-text-field>
                </validation-provider>
                <v-row>
                    <v-col cols="4">
                        <validation-provider
                            v-slot="{ errors }"
                            :name="$t('OneWord.zip')"
                            mode="eager"
                            rules="max:10"
                            vid="zip"
                        >
                            <v-text-field
                                v-model="user.zip"
                                :error-messages="errors"
                                :label="$t('OneWord.zip')"
                                autocomplete="postal-code"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="8">
                        <validation-provider
                            v-slot="{ errors }"
                            :name="$t('OneWord.place')"
                            mode="eager"
                            rules="max:255"
                            vid="place"
                        >
                            <v-text-field
                                v-model="user.place"
                                :counter="255"
                                :error-messages="errors"
                                :label="$t('OneWord.place')"
                                autocomplete="address-level2"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>
                <validation-provider
                    v-slot="{ errors }"
                    :name="$t('OneWord.phone')"
                    mode="eager"
                    rules="max:255|phone"
                >
                    <v-text-field
                        v-model="user.mobile"
                        :error-messages="errors"
                        :label="$t('OneWord.mobile')"
                        prepend-inner-icon="mdi-phone"
                        type="tel"
                        vid="mobile"
                    ></v-text-field>
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    :name="$t('OneWord.hometown')"
                    mode="eager"
                    rules="max:255"
                    vid="hometown"
                >
                    <v-text-field
                        v-model="user.hometown"
                        :error-messages="errors"
                        :label="$t('OneWord.hometown')"
                    ></v-text-field>
                </validation-provider>

                <v-row no-gutters>
                    <v-col align-self="center" class="v-label" cols="2">
                        {{ $t('OneWord.birthday') }}
                    </v-col>
                    <v-col cols="10">
                        <date-field
                            v-model="user.birthday"
                            hide-details
                        ></date-field>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" color="primary" depressed @click="passes(save)">
                    {{ $t('OneWord.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </validation-observer>
</template>

<script>
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import {mapActions, mapGetters} from 'vuex';
import zxcvbn from 'zxcvbn';
import {EventBus} from '@/utils/event-bus';
import DateField from '@/components/DateField';

export default {
    name: 'UserProfile',
    components: {DateField, ValidationObserver, ValidationProvider},
    props: {
        verified: Boolean,
    },
    data() {
        return {
            show: false,
            loading: false,
            confirm: '',
            passwordsDoNoMatch: false,
            birthdayMenu: false,
            user: {
                id: null,
                email: null,
                last_name: null,
                first_name: null,
                password: '',
                birthday: null,
                locale: null,
            },
        };
    },
    watch: {
        initUser: {
            deep: true,
            handler(value) {
                this.user = _.cloneDeep(value);
            },
        },
        birthdayMenu(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
        },
        verified(oldValue, newValue) {
            if (newValue === true && oldValue !== newValue) {
                this.displayVerified();
            }
        },
    },
    computed: {
        ...mapGetters('user', {
            initUser: 'user',
        }),
        birthdayFormatted() {
            return this.user.birthday ? moment(this.user.birthday).format('L') : '';
        },
        pwdStrengthProgress() {
            if (this.user.password) {
                let res = zxcvbn(this.user.password);
                return (res.score + 1) * 20;
            }

            return 0;
        },
        pwdStrengthColor() {
            if (this.pwdStrengthProgress > 80) {
                return 'success';
            }
            if (this.pwdStrengthProgress > 40 && this.pwdStrengthProgress <= 80) {
                return 'warning';
            }
            if (this.pwdStrengthProgress < 40) {
                return 'error';
            }

            return 'transparent';
        },
        languages() {
            return [
                {id: 'de-CH', label: this.$t('OneWord.lang.german')},
                {id: 'fr-CH', label: this.$t('OneWord.lang.french')},
                {id: 'it-CH', label: this.$t('OneWord.lang.italian')},
                {id: 'en-GB', label: this.$t('OneWord.lang.english')},
            ];
        },
    },
    methods: {
        ...mapActions('user', {
            updateUser: 'update',
        }),
        save() {
            if (!_.isEmpty(this.user.password) && (this.user.password !== this.confirm)) {
                this.passwordsDoNoMatch = true;
                return;
            }
            else {
                this.passwordsDoNoMatch = false;
            }

            this.loading = true;

            this.updateUser(this.user).then(() => {
                EventBus.$emit('success', this.$t('User.updateSuccessful'));
            }).catch(error => {
                this.$refs.profileForm.setErrors(_.get(error, 'response.data.errors', []));
                EventBus.$emit('error', _.get(error, 'response.data.message'));
            }).finally(() => {
                this.confirm = null;
                this.loading = false;
            });
        },
        cancel() {},
        saveBirthday(date) {
            this.$refs.menu.save(date);
        },
        displayVerified() {
            this.$nextTick(function() {
                EventBus.$emit('success', this.$t('User.emailVerifiedSuccess'));
            });
        },
    },
    mounted() {
        this.user = _.cloneDeep(this.initUser);

        extend('password', {
            params: ['target'],
            validate(value, {target}) {
                return value === target;
            },
            message: this.$t('User.passwordNotConfirmed'),
        });

        if (this.verified) {
            this.displayVerified();
        }
    },
};
</script>

<style lang="scss" scoped>
.Password {
    max-width: inherit !important;
}
</style>
