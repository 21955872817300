<template>
    <v-form @submit.prevent="save">
        <v-card :loading="loading">
            <v-card-title>
                {{ $t('PartnerTypeForm.title') }}
            </v-card-title>
            <v-card-text>
                <v-alert type="info" dense text>
                    {{ $t('AdminProfileCreate.partnerTypeInfoText') }}
                </v-alert>

                <v-card outlined class="mb-2">
                    <v-card-title class="subtitle-2">
                        {{ $tc('AdminProfileCreate.ngoPartnerTypeInfoText', ngoPartnerTypes.length) }}
                    </v-card-title>
                    <v-list class="p-0">
                        <v-list-item-group v-model="selectedPartnerTypes" multiple active-class="">
                            <template v-for="(pt, index) in ngoPartnerTypes">
                                <v-list-item :key="`pt-${pt.id}`" dense :value="pt.id">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title>{{ pt.name }}</v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ $t(`PartnerTypeForm.${pt.id}.info`) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                                <v-divider
                                    v-if="index < ngoPartnerTypes.length - 1"
                                    :key="index"
                                ></v-divider>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>

                <v-card outlined>
                    <v-card-title class="subtitle-2">
                        {{ $tc('AdminProfileCreate.spPartnerTypeInfoText', spPartnerTypes.length) }}
                    </v-card-title>
                    <v-list class="p-0">
                        <v-list-item-group v-model="selectedPartnerTypes" multiple active-class="">
                            <template v-for="(pt, index) in spPartnerTypes">
                                <v-list-item :key="`pt-${pt.id}`" dense :value="pt.id">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title>{{ pt.name }}</v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ $t(`PartnerTypeForm.${pt.id}.info`) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                                <v-divider
                                    v-if="index < spPartnerTypes.length - 1"
                                    :key="index"
                                ></v-divider>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>

                <v-alert type="error" dense text class="mt-2" v-if="partnerTypeRequiredError">
                    {{ $t('PartnerTypeForm.requiredError') }}
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="$router.back()" text>{{ $t('OneWord.back') }}</v-btn>
                <v-btn type="submit" depressed color="primary" :loading="saving">{{ $t('OneWord.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
    import {EventBus} from '../../utils/event-bus';

    export default {
        name: 'PartnerTypeForm',
        components: {},
        data() {
            return {
                loading: false,
                saving: false,

                profile: {},

                partnerTypes: [],
                selectedPartnerTypes: [],

                partnerTypeRequiredError: false,
            };
        },
        computed: {
            ngoPartnerTypes() {
                return _.filter(this.partnerTypes, {id: 12});
            },
            spPartnerTypes() {
                return _.filter(this.partnerTypes, item => {
                    return item.id !== 12;
                });
            },
        },
        methods: {
            save() {
                if (this.selectedPartnerTypes.length > 0) {
                    this.partnerTypeRequiredError = false;

                    let payload = {
                        partner_types: _.filter(this.partnerTypes, item => {
                            return _.includes(this.selectedPartnerTypes, item.id);
                        }),
                    };

                    axios.post(`/webapi/create-profile/partner-type`, payload).then(() => {
                        this.$router.push({name: 'create-profile-description'});
                    }).catch(error => {
                        EventBus.$emit('error', error.data.message);
                    }).finally(() => {
                        this.saving = false;
                    });
                } else {
                    this.partnerTypeRequiredError = true;
                }
            },
            select(value) {
                if (this.isSelected(value)) {
                    this.selected.splice(_(this.selected).findIndex({id: value.id}), 1);
                } else {
                    this.selected.push(value);
                }
            },
            isSelected(item) {
                return _(this.selected).findIndex({id: item.id}) !== -1;
            },
            load() {
                this.loading = true;
                axios.get('/api/partner-types').then(response => {
                    this.partnerTypes = response.data.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
        },
        mounted() {
            this.load();
        },
        beforeRouteEnter(to, from, next) {
            axios.get('/webapi/create-profile/profile').then(response => {
                next(vm => {
                    vm.profile = response.data.data;
                    vm.selectedPartnerTypes = _.map(_.get(response.data.data, 'partner_types', []), 'id');
                });
            });
        }
    };
</script>

<style scoped>

</style>
