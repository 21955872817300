<template>
    <v-btn-toggle v-model="selectedLocale" mandatory dense>
        <v-btn 
            v-for="language in availableLanguages"
            :key="language.locale"
            :value="language.locale"
            :data-cy="'language-selector-' + language.lang"
        >
            <template v-if="$vuetify.breakpoint.smAndDown">
                <span class="uppercase">{{ language.lang }}</span>
            </template>
            <template v-else>
                {{ language.label }}
            </template>
        </v-btn>
    </v-btn-toggle>
</template>

<script>
import {mapGetters} from 'vuex';

/**
 * Language/Locale Selector Component
 * 
 * Will return the selected language in the given format. Usage:
 * <language-selector v-model="lang" format="locale" separator="-" />
 * 
 * Only available languages of tenant will be used. Alternatively,
 * displayed languages can be determined by 'languages' property.
 */
export default {
    name: 'LanguageSelector',
    props: {
        format: {
            type: String,
            default: 'locale',
            validator(value) {
                // lang is the 2 character representation
                return ['locale', 'lang'].includes(value);
            }
        },
        separator: {
            type: String,
            default: '_',
            validator(value) {
                // use: underscore for backend, hyphen for frontend
                return ['-', '_'].includes(value);
            }
        },
        languages: {
            type: Object,
            required: false,
        },
        value: String,
    },
    mounted() {
        this.selectedLocale = this.value;
    },
    watch: {
        selectedLocale(value) {
            if (this.format === 'lang') {
                this.$emit('input', value.substring(0, 2));
            } else {
                this.$emit('input', value.replace('-', this.separator));
            }
        },
    },
    data() {
        return {
            selectedLocale: 'de-CH',
        };
    },
    computed: {
        ...mapGetters('env', [
            'getLanguages',
        ]),
        availableLanguages() {
            let languages = this.languages || this.getLanguages();
            return _.filter([
                {lang: 'de', locale: 'de-CH', label: this.$t('OneWord.lang.german')},
                {lang: 'en', locale: 'en-GB', label: this.$t('OneWord.lang.english')},
                {lang: 'fr', locale: 'fr-CH', label: this.$t('OneWord.lang.french')},
                {lang: 'it', locale: 'it-CH', label: this.$t('OneWord.lang.italian')},
            ], language => _.get(languages, language.lang, false));
        },
    },
};
</script>

<style scoped>

</style>