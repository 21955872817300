<template>
    <v-container
        class="text-center"
        fill-height
        style="height: calc(100vh - 64px);"
    >
        <v-row align="center">
            <v-col>
                <h1 class="display-2 primary--text">
                    {{ $t('Error404.whoops') }}
                </h1>

                <p>
                    {{ $t('Error404.text') }}
                </p>

                <v-btn
                    :to="{name: 'my-documents'}"
                    color="primary"
                    outlined
                >
                    {{ $t('Error404.btn.label') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'Error404',
    };
</script>

<style scoped>

</style>
