<template>
    <div>
        <h1 class="title mb-2">
            {{ $t('AppAdminNav.whitelabel') }}
        </h1>

        <v-toolbar dense elevation="1">
            <v-tabs color="accent" :value="$route.name">
                <v-tab :to="{name: 'admin-whitelabel-content'}">
                    {{ $t('AdminWhitelabel.tabs.content') }}
                </v-tab>
                <v-tab :to="{name: 'admin-whitelabel-colors'}">
                    {{ $t('AdminWhitelabel.tabs.colors') }}
                </v-tab>
            </v-tabs>
        </v-toolbar>

        <router-view></router-view>
    </div>
</template>

<script>
import store from '../../store';

export default {
    name: 'AdminWhitelabel',
    beforeRouteEnter(to, from, next) {
        store.dispatch('user/load').then(() => {
            next();
        });
    },
};
</script>

<style scoped>

</style>
