var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left"},[(_vm.hasError)?_c('v-alert',{staticClass:"mt-4",attrs:{"dense":"","text":"","type":"error","data-cy":"login--error-general"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.twoFactorError)?_c('v-alert',{staticClass:"mt-4",attrs:{"dense":"","text":"","type":"error","data-cy":"login--error-2fa"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.cancelTwoFactor}},[_vm._v(" "+_vm._s(_vm.$t('LoginForm.btn.TwoFactorRestart'))+" ")])],1)],1)],1):_vm._e(),(_vm.twoFactorAuth)?[_c('div',[_vm._v(" "+_vm._s(_vm.$t('LoginForm.twoFactorInfoText'))+" ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.code)}}},[_c('validation-provider',{attrs:{"mode":"eager","name":"Code","rules":"required","vid":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"error-messages":errors,"autocomplete":"one-time-code","inputmode":"numeric","outlined":"","pattern":"[0-9]","placeholder":"123456","autofocus":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"-my-4"},[_c('v-btn',{staticClass:"text-none my-3",attrs:{"small":"","text":""},on:{"click":_vm.resendCode}},[_vm._v(" "+_vm._s(_vm.$t('LoginForm.resendCode'))+" ")])],1)]},proxy:true}],null,true),model:{value:(_vm.twoFactorCode),callback:function ($$v) {_vm.twoFactorCode=$$v},expression:"twoFactorCode"}})]}}],null,true)}),_c('span',{staticClass:"mt-4 caption",domProps:{"innerHTML":_vm._s(_vm.$t('LoginForm.twoFactorLost'))}}),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{attrs:{"depressed":"","large":""},on:{"click":_vm.cancelTwoFactor}},[_vm._v(" "+_vm._s(_vm.$t('OneWord.cancel'))+" ")])],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","depressed":"","large":"","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-login")]),_vm._v(" "+_vm._s(_vm.$t('LoginDialog.login'))+" ")],1)],1)],1)],1)]}}],null,false,30945846)})]:[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.login)}}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.email'),"mode":"eager","rules":"required|email|max:255","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-2",attrs:{"data-vv-name":_vm.$t('OneWord.email'),"error-messages":errors,"label":_vm.$t('OneWord.email'),"data-cy":"login-form-email","prepend-inner-icon":"mdi-account","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.password'),"mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('OneWord.password'),"data-cy":"login-form-password","prepend-inner-icon":"mdi-form-textbox-password","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('v-btn',{staticClass:"mt-2",attrs:{"loading":_vm.loading,"block":"","color":"primary","depressed":"","large":"","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-login")]),_vm._v(" "+_vm._s(_vm.$t('LoginDialog.login'))+" ")],1),_c('router-link',{staticClass:"font-weight-bold mt-3 mb-2 d-block",attrs:{"to":{name: 'send-password'},"data-cy":"reset-password"}},[_vm._v(" "+_vm._s(_vm.$t('LoginDialog.resetPwd'))+" ")]),_c('router-link',{staticClass:"font-weight-bold mt-3 mb-2 d-block",attrs:{"to":{name:'register'},"data-cy":"register"}},[_vm._v(" "+_vm._s(_vm.$t('LoginDialog.register'))+" ")])],1)]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }