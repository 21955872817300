<template>
    <v-sheet data-cy="affiliate-info" color="white" class="affiliate-info black--text pa-3" v-if="profile">
        <v-subheader class="text-uppercase -ml-1">{{ $t('AffiliateInfo.title') }}</v-subheader>
        <template v-if="isWhiteLabel">
            <div class="text-center">
                <div
                    class="h-12 md:h-32 w-32 md:w-32 my-4 bg-cover bg-center bg-no-repeat rounded-full border border-gray-300 border-solid mx-auto"
                    :style="{ backgroundImage: `url('${personImage}')` }"
                ></div>
                <div class="font-weight-bold text-no-wrap text-truncate">
                    {{ profile.first_name }} {{ profile.last_name }}
                </div>
                <div class="text-xs text-no-wrap text-truncate">
                    <a :href="mail">{{ profile.email }}</a>
                </div>
                <div v-if="profile.phone_formatted" class="text-xs whitespace-no-wrap">
                    <a :href="`tel:${profile.phone}`">{{ profile.phone_formatted }}</a>
                </div>
                <div v-else class="text-xs whitespace-no-wrap">
                    <a :href="`tel:${profile.mobile_phone}`">{{ profile.mobile_phone_formatted }}</a>
                </div>
            </div>
        </template>
        <template v-else>
            <img :src="profileImage" :alt="profile.company_name" class="object-contain px-4 py-2 max-h-48">

            <hr class="my-5">

            <v-row align="center" justify="space-around" class="mx-3" no-gutters>
                <v-col sm="4">
                    <div
                        class="h-12 md:h-20 w-20 md:w-20 bg-cover bg-center bg-no-repeat rounded-full border border-gray-300 border-solid"
                        :style="{ backgroundImage: `url('${personImage}')` }"
                        v-if="personImage"
                    ></div>
                    <div v-else
                         class="h-20 w-20 bg-contain bg-center bg-no-repeat rounded-full border border-gray-300 border-solid">
                        <i class="mdi mdi-account w-20 ml-4 mt-4 text-gray-400" style="font-size: 3rem"></i>
                    </div>
                </v-col>
                <v-col cols="8" class="pl-2">
                    <div class="font-weight-bold text-no-wrap text-truncate">
                        {{ profile.first_name }} {{ profile.last_name }}
                    </div>
                    <div class="text-xs text-no-wrap text-truncate">
                        <a :href="mail">{{ profile.email }}</a>
                    </div>
                    <div v-if="profile.phone_formatted" class="text-xs whitespace-no-wrap">
                        <a :href="`tel:${profile.phone}`">{{ profile.phone_formatted }}</a>
                    </div>
                    <div v-else class="text-xs whitespace-no-wrap">
                        <a :href="`tel:${profile.mobile_phone}`">{{ profile.mobile_phone_formatted }}</a>
                    </div>
                </v-col>
            </v-row>
        </template>
    </v-sheet>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {EventBus} from "@/utils/event-bus";

export default {
    name: 'AffiliateInfo',
    methods: {
        ...mapActions('affiliateProfile', {
            loadSponsor: 'load',
        }),
    },
    computed: {
        ...mapGetters('affiliateProfile', {
            profile: 'profile',
        }),
        ...mapGetters('env', {
            isWhiteLabel: 'isWhiteLabel',
        }),
        profileImage() {
            return _.get(this.profile, 'logo.url', 'default-image.png');
        },
        personImage() {
            return _.get(this.profile, 'person_image.url');
        },
        phone() {
            return 'tel:' + this.profile.phone;
        },
        mail() {
            return 'mailto:' + this.profile.email;
        },
    },
    mounted() {
        this.loadSponsor();

        EventBus.$on('logged-in', () => {
            this.loadSponsor();
        });
    }
};
</script>

<style scoped lang="scss">
.v-image {
  border-radius: 50%;
}
</style>
