<template>
    <validation-observer v-slot="{ handleSubmit }" ref="applicationForm">
        <v-form @submit.prevent="handleSubmit(save)">
            <v-card>
                <v-card-text>
                    <h2 class="title mb-2">
                        {{ $t('ApplicationForm.title') }}
                    </h2>

                    <v-expansion-panels class="mb-2">
                        <v-expansion-panel>
                            <v-expansion-panel-header disable-icon-rotate color="info">
                                Informationen zur Bewerbung
                                <template v-slot:actions>
                                    <v-icon color="white">mdi-alert-circle</v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-3">
                                <p>
                                    DeinAdieu versteht sich als Qualitätsgarant für seine Enduser. Aus diesem Grund wird
                                    jede Bewerbung von einem unabhängigen Gremium geprüft.
                                </p>

                                <h2 class="subtitle-1">Folgende Kriterien entscheiden über die Aufnahme von <strong>Hilfswerken</strong>:
                                </h2>

                                <p>
                                    Aktuelle ZEWO-Zertifizierung oder Einhaltung folgender Aufnahmekriterien:
                                </p>

                                <ul>
                                    <li>Steuerbefreiter Verein oder Stiftung</li>
                                    <li>Komplette Jahresrechnung, die die Mittelverwendung klar darlegt</li>
                                    <li>Niederlassung in der Schweiz</li>
                                    <li>Transparente Onlinepräsenz mit Impressum & Datenschutzerklärung</li>
                                    <li>Organisation handelt integer, professionell, ethisch & wirkungsorientiert und
                                        achtet die Solidarität unter den bestehenden Mitgliedern
                                    </li>
                                </ul>

                                <p>
                                    Verwendung der Gelder für einen der folgenden Zwecke: Menschenrechte & Humanitäres,
                                    Natur-, Umwelt-, & Tierschutz, Menschen mit Behinderung, Kinder & Jugendliche,
                                    Senioren-, Sozial- & Nothilfe, Gesundheitsprävention & Forschung,
                                    Entwicklungszusammenarbeit. DeinAdieu behält sich das Recht vor, Bewerbungen ohne
                                    Nennung von Gründen abzulehnen.
                                </p>

                                <h2 class="subtitle-1">Folgende Kriterien entscheiden über die Aufnahme von <strong>Dienstleistern</strong>:
                                </h2>

                                <h2 class="subtitle-2">Professionalität</h2>

                                <ul>
                                    <li>(Erst-)Ausbildung</li>
                                    <li>Equipment</li>
                                    <li>Standards</li>
                                </ul>
                                <br>

                                <h2 class="subtitle-2">Erfahrung</h2>

                                <ul>
                                    <li>Erfahrung im Umgang mit Betroffenen & Angehörigen</li>
                                    <li>Frequenz der erbrachten Dienstleistungen</li>
                                    <li>Referenzen</li>
                                </ul>
                                <br>

                                <span class="subtitle-2">Leumund</span>

                                <ul>
                                    <li>HR-Eintrag</li>
                                    <li>Betreibungen</li>
                                </ul>
                                <br>

                                <p>In jeder Dienstleisterkategorie werden diese Kriterien unterschiedlich angewandt.</p>

                                <p>
                                    <strong>Bestatter:</strong> >2 Jahre Erfahrung, aktiv im HR ohne Betreibung, Minimum
                                    2 Angestellte (Tag/Nachtdienst), mind. 1 Bestatter/-in mit eidg. Fachausweis, 24h
                                    Piket, min. 25 Bestattungen pro Jahr, Fahrzeug nach Richtlinien für
                                    Bestatterfahrzeuge, 2-3 Kundenreferenzen.
                                </p>

                                <p>
                                    <strong>Palliative Care:</strong> Für Institutionen Qualitätsstandards im Bereich
                                    Palliative Care von Palliative.ch. Wünschenswert: Label „Qualité Palliative“
                                    (Schweizer Verein für Palliative Care). Für SterbebegleiterInnen Fachwissen im
                                    Bereich Medizin, Medizinrecht, Ethik und Psychologie. Erfahrung mit der
                                    medizinethischen Entscheidungsfindung am Lebensende in Spitälern, Heimen oder
                                    Spitex.
                                </p>

                                <p>
                                    <strong>Notare & Rechtsanwälte:</strong> Fachanwälte Erbrecht, juristische Erfahrung
                                    in Bereichen Ehe-/Erbrecht und gegebenenfalls der Willensvollstreckung,
                                    Mitgliedschaft beim SAV/FSA.
                                </p>

                                <p>
                                    <strong>Redner & Musiker:</strong> (Erst-)Ausbildung, 2-3 Kunden-Referenzen,
                                    rhetorische/musikalische Nachweise, Frequenz der Tätigkeit: >15 Auftritte/Jahr.
                                </p>

                                <p>
                                    <strong>Verpflegung:</strong> Saal oder Bereich für Trauergesellschaft mit
                                    mindestens 15 Sitzplätzen, Erfahrung im Bewirten von Trauergesellschaften,
                                    entsprechende Öffnungszeiten.
                                </p>

                                <p>
                                    <strong>Non-Profit:</strong> Aktuelle ZEWO-Zertifizierung, Verwendung der Gelder für
                                    einen der folgenden Zwecke: Menschenrechte & Humanitäres, Natur-, Umwelt-, &
                                    Tierschutz, Menschen mit Behinderung, Kinder & Jugendliche, Senioren-, Sozial- &
                                    Nothilfe, Gesundheitsprävention & Forschung,
                                    Entwicklungszusammenarbeit.
                                </p>

                                <p>
                                    <strong>BestattungsplanerInnen:</strong> Basiswissen im Bereich Medizin,
                                    Medizinrecht, Ethik und Psychologie. Erfahrung im Umgang mit Betroffenen und
                                    Angehörigen. 4-5 Referenzen. Frequenz: >15 Einsätze pro Jahr.
                                </p>

                                <p>
                                    <strong>Bildhauer:</strong> >2 Jahre Erfahrung im Beruf, aktiv im HR ohne
                                    Betreibung, 2-3 Kunden- Referenzen.
                                </p>

                                <p>
                                    Bitte legen Sie die entsprechenden Punkte in Ihrer Bewerbung dar. Bei Fragen stehen
                                    wir Ihnen unter <a href="mailto:info@deinadieu.ch">info@deinadieu.ch</a> zur
                                    Verfügung.
                                </p>

                                <p>
                                    Weitere Informationen:<br>
                                    Nutzungsbedingungen: <a href="https://www.deinadieu.ch/partner-terms"
                                                            target="_blank">www.deinadieu.ch/partner-terms</a><br>
                                    Datenschutz: <a href="https://www.deinadieu.ch/datenschutzerklaerung/">www.deinadieu.ch/datenschutzerklaerung/</a><br>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <validation-provider
                        v-slot="{ errors }"
                        :name="$t('ApplicationForm.applicationText')"
                        mode="eager"
                        rules="required"
                        vid="application_text"
                    >
                        <v-textarea
                            v-model="application.application_text"
                            :error-messages="errors"
                            :rows="6"
                            auto-grow
                            outlined
                        ></v-textarea>
                    </validation-provider>

                    <h2 class="title mb-2 mt-4">
                        {{ $t('ApplicationForm.documentsTitle') }}
                    </h2>

                    <v-data-table
                        v-if="hasApplicationDocuments"
                        :headers="documentHeaders"
                        :items="application.account_application_documents"
                        class="mb-4"
                        disable-pagination
                        hide-default-footer
                        hide-default-header
                    >
                        <template v-slot:item.actions="{item}">
                            <v-btn icon small @click.stop="deleteDocument(item)">
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>

                    <v-alert type="info" dense text>
                        {{ $t('ApplicationForm.uploadInfoText') }}
                    </v-alert>
                    <v-alert type="error" dense text dismissible v-if="dropzoneError">{{ dropzoneError }}</v-alert>

                    <vue-dropzone
                        v-if="dropzoneOptions"
                        id="application-form-vue-dropzone"
                        ref="dropzone"
                        :options="dropzoneOptions"
                        use-custom-slot
                    >
                        <div class="dropzone-custom-content">
                            <h3 class="subtitle-1">{{ $t('Dropzone.title') }}</h3>
                            <div class="caption">{{ $t('Dropzone.caption') }}</div>
                        </div>
                    </vue-dropzone>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="$router.back()" text>{{ $t('OneWord.back') }}</v-btn>
                    <v-btn color="accent" type="submit" :loading="saving">{{ $t('OneWord.next') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import VueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {EventBus} from '../../utils/event-bus';

export default {
    name: 'ApplicationForm',
    components: {ValidationProvider, ValidationObserver, VueDropzone},
    props: {
        account: Object,
    },
    data() {
        return {
            application: {},
            files: [],
            dropzoneOptions: null,
            saving: false,
            dropzoneError: null,
        };
    },
    computed: {
        documentHeaders() {
            return [
                {text: 'Name', value: 'document_name'},
                {text: '', value: 'actions', align: 'right'},
            ];
        },
        hasApplicationDocuments() {
            return _.get(this.application, 'account_application_documents', []).length > 0;
        },
    },
    methods: {
        deleteDocument(document) {
            this.$root.$confirm(
                this.$t('OneWord.delete'),
                this.$t('ConfirmDelete', {parameter: document.document_name})
            ).then(confirm => {
                if (confirm) {
                    axios.delete(`/webapi/create-profile/application/documents/${document.id}`).then(() => {
                        this.application.account_application_documents = [
                            ...this.application.account_application_documents
                        ];
                        _.remove(this.application.account_application_documents, {id: document.id});
                    }).catch(error => {
                        EventBus.$emit('error', error.data.message);
                    });
                }
            });
        },
        save() {
            this.saving = true;
            this.saveFiles();
        },
        saveFiles() {
            this.files.length = 0;
            let files = this.$refs.dropzone.getAcceptedFiles();
            let promises = [];

            this.dropzoneError = null;

            _(files).forEach(file => {
                let form = new FormData();
                form.append('data', file, file.name);

                promises.push(this.toBase64(form.get('data')));
            });

            Promise.all(promises).then(res => {
                _(files).forEach((file, index) => {
                    this.files.push({
                        name: file.name,
                        data: res[index],
                    });
                });

                let payload = this.application;
                payload['application_files'] = this.files;

                axios.post(`/webapi/create-profile/application/${this.application.id}`, payload).then(() => {
                    // this.$emit('files', this.files);
                    // this.$emit('save', this.application);
                    this.$router.push({name: 'create-profile-partner-type'});
                }).catch(error => {
                    if (!_.isEmpty(_.get(error.data.error, 'application_files'))) {
                        this.$refs.applicationForm.setErrors(error.data.errors);
                    } else {
                        this.dropzoneError = _.get(error.data, 'message');
                    }
                }).finally(() => {
                    this.saving = false;
                });
            });
        },
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
    },
    mounted() {
        this.dropzoneOptions = {
            url: window.location.href,
            autoProcessQueue: false,
            addRemoveLinks: true,
            maxFilesize: 3,
        };

        axios.get('/webapi/create-profile/account').then(response => {
            this.application = response.data.data;
        });
    },
};
</script>

<style scoped lang="scss">
.vue-dropzone {
    ::v-deep .dz-progress {
        display: none;
    }
}
</style>
