<template>
    <div>
        <h5 class="headline mb-4">{{ $t('OneWord.company') }}</h5>

        <validation-provider v-slot="{ errors }" :name="$t('OneWord.company')" mode="eager" rules="required|max:255" vid="company_name">
            <v-text-field
                v-model="profile.company_name"
                :counter="255"
                :error-messages="errors"
                :label="$t('OneWord.company')"
            ></v-text-field>
        </validation-provider>

        <v-alert v-if="!isTranslateable" class="mt-8" dense text type="info">
            {{ $t('ProfileForm.translationInfo') }}
        </v-alert>

        <v-row>
            <v-col cols="12" md="8">
                <validation-provider v-slot="{ errors }" :name="$t('OneWord.address')" mode="eager" rules="required|max:255" vid="street">
                    <v-text-field
                        v-model="profile.street"
                        :counter="255"
                        :disabled="!isTranslateable"
                        :error-messages="errors"
                        :label="$t('OneWord.address')"
                        autocomplete="street-address"
                    ></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" :name="$t('OneWord.poBox')" mode="eager" rules="max:255" vid="post_office_box">
                    <v-text-field
                        v-model="profile.post_office_box"
                        :counter="255"
                        :disabled="!isTranslateable"
                        :error-messages="errors"
                        :label="$t('OneWord.poBox')"
                        autocomplete="street-address"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" :name="$t('OneWord.zip')" mode="eager" rules="required|max:5" vid="zip">
                    <v-text-field
                        v-model="profile.zip"
                        :disabled="!isTranslateable"
                        :error-messages="errors"
                        :label="$t('OneWord.zip')"
                        autocomplete="postal-code"
                    ></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" md="8">
                <validation-provider v-slot="{ errors }" :name="$t('OneWord.place')" mode="eager" rules="required|max:255" vid="place">
                    <v-text-field
                        v-model="profile.place"
                        :counter="255"
                        :disabled="!isTranslateable"
                        :error-messages="errors"
                        :label="$t('OneWord.place')"
                        autocomplete="address-level2"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>

        <validation-provider v-slot="{ errors }" :name="$t('OneWord.website')" mode="eager" rules="max:255|url" vid="website">
            <v-text-field
                v-model="profile.website"
                :counter="255"
                :error-messages="errors"
                :hint="$t('OneWord.urlHint')"
                :label="$t('OneWord.website')"
                persistent-hint
                prepend-inner-icon="mdi-web"
            ></v-text-field>
        </validation-provider>

        <h5 class="headline mt-6 mb-4">{{ $t('AccountForm.subheaderContact') }}</h5>

        <validation-provider v-slot="{ errors }" :name="$t('OneWord.salutation.label')" mode="eager" rules="required" vid="salutation_id">
            <v-select
                v-model="profile.salutation_id"
                :error-messages="errors"
                :items="salutations"
                :label="$t('OneWord.salutation.label')"
                item-text="title"
                item-value="id"
            ></v-select>
        </validation-provider>
        <v-row>
            <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" :name="$t('OneWord.first-name')" mode="eager" rules="required|max:255" vid="first_name">
                    <v-text-field
                        v-model="profile.first_name"
                        :counter="255"
                        :error-messages="errors"
                        :label="$t('OneWord.first-name')"
                        autocomplete="given-name"
                    ></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" :name="$t('OneWord.last-name')" mode="eager" rules="required|max:255" vid="last_name">
                    <v-text-field
                        v-model="profile.last_name"
                        :counter="255"
                        :error-messages="errors"
                        :label="$t('OneWord.last-name')"
                        autocomplete="family-name"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <validation-provider v-slot="{ errors }" :name="$t('OneWord.email')" mode="eager" rules="required|email|max:255" vid="email">
                    <v-text-field
                        v-model="profile.email"
                        :counter="255"
                        :error-messages="errors"
                        :hint="$t('AccountForm.EmailHint')"
                        :label="$t('OneWord.email')"
                        autocomplete="email"
                        persistent-hint
                        prepend-inner-icon="mdi-at"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" :name="$t('OneWord.phone')" mode="eager" rules="max:255|phone" vid="phone">
                    <v-text-field
                        v-model="profile.phone"
                        :error-messages="errors"
                        :hint="$t('OneWord.phoneHint')"
                        :label="$t('OneWord.phone')"
                        :placeholder="phonePlaceholder"
                        type="tel"
                        persistent-hint
                        prepend-inner-icon="mdi-phone"
                    ></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" :name="$t('OneWord.mobile')" mode="eager" rules="max:255|phone" vid="mobile_phone">
                    <v-text-field
                        v-model="profile.mobile_phone"
                        :error-messages="errors"
                        :hint="$t('OneWord.phoneHint')"
                        :label="$t('OneWord.mobile')"
                        :placeholder="phonePlaceholder"
                        type="tel"
                        persistent-hint
                        prepend-inner-icon="mdi-cellphone"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>

        <v-checkbox
            v-model="profile.has_permanent_operation"
            :label="$t('ProfileComtactForm.hasPermanentOperation')"
        ></v-checkbox>
    </div>
</template>

<script>
    import {ValidationProvider} from 'vee-validate';
    import {mapGetters} from "vuex";

    export default {
        name: 'ProfileForm',
        components: {ValidationProvider},
        props: {
            profile: {
                type: Object,
                default() {
                    return {};
                },
            },
            isNgo: Boolean,
            lang: String,
        },
        computed: {
            ...mapGetters('env', ['isSwitzerland']),
            isTranslateable() {
                if (this.isNgo) {
                    return true;
                }

                return this.lang === 'de_CH';
            },
            phonePlaceholder() {
                if (this.isSwitzerland) {
                    return '+41';
                }

                return '+49';
            }
        },
        watch: {
            company: {
                deep: true,
                handler(value) {
                    this.$emit('update:profile', value);
                },
            },
        },
        data() {
            return {
                salutations: [
                    {id: 1, title: this.$t('OneWord.salutation.f')},
                    {id: 2, title: this.$t('OneWord.salutation.m')},
                ],
            };
        },
    };
</script>

<style scoped>

</style>
