<template>
    <v-list dense nav>
        <v-subheader class="text-uppercase">{{ $t('App.nav.partner') }}</v-subheader>
        <template v-for="item in items">
            <v-list-item
                v-if="$can(item.action, item.subject)"
                :to="{path: item.to}"
                :key="`nav-${item.to}`"
                :data-cy="'nav' + item.to.replaceAll('/', '-')"
            >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="item.external">
                    <v-icon small>mdi-open-in-new</v-icon>
                </v-list-item-action>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
    export default {
        name: 'AppAdminNav',
        computed: {
            items() {
                return [
                    {title: this.$t('AppAdminNav.statistic'), icon: 'mdi-chart-line', to: '/admin/statistic', action: 'read', subject: 'Account'},
                    {title: this.$t('AppAdminNav.leads'), icon: 'mdi-account-heart-outline', to: '/admin/leads', action: 'read', subject: 'Testator'},
                    {title: this.$t('AppAdminNav.tools'), icon: 'mdi-tools', to: '/admin/tools', action: 'read', subject: 'Account'},
                    {title: this.$t('AppAdminNav.websiteRanking'), icon: 'mdi-adjust', to: '/admin/website-ranking', action: 'ngo', subject: 'Statistic'},
                    {title: this.$t('AppAdminNav.subscription'), icon: 'mdi-cash-multiple', to: '/admin/subscription', action: 'read', subject: 'Account'},
                    {title: this.$t('AppAdminNav.profiles'), icon: 'mdi-map-marker', to: '/admin/profiles', action: 'read', subject: 'Account'},
                    {title: this.$t('AppAdminNav.whitelabel'), icon: 'mdi-brush', to: '/admin/whitelabel', action: 'whitelabel', subject: 'Account'},
                    {title: this.$t('AppAdminNav.users'), icon: 'mdi-card-account-phone-outline', to: '/admin/account', action: 'read', subject: 'Account'},
                    {title: this.$t('AppAdminNav.downloads'), icon: 'mdi-tray-arrow-down', to: '/admin/downloads', action: 'read', subject: 'Account'},
                    {title: this.$t('AppAdminNav.support'), icon: 'mdi-lifebuoy', to: '/admin/support', action: 'read', subject: 'Account', external: true},
                ];
            },
        }
    };
</script>

<style scoped lang="scss">

</style>
