<template>
    <v-app 
        v-if="isInitialized"
        :class="showBackground ? 'has-background' : null"
    >
        <v-navigation-drawer
            v-if="showNavigationDrawer"
            v-model="drawer"
            :clipped="$vuetify.breakpoint.lgAndUp"
            :style="{paddingTop: $vuetify.breakpoint.mdAndDown ? '3.5rem' : 0 }"
            app
            color="rgb(255,255,255)"
            width="300"
        >
            <affiliate-info v-if="!$can('read', 'Account')" />
            <app-user-nav v-if="$can('read', 'User')" />
        </v-navigation-drawer>

        <app-bar :flat="showBackground" @drawer="(drawer = !drawer)" />

        <v-main>
            <div class="da-background" :style="backgroundStyles">
                <v-container :class="{'p-0': $vuetify.breakpoint.mdAndDown}" class="da-content">
                    <the-prominent-notification />
                    <keep-alive include="AdminProfileList">
                        <router-view />
                    </keep-alive>
                </v-container>
            </div>
            <template v-if="showDisclaimer">
                <div class="text-caption bg-gray-100 text-gray-600 pa-4 text-center">
                    <div v-if="$te(`Affiliate.${tool}.agreement`)" v-html="$t(`Affiliate.${tool}.agreement`)" class="max-w-3xl mx-auto"></div>
                    <div v-else v-html="$t('Affiliate.general.agreement')" class="max-w-3xl mx-auto"></div>
                </div>
            </template>
            <whitelabel-content v-if="showWhitelabelContent" />
            <how-it-works v-else-if="showHowItWorks" />
            <app-footer />
        </v-main>

        <confirm ref="confirm" />
        <top-snackbar />
    </v-app>
</template>

<script>
import AffiliateInfo from './components/App/AffiliateInfo';
import AppBar from './components/App/AppBar';
import AppFooter from './components/App/AppFooter';
import AppUserNav from './components/App/AppUserNav';
import Confirm from './components/Confirm';
import HowItWorks from './components/App/HowItWorks';
import WhitelabelContent from './components/App/WhitelabelContent';
import TheProminentNotification from "@/components/TheProminentNotification.vue";
import TopSnackbar from './components/TopSnackbar';
import zohoPageSense from './mixins/zohoPageSense';
import {EventBus} from './utils/event-bus';
import {mapActions, mapGetters} from 'vuex';

export default {
    components: {
        AffiliateInfo,
        AppBar,
        AppFooter,
        AppUserNav,
        Confirm,
        HowItWorks,
        WhitelabelContent,
        TheProminentNotification,
        TopSnackbar,
    },

    mixins: [zohoPageSense],

    metaInfo() {
        return {
            link: [
                { rel: 'icon', href: this.getFavicon }
            ]
        }
    },

    data() {
        return {
            drawer: null,
            isInitialized: false,
        };
    },

    computed: {
        ...mapGetters('env', {
            envLoaded: 'isLoaded',
            getFavicon: 'getFavicon',
            isAuthenticated: 'isAuthenticated',
            isWhiteLabel: 'isWhiteLabel',
            getColors: 'getColors',
        }),

        ...mapGetters('affiliateProfile', {
            affiliateProfile: 'profile',
            affiliateLoaded: 'isLoaded',
            hasAffiliateProfile: 'hasProfile',
        }),

        tool() {
            return _.get({
                'consulting-tool': 'consulting',
                'testament-tool': 'testament',
                'patient-decree-tool': 'patientDecree',
                'advance-care-directive-tool': 'advanceCareDirective',
                'webinars': 'webinar',
                'webinar-details': 'webinar',
                'login': 'login',
            }, this.$route.name);
        },

        isToolRoute() {
            return _.isString(this.tool);
        },

        agreementAccepted() {
            return this.$store.getters[this.tool + '/agreementAccepted'];
        },

        toolStarted() {
            // this is only relevant for the actual tools which 
            // might have an AffiliateAgreement wall shown
            return this.isToolRoute 
                && this.agreementAccepted
                && this.$route.name.includes('-tool');
        },

        /**
         * Is the agreement wall visible at the start of the tool?
         */
        showBackground() {
            if (this.$route.query.preview && !this.toolStarted) {
                return true;
            }

            return this.isToolRoute && ! this.isAuthenticated && ! this.toolStarted;
        },

        showHowItWorks() {
            return this.showBackground && this.tool === 'testament';
        },

        showWhitelabelContent() {
            return this.isWhiteLabel && this.showHowItWorks;
        },

        showDisclaimer() {
            return this.showBackground && ! this.isWhiteLabel && this.tool !== 'webinar';
        },

        showNavigationDrawer() {
            if (this.$route.query.preview && !this.toolStarted) {
                return false;
            }

            // always show navigation drawer when user is logged in
            if (this.isAuthenticated) {
                return true;
            }

            if (!this.affiliateProfile) {
                return false;
            }

            // do NOT show the navigation drawer when the agreement screen is visible
            return ! this.showBackground;
        },

        backgroundStyles() {
            if (this.showBackground && this.affiliateProfile) {
                switch (_.get(this.affiliateProfile, 'background.type')) {
                case 'image':
                    if (_.get(this.affiliateProfile, 'background_image') && !this.$vuetify.breakpoint.mdAndDown) {
                        return {
                            backgroundImage: `url(${this.affiliateProfile.background_image.url})`,
                            backgroundSize: 'cover',
                        };
                    }
                    break;
                case 'gradient':
                    return {
                        background: `linear-gradient(to bottom, ${this.affiliateProfile.background.gradient.start}, ${this.affiliateProfile.background.gradient.end})`,
                    };
                }
            }

            return null;
        },
    },

    methods: {
        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),

        init() {            
            // this is the place to put logic after the env data
            // has loaded and before the app is being displayed
            
            this.loadAffiliate().finally(() => {
                // we need to have the affiliate loaded before we can render the app
                this.isInitialized = true;

                /**
                 * Add after UI is initialised and DOM ready.
                 */
                this.$nextTick(() => {
                    this.$root.$confirm = _.get(this.$refs.confirm, 'open', false);
                });
            });
            
            EventBus.$on('change-locale', (locale) => {
                this.$vuetify.lang.current = locale.substring(0, 2);
            });
        },

        setColors() {
            _.forEach(this.getColors, (color, key) => {
                if (color) {
                    document.documentElement.style.setProperty('--da-' + key, color);
                    this.$vuetify.theme.themes.light[key] = color;
                }
            });
        },
    },

    mounted() {
        this.init();
        this.$nextTick(() => this.setColors());
    },
};
</script>

<style lang="scss" scoped>
main {
    background-color: rgb(250, 250, 250);
}

::v-deep .v-main__wrap {
    display: flex;
    flex-direction: column;
}

.da-background {
    flex-grow: 1;
}

a.v-list-item--link {
    text-decoration: none;
}
</style>