<template>
    <v-input
        :dense="dense"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
    >
        <validation-observer ref="dateFieldForm" slim>
            <v-row align="center" no-gutters>
                <v-col cols="12">
                    <span class="caption">
                        {{ label }}
                    </span>
                </v-col>
            </v-row>
            <v-row align="center" no-gutters>
                <v-col cols="3">
                    <validation-provider
                        v-slot="{errors}"
                        rules="between:1,31"
                        slim
                    >
                        <v-text-field
                            v-model="day"
                            :dense="dense"
                            :error-messages="errors"
                            :label="$t('OneWord.day')"
                            data-cy="date-field-day"
                            type="number"
                            min="1" 
                            max="31"
                            hide-details
                            outlined
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="6">
                    <validation-provider
                        v-slot="{errors}"
                        rules="between:1,12"
                        slim
                    >
                        <v-select
                            v-model="month"
                            :items="months"
                            :label="$t('OneWord.month')"
                            :error-messages="errors"
                            :dense="dense"
                            data-cy="date-field-month"
                            type="number"
                            min="1900" 
                            :max="(new Date()).getFullYear().toString()"
                            hide-details
                            class="pa-4"
                            outlined
                        ></v-select>
                    </validation-provider>
                </v-col>
                <v-col cols="3">
                    <validation-provider
                        v-slot="{errors}"
                        :rules="`between:1900,${(new Date()).getFullYear()}`"
                        slim
                    >
                        <v-text-field
                            v-model="year"
                            :dense="dense"
                            :error-messages="errors"
                            :label="$t('OneWord.year')"
                            data-cy="date-field-year"
                            hide-details
                            outlined
                        ></v-text-field>
                    </validation-provider>
                </v-col>
            </v-row>
        </validation-observer>
    </v-input>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {formatDate} from '@/composables/Helpers';
import {EventBus} from '@/utils/event-bus';
import {getCurrentLocale} from '@/plugins/i18n';

export default {
    name: 'DateField',
    components: {ValidationObserver, ValidationProvider},
    props: {
        label: String,
        dense: Boolean,
        value: String,
        errorMessages: Array,
        hideDetails: Boolean,
    },
    data() {
        return {
            day: null,
            month: null,
            year: null,
            months: [],
        };
    },
    watch: {
        day() {
            this.validateFields();
        },
        month() {
            this.validateFields();
        },
        year() {
            this.validateFields();
        },
        value(value) {
            if (value) {
                this.setValue(value);
            }
        },
    },
    methods: {
        setValue(value) {
            let newDate = new Date(value);
            let oldDate = new Date(Date.UTC(
                this.year,
                this.month - 1,
                this.day,
            ));

            if (newDate.getTime() !== oldDate.getTime()) {
                this.day = newDate.getDate();
                this.month = newDate.getMonth() + 1;
                this.year = newDate.getFullYear();
            }
        },
        validateFields() {
            try {
                this.$refs.dateFieldForm.validate(true).then(result => {
                    if (result === true && this.day && this.month && this.year) {
                        let date = new Date(Date.UTC(
                            this.year,
                            this.month - 1,
                            this.day,
                        ));
                        this.$emit('input', formatDate(date));
                    } else {
                        this.$emit('input');
                    }
                });
            } catch (e) {
                this.$emit('input');
            }
        },
        translateMonths() {
            // set current locale for moment
            moment.locale(getCurrentLocale());

            this.months = [];
            for (let month = 1; month <= 12; month++) {
                this.months.push({
                    value: month,
                    text: moment({ month: (month - 1) }).format('MMMM'),
                });
            }
        },
    },
    mounted() {
        if (!_.isEmpty(this.value)) {
            this.setValue(this.value);
        }

        EventBus.$on('locale-changed', () => {
            this.translateMonths();
        });
        
        this.translateMonths();
    }
};
</script>

<style>
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>