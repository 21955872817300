<template>
    <validation-observer v-slot="{ handleSubmit }" ref="accountForm">
        <v-form @submit.prevent="handleSubmit(save)">
            <v-card>
                <v-card-text>
                    <h2 class="title mb-2">
                        {{ $t('AccountForm.title') }}
                    </h2>

                    <v-alert type="info" dense>
                        {{ $t('AccountForm.info') }}
                    </v-alert>

                    <v-alert type="error" dense v-if="formError">
                        {{ formError }}
                    </v-alert>

                    <h3 class="subtitle-1 mb-2">
                        {{ $t('AccountForm.subheaderCompany') }}
                    </h3>

                    <validation-provider
                        v-slot="{ errors }"
                        :name="$t('OneWord.company')"
                        mode="eager"
                        rules="required|max:255"
                        vid="company_name"
                    >
                        <v-text-field
                            v-model="company.company_name"
                            :label="$t('OneWord.company')"
                            :counter="255"
                            :error-messages="errors"
                        ></v-text-field>
                    </validation-provider>

                    <v-row>
                        <v-col cols="12" md="8">
                            <validation-provider
                                v-slot="{ errors }"
                                :name="$t('OneWord.address')"
                                mode="eager"
                                rules="required|max:255"
                                vid="street"
                            >
                                <v-text-field
                                    :label="$t('OneWord.address')"
                                    v-model="company.street"
                                    :error-messages="errors"
                                    :counter="255"
                                    autocomplete="street-address"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="4">
                            <validation-provider :name="$t('OneWord.poBox')" rules="max:255" v-slot="{ errors }"
                                                 mode="eager" vid="post_office_box">
                                <v-text-field
                                    :label="$t('OneWord.poBox')"
                                    v-model="company.post_office_box"
                                    :error-messages="errors"
                                    :counter="255"
                                    autocomplete="street-address"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <validation-provider :name="$t('OneWord.zip')" rules="required|max:5" v-slot="{ errors }"
                                                 mode="eager" vid="zip">
                                <v-text-field
                                    :label="$t('OneWord.zip')"
                                    v-model="company.zip"
                                    :error-messages="errors"
                                    autocomplete="postal-code"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="8">
                            <validation-provider :name="$t('OneWord.place')" rules="required|max:255"
                                                 v-slot="{ errors }" mode="eager" vid="place">
                                <v-text-field
                                    :label="$t('OneWord.place')"
                                    v-model="company.place"
                                    :error-messages="errors"
                                    autocomplete="address-level2"
                                    :counter="255"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>

                    <validation-provider :name="$t('OneWord.website')" rules="required|url" v-slot="{ errors }"
                                         mode="eager" vid="website">
                        <v-text-field
                            :label="$t('OneWord.website')"
                            v-model="company.website"
                            :error-messages="errors"
                            :counter="255"
                            prepend-inner-icon="mdi-web"
                            :hint="$t('OneWord.urlHint')"
                        ></v-text-field>
                    </validation-provider>

                    <h3 class="subtitle-1 mb-2 mt-4">
                        {{ $t('AccountForm.subheaderContact') }}
                    </h3>

                    <validation-provider :name="$t('OneWord.salutation.label')" rules="required" v-slot="{ errors }"
                                         mode="eager" vid="salutation_id">
                        <v-select
                            :label="$t('OneWord.salutation.label')"
                            :items="salutations"
                            item-text="label"
                            item-value="id"
                            :error-messages="errors"
                            v-model="company.salutation_id"
                        ></v-select>
                    </validation-provider>
                    <v-row>
                        <v-col cols="12" md="6">
                            <validation-provider :name="$t('OneWord.first-name')" rules="required|max:255"
                                                 v-slot="{ errors }" mode="eager" vid="first_name">
                                <v-text-field
                                    :label="$t('OneWord.first-name')"
                                    v-model="company.first_name"
                                    :error-messages="errors"
                                    autocomplete="given-name"
                                    :counter="255"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider :name="$t('OneWord.last-name')" rules="required|max:255"
                                                 v-slot="{ errors }" mode="eager" vid="last_name">
                                <v-text-field
                                    :label="$t('OneWord.last-name')"
                                    :error-messages="errors"
                                    v-model="company.last_name"
                                    autocomplete="family-name"
                                    :counter="255"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <validation-provider :name="$t('OneWord.email')" rules="required|email|max:255"
                                                 v-slot="{ errors }" mode="eager" vid="email">
                                <v-text-field
                                    :label="$t('OneWord.email')"
                                    v-model="company.email"
                                    :error-messages="errors"
                                    autocomplete="email"
                                    :hint="$t('AccountForm.EmailHint')"
                                    persistent-hint
                                    prepend-inner-icon="mdi-at"
                                    :counter="255"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <validation-provider :name="$t('OneWord.phone')" rules="max:255|phone" v-slot="{ errors }"
                                                 mode="eager" vid="phone">
                                <v-text-field
                                    :label="$t('OneWord.phone')"
                                    v-model="company.phone"
                                    :error-messages="errors"
                                    prepend-inner-icon="mdi-phone"
                                    type="tel"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider :name="$t('OneWord.mobile')" rules="max:255|phone" v-slot="{ errors }"
                                                 mode="eager" vid="mobile">
                                <v-text-field
                                    :label="$t('OneWord.mobile')"
                                    :error-messages="errors"
                                    v-model="company.mobile_phone"
                                    prepend-inner-icon="mdi-cellphone"
                                    type="tel"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :loading="saving"
                        color="accent"
                        type="submit"
                    >{{ $t('OneWord.next') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {mapGetters} from 'vuex';

export default {
    name: 'AccountStep',
    components: {ValidationProvider, ValidationObserver},
    data() {
        return {
            company: {},
            salutations: [
                {id: 1, label: this.$t('OneWord.salutation.f')},
                {id: 2, label: this.$t('OneWord.salutation.m')},
            ],
            saving: false,
            formError: null,
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
    },
    methods: {
        save() {
            this.saving = true;
            this.formError = null;

            axios.post('/webapi/create-profile/account', this.company).then(response => {
                this.company = response.data.data;
                this.formError = null;
                this.$router.push({name: 'create-profile-application'});
            }).catch(error => {
                this.$refs.accountForm.setErrors(_.get(error, 'data.errors', []));

                if (error.response.status === 409) {
                    this.formError = 'Sie besitzen bereits ein Profil bei DeinAdieu. Bitte erstellen sie neue Profile unter Partner > Präsenz.';
                }
                else {
                    this.formError = error.data.message;
                }
            }).finally(() => {
                this.saving = false;
            });
        },
    },
    mounted() {
        axios.get('/webapi/create-profile/account').then(response => {
            this.company = response.data.data;
        }).finally(() => {
            this.company.email = this.user.email;
        });
    },
};
</script>

<style scoped>

</style>
