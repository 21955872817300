<template>
    <div>
        <v-toolbar color="transparent" flat>
            <v-btn icon @click="$router.push({name: 'admin-leads'})">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>
                <h1 class="title">
                    {{ $t('AdminLeadEdit.title') }}
                </h1>
            </v-toolbar-title>
        </v-toolbar>

        <v-card class="mb-4" :loading="dataLoading">
            <v-card-title>
                {{ personCardTitle }}
            </v-card-title>
            <v-card-text>

                <template v-if="hasNgoThanksExternal">
                    <v-alert v-if="ngoThanksExternal"
                             class="mb-8"
                             text
                             type="info">
                        {{ $t('AdminTestator.ngoThanksExternal') }}
                    </v-alert>
                    <v-alert v-else-if="isNgoThanks(item)"
                             class="mb-8"
                             text
                             type="success"
                    >
                        {{ $t('AdminTestator.ngoThanksTrue') }}
                    </v-alert>
                    <v-alert
                        v-else-if="!item.anonymized"
                        class="mb-8"
                        icon="mdi-minus-circle-outline"
                        text
                        type="error"
                    >
                        {{ $t('AdminTestator.ngoThanksFalse') }}
                    </v-alert>
                </template>

                <template v-else>
                    <template v-if="item.lead_type === 'will' || item.lead_type === 'consulting'">
                        <v-alert
                            v-if="ngoThanks"
                            class="mb-4"
                            text
                            type="success"
                        >
                        <span
                            class="d-block font-bold leading-relaxed"
                            v-html="$t('AdminLeadEdit.ngoThanks.title')"
                        ></span>
                            <span v-html="$t('AdminLeadEdit.ngoThanks.true', {btn_label_text: $t('Affiliate.ngoThanks.general.acceptLabel') })"></span>
                        </v-alert>
                        <v-alert
                            v-else
                            class="mb-4"
                            text
                            type="error"
                        >
                        <span
                            class="d-block font-bold leading-relaxed"
                            v-html="$t('AdminLeadEdit.ngoThanks.title')"
                        ></span>
                            <span v-html="$t('AdminLeadEdit.ngoThanks.false', {btn_label_text: $t('Affiliate.ngoThanks.general.acceptLabel') })"></span>
                        </v-alert>
                    </template>

                    <v-alert
                        v-if="ngoInfo"
                        class="mb-8"
                        text
                        type="success"
                    >
                        <span
                            class="d-block font-bold leading-relaxed"
                            v-html="$t('AdminLeadEdit.ngoInfo.title')"
                        ></span>
                        <span v-html="$t('AdminLeadEdit.ngoInfo.true', {btn_label_text: $t('Affiliate.ngoInfo.general.acceptLabel') })"></span>
                    </v-alert>
                    <v-alert
                        v-else
                        class="mb-8"
                        text
                        type="error"
                    >
                        <span
                            class="d-block font-bold leading-relaxed"
                            v-html="$t('AdminLeadEdit.ngoInfo.title')"
                        ></span>
                        <span v-html="$t('AdminLeadEdit.ngoInfo.false', {btn_label_text: $t('Affiliate.ngoInfo.general.acceptLabel') })"></span>
                    </v-alert>
                </template>

                <template v-if="item.last_name">
                    <v-row class="pb-3">
                        <v-col :md="hasSalutation ? 4 : 6" cols="12">
                            <v-text-field
                                :label="$t('OneWord.firstName')"
                                :value="item.first_name"
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col :md="hasSalutation ? 4 : 6" cols="12">
                            <v-text-field
                                :label="$t('OneWord.lastName')"
                                :value="item.last_name"
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col v-if="hasSalutation" cols="12" md="4">
                            <v-text-field
                                :label="$t('OneWord.salutation.label')"
                                :value="salutation(item.salutation)"
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                :label="$t('OneWord.address')"
                                :value="item.address"
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                :label="$t('OneWord.zip')"
                                :value="item.zip"
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                :label="$t('OneWord.place')"
                                :value="item.place"
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col :md="isSwitzerland ? 6 : 12" cols="12">
                            <v-text-field
                                :label="$t('OneWord.birthday')"
                                :value="item.birthday | date"
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col v-if="isSwitzerland" cols="12" md="6">
                            <v-text-field
                                :label="$t('OneWord.hometown')"
                                :value="item.hometown"
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                :label="$t('OneWord.email')"
                                :value="item.email"
                                dense
                                hide-details
                                readonly
                                type="email"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                :label="$t('OneWord.phone')"
                                :value="item.phone"
                                dense
                                hide-details
                                readonly
                                type="tel"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </template>
                <template v-else-if="item.email">
                    <v-row>
                        <v-col>
                            <v-text-field
                                :label="$t('OneWord.email')"
                                :value="item.email"
                                dense
                                hide-details
                                readonly
                                type="email"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="item.deletion_scheduled_at">
                    <v-row>
                        <v-col>
                            <v-alert v-if="item.deletion_scheduled_at"
                                     class="mb-8"
                                     text
                                     type="info">
                                <span v-if="item.anonymized">{{ $t('AdminLeadEdit.userDeleted') }}</span>
                                <span v-else>{{ $t('AdminLeadEdit.userDeletionScheduled') }}</span>
                                {{ item.deletion_scheduled_at | date }}.
                            </v-alert>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="item.lead_type === 'consulting' && item.consulting_contact_ngo_info_text">
                    <v-row>
                        <v-col>
                            <v-textarea
                                :label="$t('AdminLead.consultingContactNgoInfoText')"
                                :rows="1"
                                :value="item.consulting_contact_ngo_info_text"
                                auto-grow
                                hide-details
                                readonly
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
        </v-card>

        <v-card class="mb-4" v-if="item.geo_location">
            <v-card-title>
                {{ $t('AdminLeadEdit.location') }}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <span>
                            {{ $t('AdminLeadEdit.locationHint') }}
                        </span>
                        <v-text-field
                            :value="location"
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <admin-lead-details :item="item" />

        <v-form @submit.prevent="save">
            <v-card :disabled="!isNgoThanks(item)">
                <v-card-title>
                    {{ $t('AdminTestatorEdit.title') }}
                </v-card-title>
                <v-card-text>
                    <div class="mb-4">
                        {{ $t('AdminTestatorEdit.infoText') }}
                    </div>
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-checkbox
                                v-model="item.ngo_is_new_contact"
                                :false-value="false"
                                :indeterminate="item.ngo_is_new_contact === null"
                                :label="$t('AdminTestatorEdit.ngoIsNewContact')"
                                :true-value="true"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="item.ngo_is_in_crm"
                                :false-value="false"
                                :indeterminate="item.ngo_is_in_crm === null"
                                :label="$t('AdminTestatorEdit.ngoIsInCrm')"
                                :true-value="true"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-textarea
                                v-model="item.ngo_info_text"
                                :hint="$t('AdminTestatorEdit.ngoInfoTextHint')"
                                :label="$t('AdminTestatorEdit.ngoInfoText')"
                                persistent-hint
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn :loading="loading" color="accent" type="submit">
                        <v-icon>mdi-save</v-icon>
                        {{ $t('OneWord.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import {get, has, isNull} from 'lodash';
import {mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';
import AdminLeadDetails from './AdminLeadDetails.vue';

export default {
    name: 'AdminLeadEdit',
    data() {
        return {
            loading: false,
            dataLoading: false,

            item: {
                other_ngos: []
            },
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        ...mapGetters('env', [
            'isSwitzerland',
        ]),
        personCardTitle() {
            if (this.item.lead_type === 'will') {
                if (has(this.item, 'last_name')) {
                    return this.$t('AdminLeadEdit.personData');
                }

                return this.$t('AdminLeadEdit.userData');
            }

            return this.$t(`AdminLeads.leadType.items.${this.item.lead_type}`);
        },
        hasSalutation() {
            return this.item.lead_type !== 'consulting';
        },
        ngoThanks() {
            return get(this.item, 'ngo_thanks', false);
        },
        ngoInfo() {
            return get(this.item, 'ngo_info', false);
        },
        hasNgoThanksExternal() {
            /**
             * Legacy Blue Dot:
             * Implizite zustimmung, wenn Zugriff via externen Affiliate-Link erfolgte
             */
            return !isNull(get(this.item, 'ngo_thanks_external'));
        },
        ngoThanksExternal() {
            /**
             * Legacy Blue Dot:
             * Implizite zustimmung, wenn Zugriff via externen Affiliate-Link erfolgte
             */
            return get(this.item, 'ngo_thanks_external', false);
        },
        location() {
            return _([
                _.get(this.item, 'geo_location.plz'),
                _.get(this.item, 'geo_location.place'),
                _.get(this.item, 'geo_location.state_name'),
                _.get(this.item, 'geo_location.country'),
            ]).filter().join(', ');
        },
    },
    filters: {
        date(value) {
            if (value) {
                return moment(value).format('L');
            }

            return null;
        },
        dateTime(value) {
            if (value) {
                return moment(value).format('LLL');
            }

            return null;
        },
    },
    methods: {
        load() {
            this.dataLoading = true;
            axios.get(`/webapi/leads/${this.$route.params.lead_id}`).then(response => {
                this.item = get(response, 'data.data');
            }).finally(() => {
                this.dataLoading = false;
            });
        },
        salutation(value) {
            switch (value) {
                case 'm':
                    return this.$t('OneWord.salutation.m');
                case 'f':
                    return this.$t('OneWord.salutation.f');
                case 'd':
                    return this.$t('OneWord.salutation.d');
                default:
                    return value;
            }
        },
        isNgoThanks(item) {
            return get(item, 'ngo_thanks', false);
        },
        save() {
            this.loading = true;
            axios.put(`/webapi/leads/${this.item.id}`, this.item).then(() => {
                EventBus.$emit('success');
            }).catch(error => {
                EventBus.$emit('error', get(error, 'response.data.message'));
            }).finally(() => {
                this.loading = false;
            });
        },
        openUrl(url) {
            window.open(url, '_blank');
        },
    },
    mounted() {
        this.load();
    },
    components: {
        AdminLeadDetails
    },
}
</script>

<style scoped>

</style>