import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/es5/locale/en';
import de from 'vuetify/es5/locale/de';
import it from 'vuetify/es5/locale/it';
import fr from 'vuetify/es5/locale/fr';
import icons from './icons';
import svg from './svg';
import {usei18n} from "@/plugins/i18n";
import '@/plugins/mask';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2F1F3C',
                secondary: '#DAC1BA',
                accent: '#745567',
                pp: '#CE468A',
            },
        },
    },
    icons: {
        iconfont: 'mdi',
        values: Object.assign(svg, icons),
    },
    lang: {
        locales: {en, de, it, fr},
        current: usei18n().locale,
    },
});
