<template>
    <div>
        <h1 class="title mb-2">
            {{ $t('CreateProfile.title') }}
        </h1>

        <template v-if="isAuthenticated">

            <profile-created v-if="created"></profile-created>

            <v-stepper v-model="step" v-else>
                <v-stepper-header>
                    <v-stepper-step step="1" :complete="step > 1">{{ $t('CreateProfile.step.1') }}</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2" :complete="step > 2">{{ $t('CreateProfile.step.2') }}</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" :complete="step > 3">{{ $t('CreateProfile.step.3') }}</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="4" :complete="step > 4">{{ $t('CreateProfile.step.4') }}</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="5" :complete="step > 5">{{ $t('CreateProfile.step.5') }}</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="6" :complete="step > 6">{{ $t('CreateProfile.step.6') }}</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="7" :complete="step > 7">{{ $t('CreateProfile.step.7') }}</v-stepper-step>
                </v-stepper-header>

            </v-stepper>

            <div class="mt-2">
                <router-view></router-view>
            </div>
        </template>

        <v-card v-else>
            <v-card-text>
                {{ $t('CreateProfile.infoText') }}
                <v-alert type="info" class="my-2">
                    {{ $t('CreateProfile.notLoggedInInfo') }}
                </v-alert>

                <div class="my-2">
                    <v-btn :to="{name: 'login'}" depressed color="accent">
                        {{ $t('OneWord.login') }}
                    </v-btn>
                    <v-btn :to="{name: 'register'}" text>
                        {{ $t('OneWord.register') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>

    </div>
</template>

<script>
import ProfileCreated from '@/pages/create-profile/ProfileCreated';
import {mapGetters} from 'vuex';

export default {
    name: 'CreateProfile',
    components: {
        ProfileCreated,
    },
    data() {
        return {
            step: 1,
            created: false,
            loading: false,
            company: {},
            application: {
                text: null,
                files: [],
            },
            partnerTypes: [],
            profile: {},
            images: [],
            logo: [],
            person: [],
            profileContact: {},
            error: {
                message: null,
                errors: [],
            },
        };
    },
    computed: {
        ...mapGetters('env', [
            'isAuthenticated',
        ]),
    },
    watch: {
        $route(to) {
            this.setStep(to.name);
        },
    },
    methods: {
        setStep(routeName) {
            if (routeName === 'create-profile-account') {
                this.step = 1;
            }
            if (routeName === 'create-profile-application') {
                this.step = 2;
            }
            if (routeName === 'create-profile-partner-type') {
                this.step = 3;
            }
            if (routeName === 'create-profile-description') {
                this.step = 4;
            }
            if (routeName === 'create-profile-images-form') {
                this.step = 5;
            }
            if (routeName === 'create-profile-contact-form') {
                this.step = 6;
            }
            if (routeName === 'create-profile-submit') {
                this.step = 7;
            }
        },
        back() {
            this.step = this.step - 1;
        },
        saveCompany(company) {
            this.company = company;
            this.step = 2;
        },
        saveApplicationFiles(files) {
            this.application.files = files;
            this.step = 2;
        },
        saveApplication(application) {
            this.application.text = application.text;
            this.step = 3;
        },
        savePartnerTypeForm(partnerTypes) {
            this.partnerTypes = partnerTypes;
            this.step = 4;
        },
        saveProfile(profile) {
            this.profile = profile;
            this.step = 5;
        },
        saveImages({logo, images, person}) {
            this.images = images;
            this.logo = logo;
            this.person = person;

            this.step = 6;
        },
        saveProfileContact(contact) {
            this.profileContact = contact;
            this.step = 7;
        },
        createProfile() {
            this.save();
        },
        save() {
            this.loading = true;

            let company = this.company;
            company['application_text'] = this.application.text;
            company['application_files'] = this.application.files;

            let profile = this.profileContact;
            profile['profile_title'] = this.profile.profile_title;
            profile['description'] = this.profile.description;
            profile['key_figures'] = this.profile.key_figures;
            profile['services'] = this.profile.services;
            profile['service_categories'] = this.profile.service_categories;

            axios.post('/webapi/create-profile', {
                'account': company,
                'profile': profile,
                'partner_types': _.map(this.partnerTypes, 'id'),
                'logo': this.logo,
                'images': this.images,
                'person': this.person,
            }).then(() => {
                this.created = true;
            }).catch(error => {
                console.log(error);
                this.error = error.data;
            }).finally(() => {
                this.loading = false;
            });
        },
    },
    mounted() {
        if (_.has(this.$route.query, 'account')) {
            this.step = 3;
        }

        this.setStep(this.$route.name);
    },
};
</script>

<style scoped>

</style>
