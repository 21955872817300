<template>
    <v-container
        class="text-center"
        fill-height
        style="height: calc(100vh - 64px);"
    >
        <v-row align="center">
            <v-col>
                <h1 class="display-2 primary--text mb-5" data-cy="testing-startpage">
                    Testing Start Page
                </h1>

                <p>Where all testing begins...</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'Error404',
    };
</script>

<style scoped>

</style>
