import Vue from 'vue';
import vuetify from './plugins/vuetify';
import router from './router';
import './plugins/axios';
import store from './store';
import App from './App.vue';
import './assets/app.scss';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import {usei18n, getCurrentLocale} from './plugins/i18n';
import numeral from 'numeral';
import {TiptapVuetifyPlugin} from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
import '@/plugins/vee-validate';
import VueSocialSharing from 'vue-social-sharing';
import * as Sentry from "@sentry/vue";
import './plugins/clipboard.js';
import Features from "@/plugins/features";
import '@/plugins/filters';
import loadEnv from "@/plugins/env";
import VueMeta from "vue-meta";
import {pirsch} from "@/utils/pirsch";

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        Vue: Vue,
        dsn: "https://bdd650ce53b04d799b1947aaf8d5e29c@o763092.ingest.sentry.io/5793774",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration()
        ],
        tracesSampleRate: 0.08,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    });
}

Vue.use(Features);
Vue.use(VueSocialSharing);
Vue.use(VueMeta);

window.uuidv4 = uuidv4;
window.moment = moment;
window.numeral = numeral;

Vue.config.productionTip = false;

//Vue.use(abilitiesPlugin, casl);
Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi',
});

loadEnv().then(() => {
    
    const i18n = usei18n()

    moment.locale(getCurrentLocale());

    new Vue({
        i18n,
        router,
        store,
        vuetify,
        el: '#app',
        render: (h) => h(App),
    });

    pirsch();
});

