<template>
    <svg class="noun-rings-icon" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">
        <g id="group">
            <path id="Path" d="M25.329 18.375 C37.055 13.879 48.768 15.124 53.731 20.882 53.706 19.718 53.496 18.578 53.079 17.487 49.93 9.282 36.415 6.834 22.891 12.019 9.368 17.207 0.956 28.069 4.103 36.275 4.524 37.364 5.129 38.353 5.891 39.233 5.727 31.636 13.602 22.875 25.329 18.375 Z" fill="currentColor" fill-opacity="1" stroke="none"/>
            <path id="Path-1" d="M60.554 27.473 C60.485 28.05 60.384 28.627 60.262 29.203 67.277 34.769 70.809 41.898 68.628 47.785 68.208 48.922 67.59 49.951 66.812 50.875 61.743 56.902 49.622 58.336 37.418 53.818 25.217 49.301 16.948 40.32 17.025 32.446 17.038 31.238 17.239 30.055 17.659 28.92 20.821 20.38 34.794 17.679 48.869 22.888 50.403 23.457 51.871 24.097 53.27 24.795 53.446 24.246 53.581 23.7 53.681 23.154 52.313 22.471 50.882 21.852 49.399 21.303 33.42 15.386 17.056 19.806 12.848 31.175 8.639 42.541 18.182 56.55 34.162 62.465 50.141 68.379 66.505 63.961 70.715 52.596 73.811 44.225 69.448 34.422 60.554 27.473 Z" fill="currentColor" fill-opacity="1" stroke="none"/>
            <g id="group-1">
                <path id="Path-2" d="M49.76 31.012 C51.316 29.103 52.471 27.116 53.155 25.134 51.756 24.43 50.288 23.784 48.748 23.214 35.165 18.188 21.679 20.794 18.626 29.035 18.222 30.132 18.027 31.273 18.017 32.438 22.909 26.619 34.607 25.237 46.386 29.596 47.548 30.029 48.675 30.503 49.76 31.012 Z" fill="currentColor" fill-opacity="1" stroke="none"/>
                <path id="Path-3" d="M60.169 29.657 C59.68 31.781 58.845 33.895 57.693 35.947 62.966 40.235 66.113 45.444 66.066 50.227 66.816 49.336 67.412 48.342 67.816 47.247 69.878 41.684 66.653 34.977 60.169 29.657 Z" fill="currentColor" fill-opacity="1" stroke="none"/>
            </g>
            <path id="Path-4" d="M58.785 19.596 C54.443 8.278 38.027 4.052 22.12 10.154 6.209 16.257 -3.166 30.379 1.174 41.696 3.725 48.342 10.441 52.538 18.773 53.637 17.453 52.282 16.267 50.844 15.24 49.338 14.112 47.682 13.218 45.994 12.54 44.303 9.453 43.405 6.9 41.92 5.152 39.893 4.365 38.977 3.736 37.955 3.301 36.825 0.04 28.325 8.755 17.072 22.767 11.697 36.779 6.321 50.783 8.859 54.045 17.361 54.478 18.492 54.694 19.672 54.72 20.879 54.889 28.753 46.73 37.83 34.58 42.492 31.161 43.803 27.741 44.644 24.479 45.041 27.363 47.748 31.054 50.176 35.26 52.029 36.119 51.759 36.979 51.467 37.838 51.136 53.747 45.031 63.124 30.912 58.785 19.596 Z" fill="currentColor" fill-opacity="1" stroke="none"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Rings',
    };
</script>

<style scoped lang="scss">
    .noun-rings-icon {
        fill: currentColor;
        width: auto;
    }
</style>
