import {extend, localize} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import PhoneNumber from 'awesome-phonenumber';
import {usei18n} from './i18n';
import {EventBus} from '@/utils/event-bus';
import store from '@/store';

// loop over all rules
for (let rule in rules) {
    // add the rule
    extend(rule, {
        ...rules[rule],
    });
}

EventBus.$on('locale-changed', locale => {
    loadLocale(locale.substr(0, 2));
});

loadLocale('de');

function loadLocale(code) {
    return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
        localize(code, locale);
    });
}

extend('phone', {
    message(fieldName) {
        return usei18n().t('VeeValidate.phone', {
            _field_: fieldName,
        });
    },
    validate(value) {
        return new Promise(resolve => {
            let phone = new PhoneNumber(value, store.getters['env/getCountry']);
            resolve({valid: phone.isValid()});
        });
    },
});

extend('url', {
    validate: (str) => {
        var pattern = new RegExp('^((http|https):\\/\\/)' + // protocol
            '((([a-zäöü\\d]([a-zäöü\\d-]*[a-zäöü\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    },
    message(fieldName) {
        return usei18n().t('VeeValidate.url', {
            _field_: fieldName,
        });
    },
});

extend('birthday', {
    message(fieldName) {
        return usei18n().t('VeeValidate.birthday', {
            _field_: fieldName,
        });
    },
    validate(value) {
        return new Promise(resolve => {
            resolve({valid: moment(value).isSameOrBefore(moment.now())});
        });
    },
});
