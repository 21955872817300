<template>
    <v-container fluid class="fill-height">
        <v-row no-gutters justify="center" align="center">
            <v-col cols="12" lg="6">
                <validation-observer v-slot="{handleSubmit}" ref="sendpwd">
                    <v-form @submit.prevent="handleSubmit(save)">
                        <v-card class="text-center">
                            <img
                                v-if="getLogo"
                                :src="getLogo"
                                :style="{'height': $vuetify.breakpoint.mdAndDown ? '3rem': '4rem'}"
                                class="d-inline-block my-12"
                            />
                            <v-icon v-else :size="$vuetify.breakpoint.mdAndDown ? '8rem' : '10rem'">
                                $vuetify.icons.dein_adieu_logo
                            </v-icon>
                            <v-card-title class="headline">
                                {{ $t('SendPassword.title') }}
                            </v-card-title>
                            <v-card-text>
                                <v-alert type="error" :value="error.length > 0" dense>{{ error }}</v-alert>
                                <validation-provider :name="$t('OneWord.email')"
                                                     rules="required|email|max:255"
                                                     v-slot="{ errors }"
                                                     vid="email">
                                    <v-text-field
                                        :error-messages="errors"
                                        :label="$t('OneWord.email')"
                                        autocomplete="email"
                                        data-cy="input-email"
                                        v-model="user.email"
                                    ></v-text-field>
                                </validation-provider>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn depressed text @click="$router.push({name:'login'})">
                                    {{ $t('OneWord.cancel') }}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn depressed color="primary" type="submit" :loading="loading">
                                    {{ $t('SendPassword.btn.send') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </validation-observer>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {EventBus} from '@/utils/event-bus';
import {mapGetters} from "vuex";

export default {
    name: 'SendPassword',
    components: {ValidationObserver, ValidationProvider},
    computed: {
        ...mapGetters('env', [
            'getLogo',
        ]),
    },
    data() {
        return {
            error: '',
            user: {},
            loading: false,
        };
    },
    methods: {
        save() {
            this.loading = true;
            axios.post('/webapi/password/email', this.user).then(() => {
                EventBus.$emit('success', this.$t('SendPassword.success'));
                this.$router.push({name: 'login'});
            }).catch(error => {
                this.$refs.sendpwd.setErrors(error.data.errors);
                this.error = error.data.message;
            }).finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped>

</style>
