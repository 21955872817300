var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c('validation-observer',{ref:"personForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"data-cy":"v-dialog-person"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"accent","dense":"","flat":""}},[_vm._v(" "+_vm._s(_vm.$t("AccountPersons.dialogAddPersonTitle"))+" ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.salutation.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.salutation,"label":_vm.$t('OneWord.salutation.label'),"data-cy":"v-select-salutation"},model:{value:(_vm.person.salutation),callback:function ($$v) {_vm.$set(_vm.person, "salutation", $$v)},expression:"person.salutation"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.lastName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('OneWord.lastName'),"data-cy":"v-textfield-lastname"},model:{value:(_vm.person.last_name),callback:function ($$v) {_vm.$set(_vm.person, "last_name", $$v)},expression:"person.last_name"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.lastName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('OneWord.firstName'),"data-cy":"v-textfield-firstname"},model:{value:(_vm.person.first_name),callback:function ($$v) {_vm.$set(_vm.person, "first_name", $$v)},expression:"person.first_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('OneWord.email'),"data-cy":"v-textfield-email","type":"email"},model:{value:(_vm.person.email),callback:function ($$v) {_vm.$set(_vm.person, "email", $$v)},expression:"person.email"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.isDialogOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t("OneWord.cancel"))+" ")]),_c('v-btn',{attrs:{"loading":_vm.saving,"color":"primary","depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("OneWord.save"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }