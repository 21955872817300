var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"2faActivateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"id":"2_fa_activate_form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.activate2fa)}}},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.$t('UserSecurity.title'))+" ")]),_c('v-card-text',[_c('v-alert',{attrs:{"type":"info","dense":"","text":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('UserSecurity.infoText'))}})]),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.smsNumber'),"rules":"required","vid":"two_factor_mobile_phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('OneWord.smsNumber')},model:{value:(_vm.twoFactorNumber),callback:function ($$v) {_vm.twoFactorNumber=$$v},expression:"twoFactorNumber"}})]}}],null,true)})],1),_c('v-card-actions',[(_vm.user.two_factor_mobile_phone)?_c('v-btn',{attrs:{"loading":_vm.deleting,"color":"error","type":"tel","depressed":""},on:{"click":_vm.deactivate}},[_vm._v(" "+_vm._s(_vm.$t('OneWord.delete'))+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","depressed":"","type":"submit","form":"2_fa_activate_form"}},[_vm._v(" "+_vm._s(_vm.$t('OneWord.save'))+" ")])],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"width":"600","persistent":""},model:{value:(_vm.verifyCodeDialog),callback:function ($$v) {_vm.verifyCodeDialog=$$v},expression:"verifyCodeDialog"}},[_c('validation-observer',{ref:"2faValidateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"id":"2_fa_validate_form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.verify2fa)}}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('UserSecurity.confirmCodeTitle'))+" ")]),_c('v-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('UserSecurity.confirmCodeInfoText')))]),(_vm.verifyErrorMessage)?_c('v-alert',{attrs:{"type":"error","dense":"","text":""}},[_vm._v(" "+_vm._s(_vm.verifyErrorMessage)+" ")]):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.smsCode'),"mode":"eager","rules":"required","vid":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"123456","error-messages":errors},model:{value:(_vm.twoFactorCode),callback:function ($$v) {_vm.twoFactorCode=$$v},expression:"twoFactorCode"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","depressed":"","outlined":"","disabled":_vm.locked},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('OneWord.cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":"","type":"submit","form":"2_fa_validate_form","loading":_vm.verifying}},[_vm._v(" "+_vm._s(_vm.$t('OneWord.save'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }