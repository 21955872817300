<template>
    <v-card class="mt-2">
        <v-card-text v-if="response" :class="{'pa-10': $vuetify.breakpoint.lgAndUp, 'pa-2': $vuetify.breakpoint.mdAndDown}">
            <v-row>
                <v-col class="hidden-md-and-down" lg="1">
                    <v-avatar color="accent" size="60">
                        <v-icon dark size="40">mdi-thumb-up</v-icon>
                    </v-avatar>
                </v-col>
                <v-col cols="12" lg="11">
                    <div class="title mb-4 mt-3">
                        {{ $t('ConsultingCreated.title') }}
                    </div>
                </v-col>
            </v-row>

            <v-card class="mb-8 bg-pen-and-paper mt-4">
                <v-card-text class="pa-6">
                    <span class="title">
                        {{ $t('ConsultingCreated.card1.title') }}
                    </span>

                    <ol class="list-decimal my-6" type="a">
                        <li class="my-2" v-html="$t('ConsultingCreated.card1.line1')"></li>
                        <li class="my-2" v-html="$t('ConsultingCreated.card1.line2')"></li>
                    </ol>

                    <button
                        class="block lg:inline-block px-8 py-4 rounded outline outline-1 font-semibold hover:bg-da-pp hover:text-white"
                        @click="restartTool"
                    >
                        {{ $t('ConsultingCreated.newButton') }}
                    </button>
                </v-card-text>
            </v-card>

            <more-documents :buttons="moreDocumentButtons"></more-documents>
        </v-card-text>

        <v-dialog
            v-model="ngoThanksDialog"
            :fullscreen="$vuetify.breakpoint.mdAndDown"
            max-width="800"
            persistent
            @close="ngoThanksDialog=false"
        >
            <ngo-thanks-dialog
                tool="consulting"
                :ngo-thanks="ngoThanks"
                :ngo-info="ngoInfo"
                @save="saveNgoThanks"
            ></ngo-thanks-dialog>
        </v-dialog>
    </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import MoreDocuments from '@/components/Upselling/MoreDocuments';
import NgoThanksDialog from '@/components/NgoThanksDialog';
import { checkCompletion } from '@/composables/ToolStepper';

export default {
    name: 'ConsultingCreated',
    components: {
        MoreDocuments,
        NgoThanksDialog,
    },
    data() {
        return {
            ngoThanksDialog: false,
            ngoThanks: true,
            ngoInfo: false,
        };
    },
    mounted() {
        checkCompletion('consulting');

        if ( ! this.ngoThanksSubmitted) {
            this.openNgoThanks();
        }
    },
    computed: {
        ...mapGetters('env', [
            'isAuthenticated',
        ]),
        ...mapGetters('consulting', [
            'selectedProfiles',
            'response',
            'ngoThanksSubmitted',
        ]),
        ...mapGetters('affiliateProfile', {
            affiliateProfile: 'profile',
            hasAffiliateProfile: 'hasProfile',
        }),
        moreDocumentButtons() {

            let buttons = [];

            if (this.$hasFeature(this.$Features.Testament)) {
                buttons.push({
                    icon: 'mdi-gavel',
                    label: this.$t('PatientDecreeCreated.card3.btn.testamentTool'),
                    to: {path: '/testament'},
                    id: 'consulting-btn-testament',
                });
            }

            if (this.$hasFeature(this.$Features.PatientDecree)) {
                buttons.push({
                    icon: 'mdi-file-star-outline',
                    label: this.$t('OneWord.patientDecree'),
                    to: {name: 'patient-decree-tool'},
                    id: 'testament-created-btn-patient-decree',
                });
            }

            if (this.$hasFeature(this.$Features.AdvanceCareDirective)) {
                buttons.push({
                    icon: 'mdi-file-account-outline',
                    label: this.$t('AppUserNav.advance_care_directive'),
                    to: {name: 'advance-care-directive-tool'},
                    id: 'testament-created-btn-advance-care-directive',
                });
            }

            return buttons;
        },
    },
    methods: {
        ...mapActions('consulting', [
            'reset',
            'setNgoThanksSubmitted',
        ]),
        openNgoThanks() {
            if ( ! this.response.consulting) {
                return false;
            }

            // are any NGO's selected?
            if (this.selectedProfiles.length > 0) {
                this.ngoThanksDialog = true;
            }

            // is there a sponsor available?
            if (this.hasAffiliateProfile) {
                this.ngoThanksDialog = true;
            }
        },
        restartTool() {
            this.reset();
            this.$router.push({name: 'consulting-tool'});
        },
        saveNgoThanks(settings) {
            if (settings) {
                this.ngoThanks = settings.ngoThanks;
                this.ngoInfo = settings.ngoInfo;

                axios.post('/webapi/consulting/ngo-settings', Object.assign(settings, {
                    consulting: this.response.consulting
                })).then(() => {
                    this.setNgoThanksSubmitted();
                }).finally(() => {
                    this.ngoThanksDialog = false;
                });
            } else {
                this.ngoThanksDialog = false;
            }
        },
    },
}
</script>

<style scoped>

</style>