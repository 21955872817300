<template>
    <div>
        <validation-provider :name="$t('OneWord.company')" rules="required|max:255" v-slot="{ errors }" mode="eager">
            <v-text-field
                v-model="company.company_name"
                :label="$t('OneWord.company')"
                :counter="255"
                :error-messages="errors"
            ></v-text-field>
        </validation-provider>

        <v-row>
            <v-col cols="12" md="8">
                <validation-provider :name="$t('OneWord.address')" rules="required|max:255" v-slot="{ errors }" mode="eager">
                    <v-text-field
                        :label="$t('OneWord.address')"
                        v-model="company.street"
                        :error-messages="errors"
                        :counter="255"
                        autocomplete="street-address"
                    ></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" md="4">
                <validation-provider :name="$t('OneWord.poBox')" rules="max:255" v-slot="{ errors }" mode="eager">
                    <v-text-field
                        :label="$t('OneWord.poBox')"
                        v-model="company.post_office_box"
                        :error-messages="errors"
                        :counter="255"
                        autocomplete="street-address"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="4">
                <validation-provider :name="$t('OneWord.zip')" rules="required|max:5" v-slot="{ errors }" mode="eager">
                    <v-text-field
                        :label="$t('OneWord.zip')"
                        v-model="company.zip"
                        :error-messages="errors"
                        autocomplete="postal-code"
                    ></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" md="8">
                <validation-provider :name="$t('OneWord.place')" rules="required|max:255" v-slot="{ errors }" mode="eager">
                    <v-text-field
                        :label="$t('OneWord.place')"
                        v-model="company.place"
                        :error-messages="errors"
                        autocomplete="address-level2"
                        :counter="255"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>

        <validation-provider :name="$t('OneWord.website')" rules="required|url" v-slot="{ errors }" mode="eager">
            <v-text-field
                :label="$t('OneWord.website')"
                v-model="company.website"
                :error-messages="errors"
                :counter="255"
                prepend-inner-icon="mdi-web"
                :hint="$t('OneWord.urlHint')"
            ></v-text-field>
        </validation-provider>

        <v-row>
            <v-col cols="12" md="6">
                <validation-provider :name="$t('OneWord.callCenter')" rules="required|max:255|phone" v-slot="{ errors }" mode="eager">
                    <v-text-field
                        :label="$t('OneWord.callCenter')"
                        :error-messages="errors"
                        v-model="company.phone"
                        prepend-inner-icon="mdi-phone"
                        type="tel"
                    ></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
                <validation-provider :name="$t('OneWord.directPhone')" rules="max:255|phone" v-slot="{ errors }" mode="eager">
                    <v-text-field
                        :label="$t('OneWord.directPhone')"
                        v-model="company.mobile_phone"
                        type="tel"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-cellphone"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {ValidationProvider} from 'vee-validate';

    export default {
        name: 'AccountForm',
        components: {ValidationProvider},
        props: {
            company: {
                type: Object,
                default() {
                    return {};
                }
            },
        },
        watch: {
            company: {
                deep: true,
                handler(value) {
                    this.$emit('update:company', value);
                },
            },
        },
        data() {
            return {
                salutations: [
                    {id: 1, title: this.$t('OneWord.salutation.f')},
                    {id: 2, title: this.$t('OneWord.salutation.m')},
                ],
            };
        },
    };
</script>

<style scoped>

</style>
