<template>
    <div>
        <h1 class="title mb-2">
            <v-btn icon @click="$router.push({name: 'admin-profiles'})">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ $t('AdminProfile.title') }}: {{ profile.company_name || $t('AdminProfileList.newProfileTitle') }}
        </h1>

        <v-alert text type="info" v-if="profile.is_draft">
            <v-row align="center">
                <v-col class="grow py-0">
                    {{ $t('AdminProfile.draftInfoText') }}
                </v-col>
                <v-col class="shrink py-0">
                    <v-btn small depressed color="primary" @click="submitForReview">
                        {{ $t('AdminProfile.submitForReview') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <v-toolbar dense elevation="1">
            <v-app-bar-nav-icon>
                <v-icon>mdi-map-marker</v-icon>
            </v-app-bar-nav-icon>
            <v-toolbar-title class="subtitle-1">
                {{ $t('AdminProfileList.profileLanguage') }}: <strong>{{ langText }}</strong>
            </v-toolbar-title>
            <template v-slot:extension>
                <v-tabs color="accent" :value="$route.name">
                    <v-tab :to="{name: 'admin-edit-profile-description', params: {lang: lang }}">
                        {{ $t('AdminProfile.tabs.description') }}
                    </v-tab>
                    <v-tab :to="{name: 'admin-edit-profile-contact', params: {lang: lang }}">
                        {{ $t('AdminProfile.tabs.contact') }}
                    </v-tab>
                    <v-tab :to="{name: 'admin-edit-profile-images', params: {lang: lang }}">
                        {{ $t('AdminProfile.tabs.images') }}
                    </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>

        <div class="mt-4">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {EventBus} from '../../utils/event-bus';
import store from '../../store';

export default {
    name: 'AdminProfile',
    components: {},
    data() {
        return {
            profile: {
                locale: '',
            },
            loading: false,
            tabs: null,
            savingContent: false,
            savingDescription: false,
            savingImages: false,
        };
    },
    props: {
        lang: String,
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        profileId() {
            return _.parseInt(_(this.$route.params).get('profile_id'));
        },
        hasPendingMutations() {
            return !_.isEmpty(this.profile.profile_mutations);
        },
        languages() {
            return [
                {locale: 'de_CH', label: this.$t('OneWord.lang.german')},
                {locale: 'en_GB', label: this.$t('OneWord.lang.english')},
                {locale: 'fr_CH', label: this.$t('OneWord.lang.french')},
                {locale: 'it_CH', label: this.$t('OneWord.lang.italian')},
            ];
        },
        langText() {
            return _.get(
                _.find(this.languages, {locale: this.profile.locale}),
                'label',
                this.$t('OneWord.lang.german'),
            );
        },
    },
    methods: {
        ...mapActions('user', {
            loadUser: 'load',
        }),
        saveContact() {
            this.savingContent = true;
            this.saveProfile().finally(() => {
                this.savingContent = false;
            });
        },
        saveDescription() {
            this.savingDescription = true;

            this.saveProfile().finally(() => {
                this.savingDescription = false;
            });
        },
        saveProfile() {
            return axios.put(`/webapi/accounts/${this.user.account_id}/profiles/${this.profileId}`, this.profile).
                then(() => {
                    EventBus.$emit('success', this.$t('AdminProfile.saveSuccess'));
                });
        },
        saveImages() {
            this.savingImages = true;
        },
        load() {
            this.loading = true;

            axios.get(`/webapi/accounts/${this.user.account_id}/profiles/${this.profileId}`, {
                headers: {
                    'DA-Accept-Language': this.lang,
                },
            }).then(response => {
                this.profile = response.data.data;

                if (this.profile.is_in_translation) {
                    this.$router.replace({name: 'admin-profiles'});
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        submitForReview() {
            this.$root.$confirm(
                this.$t('AdminProfile.confirmSubmitForReview'),
                this.$t('AdminProfile.confirmSubmitForReviewInfoText'),
            ).then(confirm => {
                if (confirm) {
                    return axios.patch(
                        `/webapi/accounts/${this.user.account_id}/profiles/${this.profileId}/submit-for-review`).
                        then(() => {
                            EventBus.$emit('success', this.$t('AdminProfile.submitForReviewSuccess'));
                            this.$router.push({name: 'admin-profiles'});
                        }).
                        catch(error => {
                            EventBus.$emit('error', error.data.message);
                        });
                }
            });
        },
    },
    mounted() {
        this.load();
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('user/load').then(() => {
            next();
        });
    },
};
</script>

<style scoped lang="scss">
.v-tabs {
    .v-tab {
        text-decoration: none;
    }
}
</style>
