
class ImagesToBase64 {
    static fromDropzone(files) {
        let promises = [];

        _(files).forEach(file => {
            let form = new FormData();
            form.append('data', file, file.name);
            promises.push(new Promise((resolve) => {
                this.toBase64(form.get('data')).then((result) => {
                    resolve({
                        name: form.get('data').name,
                        data: result,
                    });
                });
            }));
        });

        return Promise.all(promises);
    }

    static toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
}

export {ImagesToBase64};
