<template>
    <div>
        <validation-provider v-slot="{ errors }" :name="$t('ProfileForm.profileTitle')" mode="eager"
                             rules="required|max:255">
            <v-text-field
                v-model="profile.profile_title"
                :counter="255"
                :error-messages="errors"
                :label="$t('ProfileForm.profileTitle')"
            ></v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('ProfileForm.profileDescription')" mode="eager"
                             rules="required">
            <div>{{ $t('ProfileForm.profileDescription') }}</div>
            <tiptap-vuetify
                v-model="editorContent"
                :card-props="{outlined: true, tile: true, color: errors.length > 0 ? 'error' : null}"
                :extensions="extensions"
                class="mt-2"
            ></tiptap-vuetify>
            <div class="red--text mt-1">{{ errors[0] }}&nbsp;</div>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('ProfileForm.profileKeyFigures')" mode="eager" rules="required">
            <div class="mt-2">{{ $t('ProfileForm.profileKeyFigures') }}</div>
            <tiptap-vuetify
                v-model="keyFiguresContent"
                :card-props="{outlined: true, tile: true, color: errors.length > 0 ? 'error' : null}"
                :extensions="extensions"
                class="mt-2"
            ></tiptap-vuetify>
            <div class="red--text mt-1">{{ errors[0] }}&nbsp;</div>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('ProfileForm.profileCategories')" :rules="profileCategories.length > 0 ? 'required' : null">
            <v-select
                v-model="profile.service_categories"
                :disabled="profileCategories.length === 0"
                :error-messages="errors"
                :hint="$t('ProfileForm.profileCategories')"
                :items="profileCategories"
                :label="$t('ProfileForm.profileCategories')"
                :loading="profileCategoriesLoading"
                chips
                data-cy="service-categories"
                deletable-chips
                item-text="name"
                item-value="id"
                multiple
                persistent-hint
                return-object
                @input="limitSelection($event, 3)"
            ></v-select>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('ProfileForm.profileServices')" :rules="profileServices.length > 0 ? 'required' : null">
            <v-select
                v-model="profile.services"
                :disabled="profileServices.length === 0"
                :error-messages="errors"
                :hint="$t('ProfileForm.profileServicesHint')"
                :items="profileServices"
                :label="$t('ProfileForm.profileServices')"
                :loading="profileServicesLoading"
                chips
                class="mt-4"
                data-cy="services"
                deletable-chips
                item-text="name"
                item-value="id"
                multiple
                persistent-hint
                return-object
            ></v-select>
        </validation-provider>
    </div>
</template>

<script>
import {EventBus} from '@/utils/event-bus';
import {ValidationProvider} from 'vee-validate';
import {
    Bold,
    BulletList,
    HardBreak,
    Heading,
    History,
    Italic,
    Link,
    ListItem,
    OrderedList,
    Paragraph,
    Strike,
    TiptapVuetify,
    Underline,
} from 'tiptap-vuetify';

export default {
    name: 'ProfileDescriptionForm',
    components: {ValidationProvider, TiptapVuetify},
    data() {
        return {
            extensions: [
                Paragraph,
                BulletList,
                HardBreak,
                [
                    Heading,
                    {
                        options: {
                            levels: [1, 2, 3],
                        },
                    },
                ],
                ListItem,
                OrderedList,
                Link,
                Bold,
                Italic,
                Strike,
                Underline,
                History,
            ],

            profileServicesLoading: false,
            profileCategoriesLoading: false,

            profileCategories: [],
            profileServices: [],
        };
    },
    props: {
        profile: {
            type: Object,
            default() {
                return {
                    profile_title: null,
                    description: null,
                };
            },
        },
    },
    computed: {
        editorContent: {
            get() {
                return this.profile.description;
            },
            set(value) {
                if (value === '<p></p>' || value === '' || value === null) {
                    this.profile.description = '';
                }
                else {
                    this.profile.description = value;
                }
            },
        },
        keyFiguresContent: {
            get() {
                return this.profile.key_figures;
            },
            set(value) {
                if (value === '<p></p>' || value === '' || value === null) {
                    this.profile.key_figures = '';
                }
                else {
                    this.profile.key_figures = value;
                }
            },
        },
    },
    watch: {
        profile(value) {
            if (value) {
                this.load();
            }
        },
    },
    methods: {
        load() {
            let partnerTypeIds = _.map(_.get(this.profile, 'partner_types', []), 'id');

            if (_.isEmpty(partnerTypeIds)) {
                return;
            }

            axios.post('/webapi/services', {
                partner_types: partnerTypeIds,
            }).then((response) => {
                this.profileServices = response.data.data;
            }).finally(() => {
                this.profileServicesLoading = false;
            });

            axios.post('/webapi/create-profile/service-categories', {
                partner_types: partnerTypeIds,
            }).then(response => {
                this.profileCategories = response.data.data;
            }).finally(() => {
                this.profileCategoriesLoading = false;
            });
        },

        limitSelection(e, limit) {
            if ( ! limit) limit = 3;
            if (e.length > limit) {
                EventBus.$emit('info', this.$t('ProfileForm.profileCategoriesMaxInfo'));
                e.pop();
            }
        },
    },
    mounted() {
        EventBus.$on('locale-changed', this.load);
        this.load();
    },
    beforeDestroy() {
        EventBus.$off('locale-changed', this.load);
    },
};
</script>

<style lang="scss" scoped>
::v-deep .ProseMirror {
    min-height: 10rem;

    ul {
        list-style: disc;

        > li > p {
            display: inline;
        }
    }

    ol {
        list-style: decimal;

        > li > p {
            display: inline;
        }
    }
}
</style>
