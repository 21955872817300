import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

const requireComponent = require.context(
    // The relative path of the components folder
    './../components/Icons',
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /[A-Z]\w+\.(vue)$/,
);

const components = {};

requireComponent.keys().forEach(fileName => {

    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            // Gets the file name regardless of folder depth
            fileName.split('/').pop().replace(/\.\w+$/, ''),
        ),
    );

    // Register component globally
    Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig,
    );

    components[snakeCase(componentName)] = {
        component: componentName,
    }
});

export default components;
