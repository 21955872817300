<template>
    <v-container class="text-center">

        <affiliate-header />

        <v-card class="text-center pa-5 rounded-md lg:rounded-xl" flat>
            <template v-if="!hasProfile">
                <img
                    v-if="getLogo"
                    :src="getLogo"
                    :style="{'height': $vuetify.breakpoint.mdAndDown ? '3rem': '4rem'}"
                    class="d-inline-block my-12"
                />
                <v-icon v-else :size="$vuetify.breakpoint.mdAndDown ? '8rem' : '10rem'">
                    $vuetify.icons.dein_adieu_logo
                </v-icon>
            </template>
            <v-alert dense text type="info" v-if="$route.query.deleted">
                {{ $t('UserDeletion.deletedInfo') }}
            </v-alert>
            <v-card-title class="headline">
                {{ $t('LoginDialog.title') }}
            </v-card-title>
            <v-card-text>
                <login-form
                    :path="path"
                ></login-form>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import LoginForm from '../components/App/LoginForm';
import AffiliateHeader from './affiliate/AffiliateHeader.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'Login',
    components: {
        LoginForm,
        AffiliateHeader,
    },
    props: {
        path: String,
    },
    computed: {
        ...mapGetters('affiliateProfile', {
            profile: 'profile',
            hasProfile: 'hasProfile',
        }),
        ...mapGetters('env', [
            'getLogo',
        ]),
    },
    watch: {
        '$route'(to) {
            if (to.name === 'login-affiliate') {
                this.affiliateSlug(to.params.profile_slug);
            }
        },
    },
    methods: {
        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),
        affiliateSlug(slug) {
            this.loadAffiliate(slug);
        },
    },
    mounted() {
        let profileSlug = _.get(this.$route.params, 'profile_slug', false);
        if (profileSlug) {
            this.affiliateSlug(profileSlug);
        }
    }
};
</script>

<style scoped>

</style>
