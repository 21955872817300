<template>
    <v-card class="mb-4" v-if="item">
        <v-card-title>
            {{ $t('AdminLeadEdit.section.will.title') }}
        </v-card-title>

        <v-col
            v-if="item.lead_type === 'will' || item.lead_type === 'consulting'"
            class="mx-1 lead-details--row"
            cols="12"
        >
            <div class="underline">{{ $t('AdminLeadEdit.section.will.consideration') }}</div>
            <div class="text-gray-600 mb-2 text-sm">{{ $t('AdminLeadEdit.section.will.considerationDescription') }}</div>
            <template v-if="item.is_favored === true">
                <v-chip data-cy="lead-source-consideration-favoured" class="mr-2 my-1 bg-green-200 text-green-700">{{ $t('AdminLeadEdit.section.will.isFavoured') }}</v-chip>
            </template>
            <template v-if="item.is_favored === false && item.is_removed === true">
                <v-chip data-cy="lead-source-consideration-removed" class="mr-2 my-1 bg-red-100 text-red-500">{{ $t('AdminLeadEdit.section.will.isRemoved') }}</v-chip>
            </template>
            <template v-if="item.is_favored === false && item.is_removed === false">
                <v-chip data-cy="lead-source-consideration-zero" class="mr-2 my-1 bg-red-100 text-red-500">{{ $t('AdminLeadEdit.section.will.zeroPercent') }}</v-chip>
            </template>
            <template v-if="item.is_favored === null && item.is_removed === null">
                <v-chip data-cy="lead-source-consideration-unknown" class="mr-2 my-1 bg-gray-100 text-gray-400">{{ $t('OneWord.notAvailableToolTip') }}</v-chip>
            </template>
        </v-col>

        <v-col 
            v-if="item.other_ngos.length > 0"
            cols="12"
            class="mx-1 lead-details--row"
        >
            <div class="underline">{{ $t('AdminLeadEdit.section.will.otherNgos') }}</div>
            <div class="text-gray-600 mb-2 text-sm">{{ $t('AdminLeadEdit.section.will.otherNgosDescription') }}</div>
            <template v-for="ngo in item.other_ngos">
                <v-chip data-cy="lead-source-other-ngo" :key="ngo" class="mr-2 my-1">{{ ngo }}</v-chip>
            </template>
        </v-col>

        <v-col 
            cols="12" 
            class="mx-1 lead-details--row"
        >
            <div class="underline">{{ $t('AdminLeadEdit.section.will.sourceTitle') }}</div>
            <div class="text-gray-600 mb-2 text-sm">{{ $t('AdminLeadEdit.section.will.sourceDescription') }}</div>
            <v-chip
                class="lead-source mr-2 my-1"
                :data-cy="'lead-source-' + item.lead_source"
            >{{ $t('AdminLeadEdit.section.will.source.' + item.lead_source) }}
            </v-chip>
        </v-col>
    </v-card>
</template>

<script>
export default {
    name: 'AdminLeadDetails',
    props: {
        item: Object,
    },
}
</script>

<style scoped>
.lead-source {
    color: #fff;
    background: var(--da-primary) !important;
}

.lead-details--row {
    columns: 2;
}

.lead-details--row + .lead-details--row {
    border-top: 1px dashed #d3d3d3;
}
</style>