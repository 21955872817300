var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"accountForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-card',[_c('v-card-text',[_c('h2',{staticClass:"title mb-2"},[_vm._v(" "+_vm._s(_vm.$t('AccountForm.title'))+" ")]),_c('v-alert',{attrs:{"type":"info","dense":""}},[_vm._v(" "+_vm._s(_vm.$t('AccountForm.info'))+" ")]),(_vm.formError)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.formError)+" ")]):_vm._e(),_c('h3',{staticClass:"subtitle-1 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('AccountForm.subheaderCompany'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.company'),"mode":"eager","rules":"required|max:255","vid":"company_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.company'),"counter":255,"error-messages":errors},model:{value:(_vm.company.company_name),callback:function ($$v) {_vm.$set(_vm.company, "company_name", $$v)},expression:"company.company_name"}})]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.address'),"mode":"eager","rules":"required|max:255","vid":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.address'),"error-messages":errors,"counter":255,"autocomplete":"street-address"},model:{value:(_vm.company.street),callback:function ($$v) {_vm.$set(_vm.company, "street", $$v)},expression:"company.street"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.poBox'),"rules":"max:255","mode":"eager","vid":"post_office_box"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.poBox'),"error-messages":errors,"counter":255,"autocomplete":"street-address"},model:{value:(_vm.company.post_office_box),callback:function ($$v) {_vm.$set(_vm.company, "post_office_box", $$v)},expression:"company.post_office_box"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.zip'),"rules":"required|max:5","mode":"eager","vid":"zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.zip'),"error-messages":errors,"autocomplete":"postal-code"},model:{value:(_vm.company.zip),callback:function ($$v) {_vm.$set(_vm.company, "zip", $$v)},expression:"company.zip"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.place'),"rules":"required|max:255","mode":"eager","vid":"place"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.place'),"error-messages":errors,"autocomplete":"address-level2","counter":255},model:{value:(_vm.company.place),callback:function ($$v) {_vm.$set(_vm.company, "place", $$v)},expression:"company.place"}})]}}],null,true)})],1)],1),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.website'),"rules":"required|url","mode":"eager","vid":"website"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.website'),"error-messages":errors,"counter":255,"prepend-inner-icon":"mdi-web","hint":_vm.$t('OneWord.urlHint')},model:{value:(_vm.company.website),callback:function ($$v) {_vm.$set(_vm.company, "website", $$v)},expression:"company.website"}})]}}],null,true)}),_c('h3',{staticClass:"subtitle-1 mb-2 mt-4"},[_vm._v(" "+_vm._s(_vm.$t('AccountForm.subheaderContact'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.salutation.label'),"rules":"required","mode":"eager","vid":"salutation_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.$t('OneWord.salutation.label'),"items":_vm.salutations,"item-text":"label","item-value":"id","error-messages":errors},model:{value:(_vm.company.salutation_id),callback:function ($$v) {_vm.$set(_vm.company, "salutation_id", $$v)},expression:"company.salutation_id"}})]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.first-name'),"rules":"required|max:255","mode":"eager","vid":"first_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.first-name'),"error-messages":errors,"autocomplete":"given-name","counter":255},model:{value:(_vm.company.first_name),callback:function ($$v) {_vm.$set(_vm.company, "first_name", $$v)},expression:"company.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.last-name'),"rules":"required|max:255","mode":"eager","vid":"last_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.last-name'),"error-messages":errors,"autocomplete":"family-name","counter":255},model:{value:(_vm.company.last_name),callback:function ($$v) {_vm.$set(_vm.company, "last_name", $$v)},expression:"company.last_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.email'),"rules":"required|email|max:255","mode":"eager","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.email'),"error-messages":errors,"autocomplete":"email","hint":_vm.$t('AccountForm.EmailHint'),"persistent-hint":"","prepend-inner-icon":"mdi-at","counter":255},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.phone'),"rules":"max:255|phone","mode":"eager","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.phone'),"error-messages":errors,"prepend-inner-icon":"mdi-phone","type":"tel"},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('OneWord.mobile'),"rules":"max:255|phone","mode":"eager","vid":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('OneWord.mobile'),"error-messages":errors,"prepend-inner-icon":"mdi-cellphone","type":"tel"},model:{value:(_vm.company.mobile_phone),callback:function ($$v) {_vm.$set(_vm.company, "mobile_phone", $$v)},expression:"company.mobile_phone"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"loading":_vm.saving,"color":"accent","type":"submit"}},[_vm._v(_vm._s(_vm.$t('OneWord.next'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }