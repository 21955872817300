<template>
    <profile-images-form
        v-if="profile"
        :account-id="user.account_id"
        :profile-id="profileId"
        :profile="profile"
        :lang="lang"
        @reload-profile="load"
    ></profile-images-form>
</template>

<script>
import ProfileImagesForm from '../form/ProfileImagesForm';
import {mapActions, mapGetters} from 'vuex';
import store from '../../../store';

export default {
    name: 'ProfileImages',
    components: {ProfileImagesForm},
    props: {
        lang: String,
    },
    data() {
        return {
            profile: null,
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        profileId() {
            return _.parseInt(_(this.$route.params).get('profile_id'));
        },
    },
    methods: {
        ...mapActions('user', {
            loadUser: 'load',
        }),
        load() {
            axios.get(`/webapi/accounts/${this.user.account_id}/profiles/${this.profileId}`, {
                headers: {
                    'DA-Accept-Language': this.lang,
                },
            }).then(response => {
                this.profile = response.data.data;
            });
        },
    },
    mounted() {
        this.load();
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('user/load').then(() => {
            next();
        });
    },
};
</script>

<style scoped>

</style>
