<template>
    <div>
        <h1 class="title mb-2">
            {{ $t('AdminDownloads.title') }}
        </h1>

        <template v-for="category in contentCategories">
            <v-card class="mt-4" :key="`category-${category.id}`">
                <v-card-title>
                    {{ category.name }}
                </v-card-title>
                
                <v-card-text>
                    <v-alert type="info" v-if="category.info">
                        <span v-html="category.info"></span>
                    </v-alert>
                </v-card-text>

                <v-data-table
                    :items="category.downloads"
                    :headers="downloadHeaders"
                    hide-default-footer
                    hide-default-header
                    disable-pagination
                    class="content-download"
                >
                    <template v-slot:item.info="{item}">
                        <small class="text--secondary font-italic">{{ item.info }}</small>
                    </template>
                    <template v-slot:item.download="{item}">
                        <v-btn
                            :block="$vuetify.breakpoint.mdAndDown"
                            color="primary"
                            depressed
                            small
                            @click="download(item)"
                        >
                            <v-icon left>mdi-download</v-icon>
                            {{ $t('OneWord.download') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </template>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';

export default {
    name: 'AdminDownloads',
    data() {
        return {
            loading: false,
            contentCategories: [],
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        downloadHeaders() {
            return [
                {value: 'name'},
                {value: 'info'},
                {value: 'status'},
                {value: 'download', align: 'end'},
            ];
        },
    },
    methods: {
        load() {
            this.loading = true;

            window.axios.get(`/webapi/accounts/${this.user.account_id}/content-categories`)
                .then(response => {
                    this.contentCategories = response.data.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        download(item) {
            window.location.href = item.url;
        },
    },
    mounted() {
        EventBus.$on('locale-changed', this.load);
        this.load();
    },
    beforeDestroy() {
        EventBus.$off('locale-changed', this.load);
    }
};
</script>

<style scoped lang="scss">
.content-download {
  ::v-deep .v-data-table__mobile-row__header {
    display: none;
  }

  ::v-deep .v-data-table__mobile-row__cell {
    @apply w-full;
    @apply text-left;
  }
}
</style>
