<template>
    <div class="affiliate-agreement text-center py-0 px-2 lg:py-6 sm:px-0">

        <affiliate-header />

        <v-sheet
            :class="{'pa-10': $vuetify.breakpoint.lgAndUp}"
            class="pa-5 mt-2 mb-4 text-gray-600 rounded-md lg:rounded-xl"
            data-cy="affiliate-agreement"
        >
            <template v-if="!hasProfile">
                <img
                    v-if="getLogo"
                    :src="getLogo"
                    class="mx-auto h-20 mb-6"
                    data-cy="affiliate-tenant-logo"
                />
                <img 
                    v-else
                    :src="require('@/assets/img/deinadieu_logo.svg')" 
                    class="mx-auto h-20 mb-6"
                    data-cy="affiliate-da-logo"
                    style="fill: white"
                />
            </template>

            <div class="mb-2 text-3xl text-black font-medium">
                <h1 v-if="isWhiteLabel && $te(`AffiliateToolPopover.${tool}.welcomeText`)">
                    {{ $t(`AffiliateToolPopover.${tool}.welcomeText`) }}
                </h1>
                <h1 v-else-if="$te(`AffiliateToolPopover.${tool}.headline`)">
                    {{ $t(`AffiliateToolPopover.${tool}.headline`) }}
                </h1>
                <h1 v-else>
                    {{ $t(`AffiliateToolPopover.welcomeText`) }}
                </h1>
            </div>

            <div class="mb-4 text-xl">
                {{ $t(`AffiliateToolPopover.${tool}.infoText`) }}
            </div>

            <div v-for="i in numRows" :key="`list-${i}`" class="text-left px-4 sm:text-center inline-block mt-5">
                <v-icon class="-mt-1 mr-1" color="green">mdi-check-circle-outline</v-icon>
                {{ $t(`AffiliateToolPopover.${tool}.listText.${i}`) }}
            </div>

            <div class="mt-8">
                <v-checkbox
                    v-if="!isAuthenticated"
                    v-model="agreementAccepted"
                    :false-value="false"
                    :true-value="true"
                    class="mb-8"
                    data-cy="switch-affiliate-agreement"
                    hide-details
                >
                    <template v-slot:label>
                        <small data-cy="accept-label-simple" class="pl-2" v-if="showSimpleAcceptLabel" v-html="$t('Affiliate.simple.acceptLabel')"></small>
                        <small data-cy="accept-label-affiliate" class="pl-2" v-else-if="showAffiliateAcceptLabel" v-html="$t('Affiliate.affiliate.acceptLabel')"></small>
                        <small data-cy="accept-label-general" class="pl-2" v-else v-html="$t('Affiliate.general.acceptLabel')"></small>
                    </template>
                </v-checkbox>

                <v-btn
                    :disabled="!agreementAccepted"
                    class="mx-auto text-none rounded-lg"
                    color="primary"
                    data-cy="affiliate-btn-start"
                    depressed
                    x-large
                    @click="start"
                >
                    <template v-if="$te(`AffiliateToolPopover.${tool}.btnStart`)">{{ $t(`AffiliateToolPopover.${tool}.btnStart`) }}</template>
                    <template v-else>{{ $t('AffiliateToolPopover.btnStart') }}</template>
                </v-btn>
            </div>
        </v-sheet>

        <div v-if="!tool" data-cy="tool-undefined"><!-- keep this for testing --></div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import AffiliateHeader from './AffiliateHeader.vue';

export default {
    name: 'Affiliate',

    props: ['tool'],

    components: {
        AffiliateHeader,
    },

    data: () => {
        return {
            agreementAccepted: false,
        };
    },

    watch: {
        // using a watcher to update agreementAccepted after login/logout
        isAuthenticated: {
            immediate: true,
            handler(value) {
                this.agreementAccepted = value
            },
        }
    },

    computed: {
        ...mapGetters('affiliateProfile', [
            'profile',
            'hasProfile',
        ]),

        ...mapGetters('env', [
            'isAuthenticated',
            'isWhiteLabel',
            'getLogo',
        ]),

        profileImageUrl() {
            return _.get(this.profile, 'logo.url');
        },

        hasProfileImage() {
            return !_.isEmpty(_.get(this.profile, 'person_image.url'));
        },

        numRows() {
            return _.get({
                testament: 3,
                patientDecree: 3,
                advanceCareDirective: 3,
                consulting: 3,
            }, this.tool);
        },

        columnWidthClass() {
            return !this.isWhitelabel && this.hasProfile ? 'w-1/2' : 'w-full';
        },

        showSimpleAcceptLabel() {
            return ! this.hasProfile && ['patientDecree', 'advanceCareDirective'].includes(this.tool);
        },

        showAffiliateAcceptLabel() {
            return this.hasProfile && ['patientDecree', 'advanceCareDirective'].includes(this.tool);
        },
    },

    methods: {
        setAgreementAccepted(value) {
            return this.$store.dispatch(this.tool + '/setAgreementAccepted', value);
        },
        
        start() {
            this.setAgreementAccepted(this.agreementAccepted);
            this.$emit('start-tool');
        },
    },
};
</script>

<style lang="scss" scoped>

</style>